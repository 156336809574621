import { epassprotFetch } from '@common/services/fetch';
import LoginStore from '../../layouts/login/store';
const config = (window as any).CCMS_INFOS_CONFIG;

export function getPasswordRules() {
  let tenantId = '';
  if (config.SAAS === 'true') {
    tenantId = LoginStore.tenantId;
  } else {
    tenantId = (window as any).CCMS_INFOS_CONFIG.TENANT;
  }
  return epassprotFetch.get(`/admin/tenants/${tenantId}/securityRules/effect`);
}
