import CurrentUserStore from '@common/stores/user';


/**
 * 获取当前登录人的信息
 */
function getCurrentUserProfile() {
  return CurrentUserStore.get();
}


export const AccountService: KyPortalService.IAccountService = {
  get: getCurrentUserProfile
};
