import * as React from 'react';
import PermissionDeniedIcon from 'shuyun-ep-icons/react/PermissionDenied';
import * as classes from './index.scss';
import { LanguageStore } from '@common/stores/language';


interface IProps {
  hint?: string;
}

export const Page401 = (props: IProps) => {
  const { translate } = LanguageStore.instance.stores.commonPackageStore;
  return (
    <div className={classes.container}>
      <div className={classes.body}>
        <PermissionDeniedIcon className={classes.icon} />
        <p className={classes.title}>{translate('ErrorPage.NoPermission')}</p>
        <p>{props.hint}</p>
      </div>
    </div>
  );
};
