import qs from "qs";
import * as React from "react";
import { message as Message } from "@shuyun-ep-team/kylin-ui";
import { observer } from "mobx-react";
import { observable, action } from "mobx";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { set as setToken } from "@shuyun-ep-team/utils/es/token";
import languageStore from "@business/stores/language";
import MaskLoading from "@common/components/loading";
import { getSsoRedirectInfo } from "./service";
import * as i18nMap from "./i18n.map";

import SystemStore from "@common/stores/system";
const { translate } = languageStore;

@observer
class SsoBind extends React.Component<RouteComponentProps> {
  @observable private isFetching!: boolean;

  public getSnapshotBeforeUpdate(prevProps: RouteComponentProps) {
    const prevSession = this.getParams(prevProps.location.search).session;
    const currentSession = this.getParams(this.props.location.search).session;

    if (prevSession !== currentSession && currentSession) {
      this.getRedirectInfo(currentSession);
    } else {
      // 若不存在 session ，则定向至登陆页
      if (!currentSession) {
        this.props.history.replace("/login");
      }
    }

    return null;
  }

  public componentDidMount() {
    SystemStore.fetchSystemConfig().then(() => {
      languageStore.fetchLanguagePackage(SystemStore.config.language);
    });

    const {
      location: { search },
      history
    } = this.props;

    const { session } = this.getParams(search);

    // 存在 session 参数，则获取定向信息
    // 否则定向至登陆页
    if (session) {
      this.getRedirectInfo(session);
    } else {
      history.replace("/login");
    }
  }

  @action
  private getRedirectInfo = (session: string) => {
    this.isFetching = true;

    return getSsoRedirectInfo(session)
      .then(res => {
        setToken(res.token);
        window.location.replace(res.callback);
        return res;
      })
      .catch(err => {
        const { message } = err;
        Message.error(message);

        this.props.history.replace("/login");
        throw err;
      });
  };

  /**
   * 获取地址参数
   */
  private getParams = (search: string = "") => {
    return qs.parse(search.replace(/^\?/, "")) as { session: string };
  };

  public render() {
    const { i18n } = languageStore;

    return (
      <>
        {this.isFetching && i18n ? (
          <MaskLoading tip={translate(i18nMap.tips)} visible={true} />
        ) : null}
      </>
    );
  }
}

export default withRouter(SsoBind);
