import * as React from 'react';
import { observer } from 'mobx-react';
import { INestMenu } from '@business/typings/global';
import { calculateMenuName } from '@business/utils/menu';



interface IProps {
  className: string;
  menu: INestMenu;
}

export const MenuItem = observer(({ className, menu }: IProps) => {
  const menuName = calculateMenuName(menu);

  return <div className={className}>{menuName}</div>;
});
