import React from 'react';
import ArrowDownIcon from '@shuyun-ep-team/icons/react/ArrowDown';
import moment from 'moment';
import { DateFormats } from '@shuyun-ep-team/utils/es/date/index';
import { Styles, createStyles } from '@common/components/jss-hooks-in-classes';
import { Message, ReadStatus } from '@common/components/notification-center/type';
import { Dot } from '../dot';
import { OperationBtn } from '../operation-btn';
import { postRead } from '../../service';
import * as styles from './index.jss';

type ClassName = keyof typeof styles;

const classes = createStyles<ClassName>(styles);

interface IProps {
  message: Message;
  // 当前展开的key
  activeKey: string;
  /** 展开 */
  onCollapse: (key: string) => void;
  /** 已读 */
  onRead: () => void;
}

interface IState {
  /** 是否操作过 */
  active: boolean;
}

/**
 * @description 消息项
 */
export class NoticeItem extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      active: true,
    };
  }

  handleOption = () => {
    this.setState({
      active: true,
    });
  };

  handleCollapse = () => {
    const { message, onCollapse, onRead } = this.props;
    const { active } = this.state;
    postRead([message.id]);

    // 未读状态，则设为已读
    if (!active) {
      this.setState({
        active: true,
      });
      onRead();
    }

    onCollapse(message.id);
  };

  componentDidMount() {
    const { message } = this.props;

    this.setState({
      active: message.readStatus === ReadStatus.READ_ALREADY,
    });
  }

  public render() {
    const { message, activeKey } = this.props;
    const { active } = this.state;

    // 是否为收起状态
    const collapse = message.id !== activeKey;

    return (
      <Styles styles={classes}>
        {classes => (
          <div className={classes.wrap} key={message.title}>
            <div className={classes.subjectWrap} onClick={this.handleCollapse}>
              <div className={classes.subjectLeft}>
                <div
                  className={classes.title}
                  style={{ fontWeight: !active ? 'bold' : 'normal', color: !active ? '#333' : '#666' }}
                >
                  {!active && <Dot style={{ position: 'absolute', left: '0px', top: '19px', width: 8, height: 8 }} />}
                  {message.title}
                </div>
                <div className={classes.date}>
                  {message.createAt && moment(message.createAt).format(DateFormats.DateTime)}
                </div>
              </div>
              <div className={classes.arrow} style={{ transform: collapse ? 'rotateZ(0deg)' : 'rotateZ(180deg)' }}>
                <ArrowDownIcon />
              </div>
            </div>
            <div className={classes.unfoldWrap} style={{ maxHeight: collapse ? 0 : '500px' }}>
              <div className={classes.unfoldBox}>
                <div className={classes.unfoldDetail} title={message.detail}>
                  {message.detail}
                </div>
                <div className={classes.unfoldOperate}>
                  <OperationBtn operate={message.operate} messageId={message.id} onClick={this.handleOption} />
                </div>
              </div>
            </div>
          </div>
        )}
      </Styles>
    );
  }
}
