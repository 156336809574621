import * as React from "react";
// import * as ReactDOM from 'react-dom';
// import BreadcrumbStore from "./store";
import { deprecatedLogger } from "@common/utils/deprecated";

/**
 * 使用纯字符串的方式
 * @deprecated 此方法将在下一版本中移除，请谨慎使用
 * @param __H html
 */
export function render(__H: string) {
  deprecatedLogger("[BreadcrumbService.render]", "[应用端 Portal]");
  // BreadcrumbStore.update({ html, type: "html" });
}

/**
 *  使用 React 的方式
 * @deprecated 此方法将在下一版本中移除，请谨慎使用
 * @param __C 节点
 */
export function reactRender(__C: React.ReactElement<any>) {
  deprecatedLogger("[BreadcrumbService.reactRender]", "[应用端 Portal]");
  // const div = document.createElement('div');
  // ReactDOM.render(C, div);
  // setTimeout(() => {
  //   const html = div.innerHTML;
  //   BreadcrumbStore.update(html);
  // }, 0);
  // BreadcrumbStore.update({ component: C, type: "React" });
}

export const BreadcrumbService: KyPortalService.IBreadcrumbService = {
  render,
  reactRender
};
