import React from 'react';
import { CommonTranslate } from '@common/components/translate';
import { Icon, Popover, MaskLoading } from '@shuyun-ep-team/kylin-ui';
import { primaryColor } from '@shuyun-ep-team/kylin-ui/es/styles/vars';
import NodeWeiXinWithColorIcon from '@shuyun-ep-team/icons/react/NodeWeiXinWithColor';
import CloseIcon from '@shuyun-ep-team/icons/react/Close';
import { observer } from 'mobx-react';
import { createStyles, Styles } from '@common/components/jss-hooks-in-classes';
import { NoticeContext } from '@common/components/notification-center/context';
import * as styles from './index.jss';
import { getQRCode, putUserConfig } from '../../service';

type ClassName = keyof typeof styles;

const classes = createStyles<ClassName>(styles);

interface IProps {
  text: React.ReactNode;
  close?: boolean;
  background?: string;
}

interface IState {
  visible: boolean;
  isLoading: boolean;
  qrcodeUrl: string;
}

/**
 * @description 头部微信扫码提示
 */
@observer
export class WechatTips extends React.Component<IProps, IState> {
  static contextType = NoticeContext;

  context!: React.ContextType<typeof NoticeContext>;

  constructor(props: IProps) {
    super(props);

    this.state = {
      visible: false,
      isLoading: true,
      qrcodeUrl: '',
    };
  }

  handleVisibleChange = (visible: boolean) => {
    this.setState({
      visible,
      qrcodeUrl: '',
    });
    if (visible) {
      this.setState({
        isLoading: true,
      });
      getQRCode().then(res => {
        this.setState({
          isLoading: false,
          qrcodeUrl: res.qrcodeUrl,
        });
      });
    }
  };

  onClose = () => {
    const { onNoticeClick, config } = this.context;
    onNoticeClick();
    putUserConfig({ ...config, wechatFocusTip: false });
  };

  public render() {
    const { background, close, text } = this.props;
    const { visible, isLoading, qrcodeUrl } = this.state;

    return (
      <Styles styles={classes}>
        {classes => (
          <div className={classes.wrap} style={{ background: background || '#FFFBE6', borderColor: background }}>
            <div className={classes.left}>
              <NodeWeiXinWithColorIcon style={{ marginRight: 8, width: 18, height: 18 }} />
              {text}
              <Popover
                placement='bottom'
                visible={visible}
                onVisibleChange={this.handleVisibleChange}
                content={
                  <div style={{ position: 'relative' }}>
                    <MaskLoading visible={isLoading} />
                    {qrcodeUrl && <img width='140px' height='140px' alt='qrCode' src={qrcodeUrl}></img>}
                  </div>
                }
              >
                <span style={{ color: primaryColor, cursor: 'pointer' }}>
                  <CommonTranslate i18n='Kylin.Common.Portal.Follow' />
                  <Icon type='qrcode' style={{ marginLeft: 4, width: 12, height: 12 }} />
                </span>
              </Popover>
            </div>
            {close && <CloseIcon style={{ width: 12, height: 12, cursor: 'pointer' }} onClick={this.onClose} />}
          </div>
        )}
      </Styles>
    );
  }
}
