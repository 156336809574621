import React from 'react';
import { Button } from '@shuyun-ep-team/kylin-ui';
import clsx from 'classnames';
import { Operation } from '@common/components/notification-center/type';
import { postRead } from '@common/components/notification-center/service';
import { Styles, createStyles } from '@common/components/jss-hooks-in-classes';
import * as styles from './index.jss';

type ClassName = keyof typeof styles;

const classes = createStyles<ClassName>(styles);

interface IOperateBtn {
  messageId: string;
  operate: Operation[];
  onClick?: () => void;
  notification?: boolean;
}

/**
 * @description 通知框操作按钮
 */
export class OperationBtn extends React.Component<IOperateBtn> {
  onClick = (item: Operation) => {
    const { messageId, onClick } = this.props;

    postRead([messageId]);
    window.open(`${location.origin}/${item.url}`, '_self');
    onClick && onClick();
  };

  public render() {
    const { operate, notification } = this.props;

    return (
      <Styles styles={classes}>
        {classes => (
          <div className={clsx(classes.operate, { [classes.customStyle]: !!notification })}>
            {operate.map((item, index) => {
              return (
                <Button key={`${item.name}-${index}`} type='primary' ghost={true} onClick={() => this.onClick(item)}>
                  {item.name}
                </Button>
              );
            })}
          </div>
        )}
      </Styles>
    );
  }
}
