import React from 'react';
import clsx from 'classnames';
import { Transition } from 'react-transition-group';
import { TransitionProps, TransitionStatus } from 'react-transition-group/Transition';

export interface IFadeProps
  extends Pick<
    TransitionProps,
    'in' | 'onEnter' | 'onEntering' | 'onEntered' | 'onExit' | 'onExiting' | 'onExited'
  > {
  // in: boolean;
  fromWidth: number | string;
  toWidth?: number | string;
  duration?: number;
  className?: string;
  render?: (state: TransitionStatus) => React.ReactNode;
}

export const Fold: React.FC<IFadeProps> = props => {
  const {
    fromWidth,
    toWidth = 0,
    duration = 300,
    className,
    children,
    render,
    ...otherProps
  } = props;
  // const duration = props.duration || 300;

  const defaultStyle = {
    transition: `width ${duration}ms cubic-bezier(0.645, 0.045, 0.355, 1)`,
    width: props.fromWidth || 0
  };

  const transitionStyles: any = {
    entering: { width: fromWidth },
    entered: { width: fromWidth },
    exiting: { width: toWidth },
    exited: { width: toWidth }
  };

  return (
    <Transition timeout={duration} {...otherProps}>
      {state => (
        <div
          className={clsx(className)}
          style={{
            ...defaultStyle,
            ...transitionStyles[state]
          }}
        >
          {children ? children : render ? render(state) : null}
        </div>
      )}
    </Transition>
  );
};
