import { MenuEventBus } from '@common/events/menu';
import { LanguageEventBus } from '@common/events/language';
import { PermissionEventBus } from '@common/events/permission';
import { LoginService } from '@common/services/login';
// import { DependencieService } from '@business/services/dependencies';
import { BreadcrumbService } from '@common/services/breadcrumb';
import { LanguageService } from '@common/services/language';
import { checkHeadless } from '@common/utils/browser';
import { SpaService } from '@common/services/spa';
import { AccountService } from '@common/services/account';
import { PermissionService } from '@common/services/permission';
import { ThemeService } from '@common/services/theme';
import { JsonPackageService } from '@common/services/json-package';
import { RequestService } from '@common/services/request';
import { InspectService } from '@common/services/inspect';
import { VerificationService } from '@common/services/verification';

import generate401HTML from '@common/services/errors/401';
import generate404HTML from '@common/services/errors/404';
import generateNonHTML from '@common/services/errors/non';


const EventBusServices = {
  menu: MenuEventBus,
  language: LanguageEventBus,
  permission: PermissionEventBus
};

export const PortalService: KyPortalService.IPortalConfig = {
  InspectService,
  isHeadless: checkHeadless(),
  JsonPackageService,
  ThemeService,
  RequestService,
  AccountService,
  PermissionService,
  EventBusServices,
  // DependencieService,
  BreadcrumbService,
  LanguageService,
  LoginService,
  SpaService,
  VerificationService,
  generate401HTML,
  generate404HTML,
  generateNonHTML,
  jumpToPage401() {
    window.location.hash = '#/401';
  },
  jumpToPage404() {
    window.location.hash = '#/404';
  },
  jumpToPageNon() {
    window.location.hash = '#/non';
  }
};

(window as any).KyPortalService = PortalService;
