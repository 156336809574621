export const UserName = {
  label: 'LoginModal.LoginForm.UserName.label',
  placeholder: 'LoginModal.LoginForm.UserName.placeholder',
  requiredTips: 'LoginModal.LoginForm.UserName.requiredTips'
};


export const Password = {
  label: 'LoginModal.LoginForm.Password.label',
  placeholder: 'LoginModal.LoginForm.Password.placeholder',
  requiredTips: 'LoginModal.LoginForm.Password.requiredTips'
};
