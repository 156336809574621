
// tslint:disable-next-line
const EventEmitter = require('wolfy87-eventemitter');


const ee = new EventEmitter();

type TListener = (language?: string) => void;


function on(fnc: TListener) {
  ee.addListener('language.change', fnc);
}


function off(fnc: TListener) {
  ee.removeListener('language.change', fnc);
}


function emit(language: string) {
  ee.emit('language.change', language);
}


export const LanguageEventBus: KyPortalService.ILanguageEventBus = {
  on, off, emit
};
