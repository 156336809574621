import * as React from 'react';
import { observer } from 'mobx-react';
import SystemStore from '@common/stores/system';
import { matchMenuRoute } from '@business/utils/menu';
import { RouteComponentProps } from 'react-router-dom';
@observer
export default class EntrencePage extends React.Component<RouteComponentProps<any>> {

  public componentDidMount() {

    const { history } = this.props;

    SystemStore.fetchSystemLandingPage().then(menu => {
      const route = matchMenuRoute(menu);

      if (route && menu.isPermission) {
        if (route.isHash) {
          history.push(route.url);
        } else {
          window.location.href = route.url;
        }
      } else {
        history.push('/welcome');
      }
    }).catch(err => {
      throw err;
    });
  }

  public render() {
    return null;
  }
}
