import { IMenu } from "@business/typings/global";
import LanguageStore from '@business/stores/language';
import { getMenuSessionStorageName } from "@business/layouts/constant";


/**
 * 缓存菜单折叠状态
 * @param {boolean} Collapsed 是否折叠菜单
 */
export function setMenuCollapsed(Collapsed: boolean) {
  window.sessionStorage.setItem(getMenuSessionStorageName(), String(Collapsed));
}

/**
 * 获取当前菜单折叠状态
 * @returns boolean 折叠状态
 */
export function getMenuCollapsed() {
  return window.sessionStorage.getItem(getMenuSessionStorageName()) === "true";
}

/**
 * 清除菜单折叠状态缓存
 */
export function removeMenuCollapsed() {
  window.sessionStorage.removeItem(getMenuSessionStorageName());
}

export function matchMenuRoute(menu: IMenu) {
  if (menu.url) {
    const isHash = menu.url.startsWith("#");
    return {
      isHash,
      url: menu.url.replace("#", "")
    };
  }

  return null;
}


/**
 * 计算菜单的名称
 * 由于在注册菜单的时候会默认设置名称为`DefaultMenuName`，
 * 因为`epassport`服务注册菜单时名称不能为空，所以
 *  1、当菜单名称为`DefaultMenuName`时，根据菜单的`code`值去菜单语言包中匹配其对应的名称
 *  2、当菜单名称不为`DefaultMenuName`则直接取`menu.name`值作为名称，因为
 *     菜单名称可以在实施端进行修改，此菜单因此不能享受国际化的名称
 */
export function calculateMenuName(menu: { name: string; code: string }) {
  return menu.name === 'DefaultMenuName' ?
    LanguageStore.stores.menuPackageStore.translate(menu.code) || menu.name :
    menu.name;
}
