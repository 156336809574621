import qs from 'qs';
import { observable, action, runInAction } from 'mobx';
import { fetchSsoConfig, fetchSpConfig } from './service';
import { IWorkWechatProtocol, IDingtalkProtocol } from './types';
import { IWechatQrcodeConfig } from './wechat-form/types';
import { IDingtalkQrcodeConfig } from './dingtalk-form/types';

export interface ISSO {
  id: string;
  name: string;
  configId: string;
}

class LoginStore {
  // @observable public showSSOPopover = false;
  @observable public activeSSO?: ISSO;
  // 是否启用企业微信登陆
  @observable public enabledWorkWechat?: boolean;
  // 是否启用钉钉登陆
  @observable public enabledDingtalk?: boolean;
  // 显示企业微信登陆表单
  @observable public visibleWorkWechat?: boolean;
  @observable public workWechat!: ISSO;
  @observable public dingtalk!: ISSO;

  // 是否为重置密码模式
  @observable public isResetMode = false;
  public tenantId = '';
  @action public setIsResetMode(isResetMode: boolean) {
    this.isResetMode = isResetMode;
  }

  @action public destroy() {
    this.activeSSO = undefined;
    this.isResetMode = false;
  }

  // 单点登录配置
  @observable public isFetching = false;
  // 允许密码登录
  @observable public enablePwd = false;
  // 允许单点登录
  @observable public enableSSO = false;
  @observable public ssos?: ISSO[];

  @action
  public fetchSsoConfig() {
    const tenant = window.CCMS_INFOS_CONFIG.TENANT;
    if (!tenant) {
      return false;
    }
    this.isFetching = true;

    return fetchSsoConfig(tenant)
      .then((res: any) => {
        runInAction(() => {
          const { ssoConfigs = [], enableSSO, enablePwd } = res;
          this.isFetching = false;
          this.enablePwd = enablePwd;
          this.enableSSO = enableSSO;

          if (enableSSO) {
            this.ssos = ssoConfigs.filter(({ type = '' }) => type.toUpperCase() === 'SAML');

            // 是否开启企业微信登录
            const workWechat = ssoConfigs.find(({ type = '' }) => type.toUpperCase() === 'WECHAT');
            this.enabledWorkWechat = Boolean(workWechat);
            this.workWechat = workWechat;

            // 是否开启钉钉登录
            const dingtalk = ssoConfigs.find(({ type = '' }) => type.toUpperCase() === 'DINGTALK');
            this.enabledDingtalk = Boolean(dingtalk);
            this.dingtalk = dingtalk;
          }
        });
      })
      .catch(err => {
        runInAction(() => {
          this.isFetching = false;
        });
        throw err;
      });
  }

  @action
  public openWorkWechat = (visible: boolean) => (this.visibleWorkWechat = visible);

  @observable public isFetchingWechatConf!: boolean;
  @observable public wechatConf!: IWechatQrcodeConfig;

  /**
   * 获取微信登录配置
   * @param ssoConfigId sso Id
   */
  @action
  public getWorkWechatConfig = async (ssoConfigId: string) => {
    this.isFetchingWechatConf = true;
    const { UAL } = window.CCMS_INFOS_CONFIG;
    const { origin } = window.location;

    return fetchSpConfig<IWorkWechatProtocol>('wechat', ssoConfigId as any)
      .then(({ corpId, agentId }) => {
        const param = {
          configId: ssoConfigId,
          login: `${origin}/portal/#/login`,
          bind: `${origin}/portal/#/sso/bind`,
          callback: `${origin}/portal/#`,
          redirect: `${origin}/portal/#/redirect`
        };

        runInAction(() => {
          this.isFetchingWechatConf = false;
          this.wechatConf = {
            appid: corpId,
            agentid: agentId,
            redirectUri: encodeURIComponent(
              `${UAL}/epassport/v1/sso/weChat/callBack?${qs.stringify(param)}`
            ),
            state: 'SHUYUN'
          };
        });
      })
      .catch(err => {
        runInAction(() => (this.isFetchingWechatConf = false));
        throw new Error(err);
      });
  };

  @observable public visibleDingtalk!: boolean;
  @observable public isFetchingDingtalkConf!: boolean;
  @observable public dingtalkConf!: IDingtalkQrcodeConfig;

  @action
  public openDingtalk = (visible: boolean) => (this.visibleDingtalk = visible);

  /**
   * 获取钉钉扫码配置
   * @param ssoConfigId sso Id
   */
  @action
  public getDingtalkConfig = async (ssoConfigId: string) => {
    this.isFetchingDingtalkConf = true;
    const { UAL } = window.CCMS_INFOS_CONFIG;
    const { origin } = window.location;

    return fetchSpConfig<IDingtalkProtocol>('dingtalk', ssoConfigId as any)
      .then(res => {
        const { qrAppId } = res;
        const param = {
          configId: ssoConfigId,
          login: `${origin}/portal/#/login`,
          bind: `${origin}/portal/#/sso/bind`,
          callback: `${origin}/portal/#`,
          redirect: `${origin}/portal/#/redirect`
        };

        runInAction(() => {
          this.isFetchingDingtalkConf = false;

          this.dingtalkConf = {
            appid: qrAppId,
            redirect_uri: encodeURIComponent(
              `${UAL}/epassport/v1/sso/dingtalk/callBack?${qs.stringify(param)}`
            ),
            state: 'STATE'
          };
        });

        return res;
      })
      .catch(err => {
        runInAction(() => (this.isFetchingDingtalkConf = false));
        throw new Error(err);
      });
  };
}

export default new LoginStore();
