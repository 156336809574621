import { StyleSheet } from 'jss';
import { jss } from 'react-jss';
import { ITheme } from '@business/typings/interface';

class StyleAttached {
  private theme!: ITheme;
  private language!: string;
  private rootStyleSheet!: StyleSheet;
  /**
   * 附加样式
   * @param theme 主题配置
   * @param lang 语言
   */
  public attach(theme: ITheme, lang: string) {
    this.theme = theme;
    this.language = lang;

    const rootStyleVariables = this.rootStyleGenerator(lang);
    const { fontFamily, lineHeightBase, fontSizeBase } = this.getLanguageDiffStyleVariables(
      lang,
      true
    );

    this.rootStyleSheet = jss.createStyleSheet({
      '@global': {
        ':root': rootStyleVariables,
        body: {
          fontFamily,
          lineHeight: lineHeightBase,
          fontSize: fontSizeBase
        }
      }
    });

    this.rootStyleSheet.attach();
  }

  /**
   * 销毁附加样式
   */
  public detach() {
    this.rootStyleSheet?.detach();
  }

  /**
   * 变更附加样式
   * @param theme 变更后主题配置
   * @param lang 变更后语言
   */
  public change(theme: ITheme, lang: string) {
    this.detach();

    this.attach(theme, lang);
  }

  /**
   * 仅变更语言的附加样式变更
   * @param lang 变更后语言
   */
  public changeLanguageOnly(lang: string) {
    this.detach();

    this.attach(this.theme, lang);
  }

  /**
   * 仅变更主题配置的附加样式变更
   * @param theme 变更后语言
   */
  public changeThemeOnly(theme: ITheme) {
    this.detach();

    this.attach(theme, this.language);
  }

  /**
   * 生成 CSS 变量
   * @param lang 当前语言
   */
  private rootStyleGenerator = (lang?: string) => {
    const { fontFamily, fontSizeBase, lineHeightBase } = this.getLanguageDiffStyleVariables(lang);

    return {
      '--primary-color': this.getPathValue('root.primaryColor'),
      '--link-hover-color': this.getPathValue('root.linkHoverColor'),
      '--success-color': this.getPathValue('root.successColor'),
      '--error-color': this.getPathValue('root.errorColor'),
      '--warning-color': this.getPathValue('root.warningColor'),
      '--normal-color': this.getPathValue('root.normalColor'),
      '--processing-color': this.getPathValue('root.processingColor'),
      '--ku-body-padding-lg': this.getPathValue('root.kuBodyPaddingLg'),
      '--ku-body-padding-md': this.getPathValue('root.kuBodyPaddingMd'),
      '--ku-body-padding-sm': this.getPathValue('root.kuBodyPaddingSm'),
      '--ku-body-padding-xs': this.getPathValue('root.kuBodyPaddingXs'),
      '--body-background': this.getPathValue('root.bodyBackground'),
      '--heading-1-size': this.getPathValue('root.heading1Size'),
      '--heading-2-size': this.getPathValue('root.heading2Size'),
      '--heading-3-size': this.getPathValue('root.heading3Size'),
      '--heading-4-size': this.getPathValue('root.heading4Size'),
      '--heading-color': this.getPathValue('root.headingColor'),
      '--typography-title-fontWeight': this.getPathValue('typography.typographyTitleFontWeight'),
      '--ku-heading-1-line-height': this.getPathValue('typography.kuHeading1LineHeight'),
      '--ku-heading-2-line-height': this.getPathValue('typography.kuHeading2LineHeight'),
      '--ku-heading-3-line-height': this.getPathValue('typography.kuHeading3LineHeight'),
      '--ku-heading-4-line-height': this.getPathValue('typography.kuHeading4LineHeight'),
      '--typography-title-margin-top': this.getPathValue('typography.typographyTitleMarginTop'),
      '--typography-title-margin-Bottom': this.getPathValue(
        'typography.typographyTitleMarginBottom'
      ),
      '--font-family': fontFamily,
      '--line-height-base': lineHeightBase,
      '--font-size-base': fontSizeBase
    };
  };

  /**
   * 获取因语言差异而不同的样式变量
   * @param lang 语言
   */
  private getLanguageDiffStyleVariables(lang = 'zh-CN', important = false) {
    const fontFamily = this.getPathValue(
      lang === 'en-US' ? 'root.kuFontFamilyEnUs' : 'root.fontFamily',
      important
    );
    const lineHeightBase =
      lang === 'en-US'
        ? `1.2${important ? ' !important' : ''}`
        : this.getPathValue('root.lineHeightBase', important);
    const fontSize = this.getPathValue('root.fontSizeBase');
    const fontSizeBase = lang === 'en-US' ? `calc(${fontSize} - 1px)` : fontSize;

    return {
      fontFamily,
      lineHeightBase,
      fontSizeBase: `${fontSizeBase}${important ? ' !important' : ''}`
    };
  }

  /**
   *
   * @param path 路径
   * @param important 追加 !important
   */
  private getPathValue(path: string, important = false) {
    const paths = path.split('.');

    const value = paths.reduce(
      (theme, p) =>
        Reflect.hasOwnProperty.call(theme, p) ? (theme as Record<string, any>)[p] : '',
      this.theme || {}
    );

    return important ? `${value} !important` : (value as any);
  }
}

export default new StyleAttached();
