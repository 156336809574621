import { action, observable, computed } from 'mobx';
import { BaseLanguagePackageStore } from './base';
import { MenuLanguagePackageStore } from './menu';
import { CommonLanguagePackageStore } from './common';
import { WidgetLanguagePackageStore } from './widget';
import StyleAttached from '@common/services/style-attached';
import { PortalService } from '@common/services/portal';

export class LanguageStore {

  public static instance: LanguageStore;

  public static create(stores: { [key: string]: BaseLanguagePackageStore }) {

    if (!LanguageStore.instance) {
      LanguageStore.instance = new LanguageStore(stores);
    }

    return LanguageStore.instance;
  }


  @observable public language!: string;


  public stores: {
    menuPackageStore: MenuLanguagePackageStore,
    commonPackageStore: CommonLanguagePackageStore,
    widgetPackageStore: WidgetLanguagePackageStore,
    [key: string]: BaseLanguagePackageStore
  } = {
      menuPackageStore: new MenuLanguagePackageStore(),
      commonPackageStore: new CommonLanguagePackageStore(),
      widgetPackageStore: new WidgetLanguagePackageStore(),
    };


  // public menuPackageStore = new MenuLanguagePackageStore();

  // public commonPackageStore = new CommonLanguagePackageStore();

  // // widget 语言包
  // public widgetPackageStore = new WidgetLanguagePackageStore();

  // public portalPackageStore: BusinessPortalLanguageStore | ImplementPortalLanguageStore;

  // 获取语言包
  @computed
  public get i18n() {
    return this.stores.portalPackageStore.i18n;
  }

  @computed
  public get isFetching() {
    return Object.values(this.stores).find(store => {
      return store.isFetching;
    }) ? true : false;
  }


  constructor(stores: { [key: string]: BaseLanguagePackageStore }) {
    Object.assign(this.stores, stores);
  }


  @action public fetchLanguagePackage(language: string) {
    this.language = language;

    document.documentElement.setAttribute('language', language);

    StyleAttached.changeLanguageOnly(language);

    // ---start chrome插件need
    if (PortalService.InspectService.isInspect) {
      sessionStorage.setItem("currentProjectLanguage", language);
      // @ts-ignore
      if (window.sendMessageToContentScriptByPostMessage) {
        // @ts-ignore
        window.sendMessageToContentScriptByPostMessage(language);
      }
    }
    // ---end

    return Promise.all(Object.values(this.stores).map(item => item.fetch(language)));
  }

  @action public updateLangContent(realContent: string, target: any) {
    if (!realContent || !target) {
      throw new Error('no target or realContent');
    }
    target.innerText = realContent;
  }


  public translate = (key: string, data?: any) => {
    return this.stores.portalPackageStore.translate(key, data);
  }
}
