import { observable, action, computed } from 'mobx';
import UserStore from '@common/stores/user';

// 保留n条历史记录
const MAX_RECORDS_NUM = 5;

// 历史记录在localStorage中的索引
const RECENT_SEARCH = 'KYLIN_RECENT_SEARCH';

interface ISearchRecord {
  userId: string;
  records: string[];
}

export class SearchHistoryStore {
  // public userId: string;

  @observable
  public records: ISearchRecord[] = [];

  @computed
  get userId() {
    return UserStore.profile.userId;
  }

  /**
   * 获取当前用户对应的历史记录
   */
  @computed
  get currentRecords() {
    return this.records.find(item => item.userId === this.userId)?.records || [];
  }

  constructor() {
    const localRecords = this.getAllRecords();

    this.records = localRecords;
  }

  /**
   * 从localStorage中取出历史记录
   */
  public getAllRecords() {
    const records = localStorage.getItem(RECENT_SEARCH);

    if (records === null) {
      return [];
    }

    const parsedValue = JSON.parse(records);

    if (!Array.isArray(parsedValue)) {
      throw new Error('请检查localStorage中储存的搜索记录是否为string[]!');
    }

    return parsedValue as ISearchRecord[];
  }

  /**
   * 将新的搜索关键词放到历史记录里面去
   *
   * @param keyword 搜索关键词
   */
  @action
  public push(keyword: string) {
    const recentSearch = this.currentRecords;

    const recordsNum = recentSearch.length;

    const validKeyword = keyword.trim();

    // 去空 去重
    if (validKeyword === '' || recentSearch.includes(keyword)) {
      return;
    }

    let newRecentSearch = [];

    if (recordsNum >= MAX_RECORDS_NUM) {
      newRecentSearch = [keyword, ...recentSearch.slice(0, MAX_RECORDS_NUM - 1)];
    } else {
      newRecentSearch = [keyword, ...recentSearch];
    }

    this.updateRecords(newRecentSearch);
  }

  /**
   * 更新localstorage中的搜索记录
   */
  @action
  public updateRecords(newRecentSearch: string[]) {
    const hasRecords = this.records.some(item => item.userId === this.userId);

    const newRecord = {
      userId: this.userId,
      records: newRecentSearch
    };

    if (hasRecords) {
      this.records = this.records.map(item => {
        if (item.userId === this.userId) {
          return newRecord;
        }

        return item;
      });
    } else {
      this.records.push(newRecord);
    }

    localStorage.setItem(RECENT_SEARCH, JSON.stringify(this.records));
  }

  public dispose() {
    this.records = [];
    localStorage.removeItem(RECENT_SEARCH);
  }
}

export const historyRecordStore = new SearchHistoryStore();
