import * as classes from './index.scss';
import * as React from 'react';
import Store from './store';
import LanguageStore from '@business/stores/language';
import PasswordRuleStore from '@business/stores/password';
import { observer } from 'mobx-react';
import { FormComponentProps } from '@shuyun-ep-team/kylin-ui/lib/form';
import { Button, Input, Form } from '@shuyun-ep-team/kylin-ui';
import { withRouter } from 'react-router-dom';
import * as i18nMap from './i18n.map';
import Translate from '@common/components/translate';
import { LoginService } from '@common/services/login';
import MaskLoading from '@common/components/loading';



interface IProps extends FormComponentProps {
  jumpToLandingPage: () => void;
}


@observer
class ResetForm extends React.Component<IProps> {


  public componentDidMount() {
    PasswordRuleStore.fetchPasswordRules();
  }


  private resetConfirmPassword = () => {
    const { form } = this.props;
    form.setFieldsValue({ confirmPassword: '' });
  }

  private login = () => {
    const { form } = this.props;

    form.validateFields(async (errors: any) => {
      if (errors) {
        return;
      }

      const { password } = form.getFieldsValue() as any;

      Store.reset({
        newPassword: password
      }).then(() => {
        LoginService.close();
        this.props.jumpToLandingPage();
      });

    });
  }


  public render() {
    const { isSubmiting } = Store;
    const { isFetching, passwordRegExp } = PasswordRuleStore;
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const { translate } = LanguageStore;


    return (
      <Form className={classes.form}>
        <MaskLoading visible={isFetching} />
        <div className={classes.formTitle}>
          {translate(i18nMap.title)}
        </div>
        <Form.Item
          label={translate(i18nMap.Password.label)}
          className={classes.formItem}
        >
          {getFieldDecorator('password', {
            rules: [{
              required: true,
              message: <Translate i18n={i18nMap.Password.requiredTips} />
            }, {
              pattern: passwordRegExp,
              message: PasswordRuleStore.calculatePasswordRuleMessage
            }]
          })(
            <Input
              size="large"
              type="password"
              placeholder={translate(i18nMap.Password.placeholder)}
              onChange={this.resetConfirmPassword}
            />
          )}
        </Form.Item>

        <Form.Item
          label={translate(i18nMap.ConfirmPassword.label)}
          className={classes.formItem}
        >
          {getFieldDecorator('confirmPassword', {
            rules: [{
              required: true,
              message: <Translate i18n={i18nMap.ConfirmPassword.requiredTips} />
            }, {
              validator: (__R, input, callback) => {
                if (input === form.getFieldValue('password')) {
                  callback();
                } else {
                  callback(translate(i18nMap.ConfirmPassword.confirmNotEqualTips));
                }
              }
            }]
          })(
            <Input
              size="large"
              type='password'
              placeholder={translate(i18nMap.ConfirmPassword.placeholder)}
            />
          )}
        </Form.Item>

        <Button
          size="large"
          type='primary'
          htmlType="submit"
          className={classes.btnLogin}
          onClick={this.login}
          disabled={isSubmiting}
        >{translate(i18nMap.LoginButtonText)}</Button>

      </Form>
    );
  }
}


export default Form.create()(withRouter(ResetForm as any) as any) as any;
