import { observable, action, runInAction } from 'mobx';
import { updateUserPassword } from './service';
import { IFormData } from './types';



class UserPasswordStore {
  @observable public isSubmiting = false;


  @action public updateUserPassword(formData: IFormData) {
    this.isSubmiting = true;
    return updateUserPassword(formData).then(() => {
      runInAction(() => {
        this.isSubmiting = false;
      });
    }).catch((err: PromiseRejectionEvent) => {
      runInAction(() => {
        this.isSubmiting = false;
      });
      throw err;
    });
  }
}


export default new UserPasswordStore();
