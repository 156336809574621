import { observable, action } from 'mobx';

export type ICallback = (verifyCode: string, cancelAndVerification: () => Promise<any>) => void;

export interface IVerificationConfig {
  closable?: boolean;
  zIndex?: number;
}

class VerificationStore {
  @observable
  public zIndex = 1000;

  @observable
  public hasMobile = false;

  @observable
  public visible = false;

  @observable
  public closable = false;

  @observable
  public renderType: 'phone-binding' | 'captcha-transmission' | '' = '';

  public callback?: ICallback;

  @action
  public verificationMobileBinding(
    config: IVerificationConfig = {
      closable: true,
      zIndex: 1000
    }
  ) {
    this.zIndex = config.zIndex === undefined ? 1000 : config.zIndex;
    this.closable = config.closable === undefined ? true : !!config.closable;
    this.hasMobile = false;
    this.visible = true;
    this.renderType = 'phone-binding';
  }

  @action
  public verificationCaptchaTransmission(
    callback: ICallback,
    config: IVerificationConfig = {
      closable: true,
      zIndex: 1000
    }
  ) {
    this.callback = callback;
    this.zIndex = config.zIndex === undefined ? 1000 : config.zIndex;
    this.closable = config.closable === undefined ? true : !!config.closable;
    this.visible = true;
    this.renderType = 'captcha-transmission';
  }

  @action
  public reset() {
    this.zIndex = 1000;
    this.closable = false;
    this.hasMobile = false;
    this.visible = false;
    this.renderType = '';
  }
}

export const verificationStore = new VerificationStore();
