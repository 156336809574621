import { BaseLanguagePackageStore } from './base';
import { JsonPackageService } from '@common/services/json-package';

/**
 * 获取菜单的语言包，主要包含以下配置
 */
function fetchMenuLanguagePackage(language: string) {
  const url = `/web-common-resource/configs/portal/menu/i18n/${language}.json`;
  return JsonPackageService.fetch(url, {
    // 指定语言包加载失败会默认使用中文语言包
    fallbackURL: `/web-common-resource/configs/portal/menu/i18n/zh-CN.json`
  });
}



export class MenuLanguagePackageStore extends BaseLanguagePackageStore {

  constructor() {
    super(fetchMenuLanguagePackage, 'enterprise-menu-运用端');
  }
}
