import { ICSSProperties } from '@shuyun-ep-team/utils/es/jss/typings/style';
import { antPrefix } from '@shuyun-ep-team/kylin-ui/es/styles/vars';

export const root: ICSSProperties = {
  boxSizing: 'border-box',
  width: '100%',
  height: '100%',
  paddingLeft: 48
};

export const title: ICSSProperties = {
  marginTop: 24,
  marginBottom: 36,
  fontSize: 18,
  lineHeight: '32px',
  color: 'rgba(51, 51, 51, 1)'
};

export const searchInput: ICSSProperties = {
  width: 560,
  height: 40,
  marginBottom: 6,

  [`& .${antPrefix}-input-prefix`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
    color: '#DBDBDB'
  },

  [`& .${antPrefix}-input`]: {
    fontSize: 14,
    color: '#333333',
    paddingLeft: '34px !important'
  }
};

export const helper: ICSSProperties = {
  marginBottom: 20,
  fontSize: 12,
  lineHeight: '17px',
  color: 'rgba(153, 153, 153, 1)'
};

export const resultSection: ICSSProperties = {
  width: 744,
  marginBottom: 30
};

export const label: ICSSProperties = {
  marginBottom: 16,
  fontSize: 12,
  lineHeight: '17px',
  color: '#999999'
};

export const itemList: ICSSProperties = {
  listStyle: 'none',
  padding: 0
};

export const item: ICSSProperties = {
  marginBottom: 16
};

export const itemTitle: ICSSProperties = {
  fontSize: 16,
  lineHeight: '22px',
  color: '#333333',
  marginBottom: 4,
  cursor: 'pointer',

  '&:hover': {
    color: '#333333'
  }
};

export const hightLight: ICSSProperties = {
  color: '#1E84C6',
  background: 'transparent',
  padding: 0
};

export const itemDetail: ICSSProperties = {
  fontSize: 14,
  lineHeight: '20px',
  color: '#666666'
};
