
type TDependencies =
  'angular@1.5.8' |
  'angular-resource@1.5.8' |
  'angular-ui-router' |
  'ccms-ep-components' |
  string
  ;


const versions = process.env.dependencies;


const CommonDependencies: any = {
  'angular@1.5.8': {
    load() {
      return import(/* webpackChunkName: "angular" */'angular');
    }
  },
  'angular-resource@1.5.8': {
    load() {
      return import(/* webpackChunkName: "angular-resource" */'angular-resource');
    }
  },
  'angular-ui-router': {
    load() {
      return import(/* webpackChunkName: "angular-ui-router" */'angular-ui-router');
    }
  },
  'ccms-ep-components': {
    load() {
      return import(/* webpackChunkName: "shuyun-angularjs-components" */'@shuyun-ep-team/angularjs-components');
    }
  }
};


const CommonDependencieNames: string[] = Object.keys(CommonDependencies);


// 判断依赖是否存在
function has(dep: string) {
  return !!CommonDependencieNames.find(item => item === dep);
}


/**
 * 加载依赖模块
 * @param deps 需要加载的依赖列表
 * @param isInOrder 是否按序加载，默认为 true
 */
function load(deps: TDependencies[], isInOrder = true) {

  // 检查依赖是否存在
  for (const dep of deps) {
    if (!has(dep)) {
      throw new ReferenceError(`Portal do not provide the dependencie named \`${dep}\`.`);
    }
  }

  if (isInOrder) {
    // 按序加载
    return loadDependenciesInOrder(deps);
  } else {
    // 并行加载
    return loadDependencies(deps);
  }

}

function loadDependencies(deps: TDependencies[]) {
  const modules: any = new Array(deps.length);
  const promises = deps.map((dep, i) => {
    return CommonDependencies[dep].load().then((item: any) => {
      modules[i] = item;
    });
  });

  return Promise.all(promises).then(() => modules);
}

function loadDependenciesInOrder(deps: TDependencies[]) {
  return deps.reduce((o, dep) => {
    return o.then((modules: any) => {
      return CommonDependencies[dep].load().then((item: any) => {
        modules.push(item);
        return modules;
      });
    });
    return o;
  }, Promise.resolve([]));
}


export const DependencieService: PortalService.IDependencieService = {
  has, load, versions
};
