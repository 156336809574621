import { observable, action, runInAction, toJS } from 'mobx';
import { fetchWithCacheGenerator } from '@shuyun-ep-team/utils/es/fetch-with-cache';
import { epassprotFetch } from '@common/services/fetch';
import PermissionUtils from '@common/utils/permission';


// tslint:disable-next-line
const { fetch: fetchWithCache, clear: clearCache } = fetchWithCacheGenerator(() => {
  return epassprotFetch.get('/account/funcPermissions');
}, () => 'fetchUserPermission');


class UserPermissionStore {

  @observable public isFetching = false;
  @observable public permissions: string[] = [];
  @action public fetch(refresh = false) {

    if (refresh) {
      clearCache();
    }

    this.isFetching = true;
    return fetchWithCache().then((res: any) => {
      runInAction(() => {
        this.isFetching = false;
        this.permissions = res.map((item: { code: string }) => item.code);
      });
      return this.get();
    }).catch(err => {
      runInAction(() => {
        this.isFetching = false;
      });
      throw err;
    });

  }

  @action public destroy() {
    this.permissions = [];
    clearCache();
  }

  public match = (code: string) => {
    return PermissionUtils.match(this.permissions, code);
  }

  public get() {
    return toJS(this.permissions);
  }
}


export default new UserPermissionStore();
