import { observable, action, toJS, computed } from 'mobx';
import { ThemeService } from '@common/services/theme';


class ThemeServiceStore {

  @observable
  public visible = false;

  @action
  public updateVisible(visible: boolean) {
    this.reset();
    this.visible = visible;
  }


  @observable
  public themeVariables: any = {};


  /**
   * 将修改后的语法合并到系统主题
   */
  public mergeToTheme() {
    ThemeService.eventBus.emit(toJS(this.themeVariables));
  }

  @action
  public reset() {
    this.themeVariables = ThemeService.get();
    this.currentTab = this.groups[0];
  }

  @computed
  public get groups() {
    return Object.keys(this.themeVariables);
  }

  @observable
  public currentTab = '';


  @action
  public setCurrentTab(group: string) {
    this.currentTab = group;
  }

  @computed
  public get currentTabVariables() {
    return this.themeVariables[this.currentTab] || {} as { [key: string]: string };
  }

  @action
  public setThemeGroupValue(group: any, path: string, value: string) {
    group[path] = value;
  }

}


export const themeServiceStore = new ThemeServiceStore();



/**
 * 将方法暴露到 window 供开发和测试用
 */
(window as any).debugThemeService = () => {
  themeServiceStore.updateVisible(true);
};
