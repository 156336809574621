import * as classes from './index.scss';
import * as React from 'react';
import { Spin } from '@shuyun-ep-team/kylin-ui';
import { ISpinProps } from '@shuyun-ep-team/kylin-ui/lib/spin';


export interface IProps extends ISpinProps {
  visible: boolean;
}

export default class MaskLoading extends React.Component<IProps> {

  public render() {
    const { visible } = this.props;

    if (!visible) {
      return null;
    }

    return (
      <div className={classes.loader}>
        <Spin />
      </div>
    );
  }
}
