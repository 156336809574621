export const languageLabel = 'AccountPage.ProfilePage.UserLanguage.label';
export const saveButtonText = 'AccountPage.ProfilePage.UserLanguage.saveButtonText';
export const cancelButtonText = 'AccountPage.ProfilePage.UserLanguage.cancelButtonText';
export const updateButtonText = 'AccountPage.ProfilePage.UserLanguage.updateButtonText';
// 操作权限
export const operatePermissionLabel = 'AccountPage.ProfilePage.operatePermission.label';
// 通道权限
export const channelPermissionLabel = 'AccountPage.ProfilePage.channelPermission.label';
export const channelNoPermissionMessage = 'AccountPage.ProfilePage.channelPermission.noPermissionMessage';
// 敏感权限
export const sensitivePermissionLabel = 'AccountPage.ProfilePage.sensitivePermission.label';
export const sensitiveNoPermissionMessage = 'AccountPage.ProfilePage.sensitivePermission.noPermissionMessage';
// 直属组织
export const organizationLabel = 'AccountPage.ProfilePage.organization.label';
export const noOrganizationMessage = 'AccountPage.ProfilePage.organization.noOrganizationMessage';
// 最近一次登录标题
export const lastLoginLabel = 'AccountPage.ProfilePage.lastLogin.label';
// 最近一次登录日期
export const lastLoginDateLabel = 'AccountPage.ProfilePage.lastLoginDate.label';
// 最近一次登录设备
export const lastLoginDeviceLabel = 'AccountPage.ProfilePage.lastLoginDevice.label';
// 最近一次登录位置
export const lastLoginLocationLabel = 'AccountPage.ProfilePage.lastLoginLocation.label';
// 最近一次登录IP
export const lastLoginIPLabel = 'AccountPage.ProfilePage.lastLoginIP.label';

// 不掩码
export const unMaskText = 'AccountPage.ProfilePage.unMaskText';
// 掩码
export const partialMaskText = 'AccountPage.ProfilePage.partialMaskText';
// 全掩码
export const fullMaskText = 'AccountPage.ProfilePage.fullMaskText';
