// 语言翻译工具函数

export function translate(template: string, data: { [key: string]: string }): string {

  return template.replace(/(\{[a-zA-Z\d]+\})/g, (a) => {
    const key = a.replace(/\{|\}/g, '');
    return data[key] || '';
  });

}
