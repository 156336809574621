/**
 * 与实施端 PortalService 不一样的是
 * 应用端需要多添加一个 `DependencieService` 服务用来管理
 * 旧项目`angular.js`的依赖
 */
import { PortalService } from '@common/services/portal';
import { DependencieService } from '@business/services/dependencies';
import { BusinessMenuService } from '@business/services/menu';

PortalService.DependencieService = DependencieService;

/**
 * 应用端暴露所有菜单折叠服务
 */

PortalService.BusinessMenuService = BusinessMenuService;
