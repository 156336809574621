
export function match(
  permissions: string[],
  code: string) {

  return !!permissions.find(item => item === code);
}


const PermissionUtils = {
  match
};


export default PermissionUtils;
