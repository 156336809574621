import * as React from 'react';
import { runInAction } from 'mobx';
import { action, observable, toJS } from 'mobx';
import { translate } from '@common/utils/i18n';
import SystemStore from '@common/stores/system';
import { PortalService } from '@common/services/portal';

interface II18n {
  [key: string]: string;
}

export class BaseLanguagePackageStore {

  public language = '';


  constructor(private fetcher: (lang: string) => Promise<any>, private project: string) {

  }

  // 获取语言包
  @observable
  public i18n!: II18n;

  @observable
  public isFetching = false;


  @action
  public fetch(language: string): Promise<any> {
    this.language = language;
    this.isFetching = true;
    if (PortalService.InspectService.isInspect) {
      return this.inspectFetch(language);
    }
    return this.fetcher(language).then((i18n: II18n) => {
      sessionStorage.setItem(`${this.project}-${language}`, JSON.stringify(i18n));
      sessionStorage.setItem(`${this.project}-${language}-origin`, JSON.stringify(i18n));
      runInAction(() => {
        this.i18n = i18n;
        this.isFetching = false;
      });
    });
  }

  @action
  private inspectFetch(language: string): Promise<any> {

    // 当前系统支持的语言
    const languages = SystemStore.config.languages.map(item => item.language);

    return Promise.all(languages.map((item: string) => {
      return this.fetcher(item)
        .then((i18n: II18n) => {
          sessionStorage.setItem(`${this.project}-${item}`, JSON.stringify(i18n));
          sessionStorage.setItem(`${this.project}-${item}-origin`, JSON.stringify(i18n));
          if (language === item) {
            runInAction(() => {
              this.i18n = i18n;
            });
          }
        });
    }))
      .finally(() => {
        runInAction(() => {
          this.isFetching = false;
        });
      });
  }


  /**
   * 根据语言包的Key值匹配对应的文案
   */
  public translate = (
    key: string,
    data?: { [key: string]: string }
  ): string => {
    const context = this.i18n ? this.i18n[key] || '' : '';
    let text = context;
    if (data) {
      text = translate(context, data);
    }

    return PortalService.InspectService.isInspect ? (
      (<span
        data-i18n-project={this.project}
        data-i18n-key={key}
      >{text} </span>) as any
    ) : text;
  }


  public getPackage() {
    return toJS(this.i18n);
  }

}
