import { action, observable } from 'mobx';
import { NoticeConfig } from './type';

/**
 * 解决如下warning
 * WARNING in ../common/components/notification-center/store.ts 50:86-98
 * "export 'NoticeConfig' was not found in './type'
 */
type TNoticeConfig = NoticeConfig;

/**
 * 消息中心入口Store
 */
export class NoticeStore {
  @observable config: TNoticeConfig = {
    systemNotify: false,
    wechatNotify: false,
    wechatFocusTip: false,
  };

  /**
   * notice 设置配置
   */
  @action
  setConfig = (config: TNoticeConfig) => {
    this.config = Object.assign(this.config, config);
  };

  /**
   * notice 图标被点击
   */
  @action onNoticeClick = () => {
    this.config = { ...this.config, wechatFocusTip: false };
  };
}
