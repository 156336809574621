import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Entrance from '@business/views/entrance';
import UserAccount from '@business/views/account/index';
import PolicyPrivacy from '@business/views/policyPrivacy/index';

import { Page401 } from '@common/views/errors/401';
import { Page404 } from '@common/views/errors/404';
import { NonPage } from '@common/views/errors/non';
import IframePage from '@business/views/iframe';
import { SearchPage } from './search-page';
import { WelcomePage } from '@business/views/welcome';

export default function Router() {
  return (
    <Switch>
      <Route path="/" component={Entrance} exact={true} />

      <Route path="/account" component={UserAccount} />
      <Route path="/policy-privacy" component={PolicyPrivacy} />

      <Route path="/search" render={props => <SearchPage key="search" {...props} />} />

      <Route path="/iframe/:src" component={IframePage} />
      <Route path="/welcome" component={WelcomePage} />
      <Route path="/non" component={NonPage} />
      <Route path="/401" component={Page401} />
      <Route path="/404" component={Page404} />

      {/* <Route path="*" component={Page404} /> */}
    </Switch>
  );
}
