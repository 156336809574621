import { ssoUserFetch, ssoFetch } from "@common/services/fetch";
import { IBindUser, IBindResponse } from "./types";

/**
 * sso 绑定检查
 * @param session 后端生成sessionID
 */
export function check(session: string) {
  return ssoFetch.get(
    // `${(window as any).CCMS_INFOS_CONFIG.UAL}/epassport/v1/bind/check?session=${session}`
    `/bind/check?session=${session}`
  );
}

/**
 * 绑定现有账户
 * @param session sessionID
 * @param ssoUser sso用户实例
 */
export function bind(
  session: string,
  ssoUser: IBindUser
): Promise<IBindResponse> {
  return ssoUserFetch.post(`/bind`, { ...ssoUser, session }, {
    isNotNeedAuth: true
  } as any) as any;
}

/**
 * 直接创建用户
 * @param session sessionID
 */
export function create(session: string): Promise<IBindResponse> {
  return ssoUserFetch.post("/create", { session }, {
    isNotNeedAuth: true
  } as any) as any;
}
