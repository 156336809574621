import { observable, action, computed, runInAction } from 'mobx';
import { fetchUserOperationPermission } from './service';


export interface IPermission {
  id: number;
  code: string;
  name: string;
  parentId: number | null;
  ranking: number;
  enabled: boolean;
  children?: IPermission[];
  parent?: IPermission;
}

class OperationPermissionStore {

  @observable public isFetching = false;
  @observable public permissions: IPermission[] = [];

  @action public fetchPermission() {
    this.isFetching = true;
    return fetchUserOperationPermission().then((res: any) => {
      runInAction(() => {
        this.isFetching = false;
        this.permissions = res;
      });
    }).catch(err => {
      runInAction(() => {
        this.isFetching = false;
      });
      throw err;
    });
  }


  @computed public get nestPermissions() {
    return genNestPermissions(this.permissions);
  }

  @observable public isExpand = false;

  @action public setExpand(isExpand: boolean) {
    this.isExpand = isExpand;
  }
}


export default new OperationPermissionStore();


function genNestPermissions(permissions: IPermission[]) {
  const rootNodes = permissions.filter(item => {
    return !item.parentId || !permissions.find(v => v.id === item.parentId);
  });

  function match(item: IPermission) {
    runInAction(() => {
      item.children = permissions.filter(v => v.parentId === item.id);
      item.children.forEach(v => {
        v.parent = item;
        match(v);
      });
    });
  }

  rootNodes.forEach(item => match(item));

  return rootNodes;
}
