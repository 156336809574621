import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import withStyles, { WithStylesProps } from 'react-jss';

import MenuIcon from '@business/components/menu-icon';
import DoubleLeftIcon from '@shuyun-ep-team/icons/react/DoubleLeft';
import SearchIcon from '@shuyun-ep-team/icons/react/Search';
import ArrowRight from '@shuyun-ep-team/icons/react/ArrowRight';
import MaskLoading from '@common/components/loading';
import UserStore from '@common/stores/user';
import LanguageStore from '@business/stores/language';
import PermissionStore from '@common/stores/permission';
import AvatarIcon from '@shuyun-ep-team/icons/react/Avatar';
import SettingIcon from '@shuyun-ep-team/icons/react/Setting';
import { Menu as AntMenu, Tooltip } from '@shuyun-ep-team/kylin-ui';
import TokenUtils, { getTokenObj, get as getToken } from '@shuyun-ep-team/utils/es/token';
import { INestMenu } from '@business/typings/global';
import { getMenuCollapsed } from '@business/utils/menu';
import { confirm } from '@common/components/modal';
import { ssoLocalStorageName } from '@business/layouts/constant';
import { DefaultLogo, DefaultSecondaryLogo } from '@common/configs/logo';

import BiWithColorIcon from '@shuyun-ep-team/icons/react/BiWithColor';
import { NotificationCenter } from '@common/components/notification-center';
import Store from './store';
import * as styles from './index.jss';
import * as i18nMap from './i18n.map';
import { SearchBar } from './components/search-bar';
import { MenuItem } from './components/menu-item';
import { Fade } from './components/fade';
import { Fold } from './components/fold';
import { ensureTokenOk, getLogoPath } from '../service';

const { SubMenu, Item } = AntMenu;

type ClassName = keyof typeof styles;

export interface IMenuProps extends RouteComponentProps<any>, WithStylesProps<typeof styles> {
  children?: React.ReactNode;
}

interface IMenuState {
  showUserMenu: boolean;
  SearchBarVisible: boolean;
  logoPath: {
    icon: string;
    image: string;
  };
}

@observer
export class Nav extends React.Component<IMenuProps, IMenuState> {
  public state = {
    showUserMenu: false,
    SearchBarVisible: false,
    logoPath: {
      icon: '',
      image: '',
    },
  };

  public componentDidMount() {
    // 读取菜单组件的折叠状态
    Store.updateFoldedStatus(getMenuCollapsed());

    Store.fetchUserAvailableMenus();
    PermissionStore.fetch();

    getLogoPath().then((res: any) => {
      this.setState({
        logoPath: {
          icon: res.iconPath || DefaultLogo,
          image: res.imgPath || DefaultSecondaryLogo,
        },
      });
    });
  }

  public componentWillUnmount() {
    Store.cleanCache();
  }

  public render() {
    const { clsx, openKeys } = this;
    const { classes } = this.props;
    const { SearchBarVisible } = this.state;
    const { menus, isFetching, isFolded } = Store;

    // 判断是否有 dashboard 的权限
    // const hasDashboardPermission = PermissionStore.match('portal.dashboard.access');

    return (
      <nav className={clsx('nav')}>
        {/* 左侧 ⬅️ ⬅️ */}
        <Fold
          className={clsx('navLeft', SearchBarVisible ? 'searchBar' : '')}
          fromWidth={64}
          toWidth={264}
          in={!SearchBarVisible}
          render={state => {
            if (state === 'entered') {
              return this.renderMenuLeft();
            } else if (state === 'exited') {
              return <SearchBar jumpToSearchPage={this.jumpToSearchPage} closeSearchBar={this.closeSearchBar} />;
            }
          }}
        />

        {/* 右侧 ➡️ ➡️ */}
        <Fold
          className={classnames(classes.navRight, 'menuNavRight')}
          fromWidth={200}
          toWidth={0}
          in={!isFolded && !SearchBarVisible}
          render={state => {
            if (state === 'entered') {
              return this.renderMenuRight(menus, isFetching, openKeys);
            }

            return null;
          }}
        />
      </nav>
    );
  }

  public showSearchBar = () => {
    this.setState({ SearchBarVisible: true });
  };

  public closeSearchBar = () => {
    this.setState({ SearchBarVisible: false });
  };

  private getSsoSession = () => {
    return localStorage.getItem(ssoLocalStorageName);
  };

  /**
   * 询问确认退出
   */
  private askForLogout = () => {
    const { translate } = LanguageStore;
    const self = this;
    confirm({
      title: translate(i18nMap.LogoutConfirmModal.title),
      content: translate(i18nMap.LogoutConfirmModal.content),
      onOk: () => {
        const tokenObj = TokenUtils.getTokenObj();
        localStorage.removeItem('isMessage');
        // 用户名密码登录
        if (tokenObj.loginFrom === 'Account') {
          UserStore.logout().then(() => {
            TokenUtils.remove();
            self.logOut();
          });
        } else {
          if (getToken() !== 'undefined undefined') {
            const session = this.getSsoSession();
            // 若session不存在，则直接清空localStorage，定向至登陆页
            if (session) {
              const { origin, pathname } = window.location;
              const callback = `${origin}/${pathname}#/login`;

              UserStore.ssoLogout(session, callback).then(() => {
                TokenUtils.remove();
                localStorage.removeItem(ssoLocalStorageName);
                self.logOut();
              });
            } else {
              TokenUtils.remove();
              localStorage.removeItem(ssoLocalStorageName);
              self.logOut();
            }
          } else {
            self.logOut();
          }
        }
      },
    });
  };

  /**
   * 退出
   */
  private logOut = () => {
    const { history } = this.props;
    history.push(`/login`);
    document.cookie = 'tenant_domain=;path=/;expires=' + new Date(0).toUTCString();
    window.location.reload();
  };

  /**
   * 生成菜单项
   * @param menu 菜单配置
   * @param level 层级
   */
  private generateNestMenus(menu: INestMenu, level: number): React.ReactNode {
    const { clsx } = this;
    const { classes } = this.props;

    const { openKeys: calculatedOpenKeys } = this.calculateActivedMenus();

    const isOpen = this.openKeys.includes(String(menu.id)) || calculatedOpenKeys.includes(String(menu.id));

    let menuHref = menu.addition === 'iframe' ? `#/iframe/${decodeURIComponent(menu.code)}` : menu.url;

    // tab打开
    if (menu.addition === 'blank') {
      if (menuHref.indexOf('?') !== -1) {
        // 已有参数处理
        menuHref += `&token=${getTokenObj().access_token}`;
      } else {
        menuHref += `?token=${getTokenObj().access_token}`;
      }
    }

    const menuItemClassName = classnames({
      [classes.level1Item]: level === 0,
      [classes.level2Item]: level === 1,
      [classes.level3Item]: level >= 2,
    });

    const menuItemLinkClassName = classnames(classes.itemLink, {
      [classes.level1ItemLink]: level === 0,
      [classes.level2ItemLink]: level === 1,
      [classes.level3ItemLink]: level >= 2,
    });

    const menuItemContentClassName = classnames(classes.itemContent, {
      [classes.level1ItemContent]: level === 0,
      [classes.level2ItemContent]: level === 1,
      [classes.level3ItemContent]: level >= 2,
    });

    // 菜单图标 ：仅一级菜单有图标选项
    const menuIcon = level === 0 ? <MenuIcon className={clsx('menuIcon')} icon={menu.icon || ''} /> : null;

    // 菜单名称
    const menuTitleNode = (
      <React.Fragment>
        {menuIcon}
        <MenuItem className={menuItemContentClassName} menu={menu} />
      </React.Fragment>
    );

    if (!menu.children || !menu.children.length) {
      return (
        <Item key={menu.id} className={menuItemClassName}>
          {menu.addition === 'iframe' ? (
            <a
              className={menuItemLinkClassName}
              onClick={() => {
                window.location.hash = menuHref + `?timestamp=${Date.now()}`;
              }}
            >
              {menuTitleNode}
            </a>
          ) : (
            <a href={menuHref} className={menuItemLinkClassName} target={menu.addition === 'blank' ? '_blank' : ''}>
              {menuTitleNode}
            </a>
          )}
        </Item>
      );
    }

    const subMenuTitleContent = menu.url ? (
      <a href={menuHref} className={menuItemLinkClassName}>
        {menuTitleNode}
      </a>
    ) : (
      menuTitleNode
    );

    const subMenuTitle = (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {subMenuTitleContent}
        <ArrowRight className={isOpen ? classes.arrowOpen : classes.arrow} />
      </div>
    );

    return (
      <SubMenu className={menuItemClassName} key={String(menu.id)} title={subMenuTitle}>
        {menu.children.map(item => this.generateNestMenus(item, level + 1))}
      </SubMenu>
    );
  }

  @observable private openKeys: string[] = [];

  @action
  private onOpenChange = (openKeys: string[]) => {
    this.openKeys = openKeys;
  };

  @action
  private calculateActivedMenus() {
    const { pathname } = this.props.location;
    const { selectedKeys, openKeys } = match(Store.menus, `#${pathname}`, [], []);

    return {
      selectedKeys,
      openKeys: getMenuCollapsed() ? [] : openKeys,
    };
  }

  private handleToogle = () => {
    this.setState(pre => ({ ...pre }));
    Store.updateFoldedStatus(!Store.isFolded);
  };

  private clsx = (...args: Array<ClassName | ''>) => {
    const mergedClasses = args.map(name => {
      if (name === '') {
        return '';
      }
      return this.props.classes[name];
    });

    return classnames(...mergedClasses);
  };

  // 进入到 kylinBI
  private goToBI = () => {
    const token = TokenUtils.getTokenObj()?.access_token;
    const biUrl = (window as any).CCMS_INFOS_CONFIG.KYLINBIDOMAIN + '/rest/bi/session/oauth/login/?token=' + token;
    ensureTokenOk().then(() => {
      window.open(biUrl, '_blank');
    });
  };

  private renderMenuLeft = () => {
    const { clsx } = this;
    const { SearchBarVisible } = this.state;
    const { translate } = LanguageStore;
    const { isFolded } = Store;

    // 判断是否为账号密码登录，非账号密码登录没有修改密码页面
    const isLoginFromAccount = TokenUtils.getTokenObj().loginFrom === 'Account';

    if (SearchBarVisible) {
      return null;
    }

    return (
      <Fade in={!SearchBarVisible} style={{ width: '100%' }} className={clsx('navLeft')} duration={500}>
        {state => {
          if (state !== 'entered') {
            return null;
          }

          return (
            <>
              {/* logo */}
              <div className={clsx('icon', 'logo')}>
                {this.state.logoPath.icon ? (
                  <img src={this.state.logoPath.icon} width='32px' height='32px' alt='logo' />
                ) : null}
              </div>

              {/* 搜索 icon */}
              <div className={clsx('icon', 'iconWithHoverFrame', 'quickIcon')} onClick={this.showSearchBar}>
                <Tooltip title={translate(i18nMap.IconTooltip.search)} placement='right'>
                  <SearchIcon />
                </Tooltip>
              </div>

              {/* BI icon */}
              {PermissionStore.permissions.includes('kylin.bi.canAccess.newbi') && (
                <div className={clsx('icon', 'iconWithHoverFrame', 'quickIcon')} onClick={this.goToBI}>
                  <Tooltip title='Kylin BI' placement='right'>
                    <BiWithColorIcon />
                  </Tooltip>
                </div>
              )}

              <div className={clsx('flex')}></div>

              {/* 消息中心 */}
              <NotificationCenter />

              {/* 系统设置 icon */}
              <a className={clsx('icon', 'iconWithHoverFrame', 'systemIcon')} href='/implement/' target='_blank'>
                <Tooltip title={translate(i18nMap.IconTooltip.system)} placement='right'>
                  <SettingIcon />
                </Tooltip>
              </a>

              {/* 用户 icon */}
              <div
                className={clsx('systemIconPlaceholder')}
                onMouseOver={() => this.setState({ showUserMenu: true })}
                onMouseOut={() => this.setState({ showUserMenu: false })}
              >
                <a className={clsx('icon', 'iconWithHoverFrame', 'systemIcon')}>
                  {/* <Tooltip title={translate(i18nMap.IconTooltip.user)} placement="right"> */}
                  <AvatarIcon />
                  {/* </Tooltip> */}

                  {/* 「我的」菜单 position: absolute */}
                  <Fade in={this.state.showUserMenu}>
                    {() => (
                      <div className={clsx('ctxMenuFrame', 'userMenu')}>
                        <ul className={clsx('ctxMenu')}>
                          {/* 用户真实姓名 */}
                          <li className={clsx('ctxMenuItemWithoutCursor')}>{UserStore.profile.realName}</li>

                          <div className={clsx('ctxMenuDivider')} />

                          <li
                            className={clsx('ctxMenuItemWithHover')}
                            onClick={() =>
                              this.props.history.push({
                                pathname: '/account/profile',
                              })
                            }
                          >
                            {translate(i18nMap.UserMenu.account)}
                          </li>

                          {isLoginFromAccount ? (
                            <li
                              className={clsx('ctxMenuItemWithHover')}
                              onClick={() => this.props.history.push('/account/password')}
                            >
                              {translate(i18nMap.UserMenu.password)}
                            </li>
                          ) : null}

                          <li
                            className={clsx('ctxMenuItemWithHover')}
                            onClick={() =>
                              this.props.history.push({
                                pathname: '/policy-privacy',
                              })
                            }
                          >
                            {translate(i18nMap.UserMenu.policyPrivacy)}
                          </li>

                          <div className={clsx('ctxMenuDivider')} />

                          <li className={clsx('ctxMenuItemWithHover')} onClick={this.askForLogout}>
                            {translate(i18nMap.UserMenu.logout)}
                          </li>
                        </ul>
                      </div>
                    )}
                  </Fade>
                </a>
              </div>

              {/* 分割线 */}
              <div className={clsx('splitLine')}></div>

              {/* toggle icon */}
              <div className={clsx('icon', 'toggleIcon')} onClick={this.handleToogle}>
                <DoubleLeftIcon className={clsx(isFolded ? 'toggleIconRight' : 'toggleIconLeft')} />
              </div>
            </>
          );
        }}
      </Fade>
    );
  };

  private jumpToSearchPage = (keyword: string) => {
    this.setState({
      SearchBarVisible: false,
      // isFolded: true
    });
    this.props.history.push(`/search?keyword=${keyword}`);
  };

  private renderMenuRight = (menus: any[], isFetching: boolean, openKeys: string[]) => {
    const { clsx } = this;
    const { selectedKeys, openKeys: calculatedOpenKeys } = this.calculateActivedMenus();

    return (
      <React.Fragment>
        <header className={clsx('header')}>
          {/* <span style={{ color: 'rgba(255, 255, 255, 1)', fontSize: 25 }}>KYLIN</span> */}
          {this.state.logoPath.image && (
            <img className={clsx('rightLogo')} src={this.state.logoPath.image} alt='logo' />
          )}
        </header>

        <MaskLoading visible={isFetching} />

        <div className={clsx('menuContainer')}>
          <AntMenu
            mode='inline'
            theme='dark'
            selectedKeys={selectedKeys}
            /**
             * observable 对象 openKeys 长度为 0 时，使用通过当前路由匹配的打开节点值
             * 否则使用 observable 对象 openKeys
             * 此处这样用是因为菜单的打开收起
             */
            openKeys={openKeys.length ? openKeys : calculatedOpenKeys}
            className={clsx('menu')}
            inlineIndent={16}
            onOpenChange={this.onOpenChange}
          >
            {menus.map(menu => this.generateNestMenus(menu, 0))}
          </AntMenu>
        </div>
      </React.Fragment>
    );
  };
}

export const Menu = withStyles(styles, { injectTheme: true })(withRouter(Nav));

function match(menus: any[], pathname: string, selectedKeys: any[] = [], openKeys: any[] = []) {
  menus.forEach((item: any) => {
    const { id, children = [], url, parentKeys = [] } = item;

    /**
     * 匹配模式
     *  若 url 为 #/loyalty/operations
     *    则 #/loyalty/operations、#/loyalty/operations/special、#/loyalty/operations/XX 都会被匹配到
     *    但是 #/loyalty/operations/ 不会被匹配到
     */
    if (url === pathname || new RegExp(`^${url}/.+`).test(pathname)) {
      selectedKeys.push(String(id));

      openKeys.push(parentKeys);
    }

    if (children.length) {
      match(children, pathname, selectedKeys, openKeys);
    }
  });

  return { openKeys: openKeys.toString().split(','), selectedKeys };
}
