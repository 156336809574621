import { action, observable, runInAction } from 'mobx';
import { login, getCode } from '../service';

const config = (window as any).CCMS_INFOS_CONFIG;
class LoginFormStore {

  @observable public isSubmiting = false;
  @action public login(formData: { username: string,
      password: string,
      tenant_domain: string,
      verification_code: string }) {
    this.isSubmiting = true;

    const data = {
      ...formData,
      grant_type: 'password',
      tenant_id: config.SAAS === 'false' ? config.TENANT : undefined,
      is_sass: config.SAAS === 'true' ? '1' : '0',
      is_message: config.ISMESSAGE === 'true' ? '1' : '0',
    };
    return login(data).then(res => {
      runInAction(() => {
        this.isSubmiting = false;
      });
      return res;
    }).catch(err => {
      runInAction(() => {
        this.isSubmiting = false;
      });
      throw err;
    });
  }

  @action public getCode(formData: { username: string, password: string , tenant_domain: string}) {
    const data = {
      ...formData,
      grant_type: 'password',
      tenant_id: config.SAAS === 'false' ? config.TENANT : undefined,
      is_sass: config.SAAS === 'true' ? '1' : '0',
      is_message: config.ISMESSAGE === 'true' ? '1' : '0',
    };
    return getCode(data).then(res => {
      return res;
    }).catch(err => {
      throw err;
    });
  }

}

export default new LoginFormStore();
