import React, { RefObject } from 'react';
import classnames from 'classnames';
import withStyles, { WithStylesProps } from 'react-jss';
import LanguageStore from '@business/stores/language';
import CloseIcon from '@shuyun-ep-team/icons/react/Close';
import { historyRecordStore } from '../../search-page/search-history-store';
import * as styles from './search-bar.jss';
import { Input } from '@shuyun-ep-team/kylin-ui';
import * as i18nMap from '../i18n.map';

type ClassName = keyof typeof styles;

export interface ISearchBarProps extends WithStylesProps<typeof styles> {
  jumpToSearchPage: (keyword: string) => void;
  closeSearchBar: () => void;
}

class SearchBarComp extends React.Component<ISearchBarProps, {}> {
  public searchBarRef: RefObject<HTMLDivElement> | null = null;
  public searchInputRef: RefObject<Input> | null = null;

  public constructor(props: Readonly<ISearchBarProps>) {
    super(props);
    this.searchInputRef = React.createRef();
  }

  public componentDidMount() {
    const timer = setTimeout(() => {
      clearTimeout(timer);
      this.focus();
    }, 0);

    document.addEventListener('click', this.props.closeSearchBar);
  }

  public componentWillUnmount() {
    document.removeEventListener('click', this.props.closeSearchBar);
  }

  public render() {
    const { clsx } = this;
    const records = historyRecordStore.currentRecords;
    const { translate } = LanguageStore;

    return (
      <div ref={this.searchBarRef} className={clsx('searchBar')} onClick={e => {
        e.nativeEvent.stopImmediatePropagation();
      }}>
        <header className={clsx('searchBarHeader')}>
          <div className={clsx('searchBarCancelIcon')} onClick={this.props.closeSearchBar}>
            <CloseIcon />
          </div>
          <Input
            ref={this.searchInputRef}
            className={clsx('searchBarInput')}
            placeholder={translate(i18nMap.Search.placeholder)}
            onPressEnter={(e: any) => {
              this.props.jumpToSearchPage(e.target.value);
            }}
          />
        </header>

        {records.length > 0 ? (
          <nav className={clsx('searchBarHistoryPanel')}>
            <div className={clsx('historyPanelLabel')}>{translate(i18nMap.Search.label)}</div>
            <ul className={clsx('historyList')}>
              {records.map((keyword: string) => {
                return (
                  <li
                    key={keyword}
                    className={clsx('historyItem')}
                    onClick={() => this.props.jumpToSearchPage(keyword)}>
                    {keyword}
                  </li>
                );
              })}
            </ul>
          </nav>
        ) : null}
      </div>
    );
  }

  public focus = () => {
    if (this.searchInputRef !== null && this.searchInputRef.current !== null) {
      this.searchInputRef.current.focus();
    }
  };

  private clsx = (...args: Array<ClassName | ''>) => {
    const mergedClasses = args.map(name => {
      if (name === '') {
        return '';
      }
      return this.props.classes[name];
    });

    return classnames(...mergedClasses);
  };
}

export const SearchBar = withStyles(styles, { injectTheme: true })(SearchBarComp);
