/**
 * 打印废弃日志
 * @param info 信息
 */
export function deprecated(info?: string) {
  return (__T: any, name: string) => {
    deprecatedLogger(name, info);
  };
}

/**
 * 打印废弃日志
 * @param name 方法名
 * @param info info
 */
export function deprecatedLogger(name: string, info?: string) {
  // tslint:disable-next-line: no-console
  console.warn(
    `Deprecated: ${name} 方法已废弃，将在下一版本中移除，请谨慎使用！`,
    info
  );
}
