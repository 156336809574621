import * as qs from 'qs';
import axios from 'axios';
import { LanguageService } from '@common/services/language';
import { get as getToken } from '@shuyun-ep-team/utils/es/token';

function generateRequestHeaders() {
  const Authorization = getToken();
  const AcceptLanguage = LanguageService.getLanguage();

  const headers: {
    Authorization?: string;
    'Accept-Language'?: string;
  } = {};

  if (Authorization) {
    headers.Authorization = Authorization;
  }
  if (AcceptLanguage) {
    headers['Accept-Language'] = AcceptLanguage;
  }

  return headers;
}

/**
 * 获取所有语言配置参数 withI18nPayload=true
 */
function generateWithAllI18nQueryParams() {
  return qs.stringify({ withI18nPayload: true });
}

// 通用的UAL请求
const commonFetch = axios.create({});
commonFetch.interceptors.request.use(config => {
  const DefaultHeaders = generateRequestHeaders();

  if (!config.headers.Authorization && DefaultHeaders.Authorization) {
    config.headers.Authorization = DefaultHeaders.Authorization;
  }

  if (!config.headers['Accept-Language'] && DefaultHeaders['Accept-Language']) {
    config.headers['Accept-Language'] = DefaultHeaders['Accept-Language'];
  }

  if (!config.headers['Content-Type']) {
    config.headers['Content-Type'] = 'application/json';
  }

  const isFormData = config.data instanceof FormData;

  // 格式化参数
  if (config.method !== 'get' && !isFormData) {
    if (config.headers['Content-Type'] !== 'application/json') {
      config.data = qs.stringify(config.data);
    }
  }

  return config;
});

export const RequestService: KyPortalService.IRequestService = {
  generateRequestHeaders,
  generateWithAllI18nQueryParams,
  commonFetch
};
