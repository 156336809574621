import { message as Message } from '@shuyun-ep-team/kylin-ui';
import { LoginService } from '@common/services/login';
import { AxiosResponse, AxiosInstance } from 'axios';


export function setResponseInterceptor(fetch: AxiosInstance) {
  fetch.interceptors.response.use((response: AxiosResponse) => {
    return response.data;
  }, (response: any) => {

    // 是否需要自动处理错误
    const AutoError = response.config && response.config.AutoError;

    if (response.response && response.response.data && (response.response.data.msg || response.response.data.message)) {

      const data = response.response.data;

      // 401
      if (response.response.status === 401) {
        const code = data.code;

        // 被踢
        if (code === 'kicked_out') {
          LoginService.open('Kickout');
        }
        // 登录超时
        else if (code === 'timeout') {
          LoginService.jumpToLogin();
        }
        // else if (isNeedLogin(code)) {
        //   LoginService.jumpToLogin();
        // }
        else {
          LoginService.jumpToLogin();
        }
      } else {
        if (AutoError) {
          Message.error(data.msg || data.message);
        }
      }

      throw data;
    }

    const errorRes = response.response || response;
    if (AutoError) {
      Message.error(response.message || errorRes.message || errorRes.status);
    }
    throw errorRes;
  });
}


// function isNeedLogin(code: string) {
//   return [
//     'need_login', // 请登陆
//     'miss_token', // 缺少token
//     'invalid_token', // 无效token
//     'type_error_token', // 非access token
//     'illegal_user', // 用户不存在或禁用
//     'not_exist_session' // 不存在此session
//   ].indexOf(code) > -1;
// }
