export const UserName = {
  label: 'LoginPage.LoginForm.UserName.label',
  domain: 'LoginPage.LoginForm.UserName.domain',
  domainRequiredTips: 'LoginPage.LoginForm.UserName.domain.requiredTips',
  placeholder: 'LoginPage.LoginForm.UserName.placeholder',
  requiredTips: 'LoginPage.LoginForm.UserName.requiredTips',
  mismatch: "LoginPage.LoginForm.UserName.domain.mismatch"
};


export const Password = {
  label: 'LoginPage.LoginForm.Password.label',
  placeholder: 'LoginPage.LoginForm.Password.placeholder',
  requiredTips: 'LoginPage.LoginForm.Password.requiredTips'
};
export const Code = {
  label: 'LoginPage.LoginForm.Code.label',
  placeholder: 'LoginPage.LoginForm.Code.placeholder',
  requiredTips: 'LoginPage.LoginForm.Code.requiredTips',
  buttonText: 'LoginPage.LoginForm.Code.Button.text',
  resendButtonText: 'LoginPage.LoginForm.Code.resendButton.text',
  buttonCountdown: 'LoginPage.LoginForm.Code.Button.Countdown'
};

export const LoginButtonText = 'LoginPage.LoginForm.LoginButton.text';
