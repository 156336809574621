import { ThemeStore } from '@common/stores/theme';

// tslint:disable-next-line
const EventEmitter = require('wolfy87-eventemitter');

const ee = new EventEmitter();

export const ThemeService: KyPortalService.IThemeService = {
  get() {
    return ThemeStore.get();
  },
  eventBus: {
    on(fnc) {
      ee.addListener('theme.change', fnc);
    },
    off(fnc) {
      ee.removeListener('theme.change', fnc);
    },
    emit(patch: { [key: string]: string }) {
      const theme = ThemeStore.get();

      Object.keys(patch).forEach(path => {
        mergeThemeValues(theme, path, patch[path]);
      });

      ee.emit('theme.change', theme);
    }
  },
  commonStyleUtils: {
    getH1: () => ({
      fontSize: ThemeStore.theme.root.heading1Size,
      color: ThemeStore.theme.root.headingColor,
      fontWeight: ThemeStore.theme.typography.typographyTitleFontWeight,
      lineHeight: ThemeStore.theme.typography.kuHeading1LineHeight,
      marginTop: ThemeStore.theme.typography.typographyTitleMarginTop,
      marginBottom: ThemeStore.theme.typography.typographyTitleMarginBottom
    }),
    getH2: () => ({
      fontSize: ThemeStore.theme.root.heading2Size,
      color: ThemeStore.theme.root.headingColor,
      fontWeight: ThemeStore.theme.typography.typographyTitleFontWeight,
      lineHeight: ThemeStore.theme.typography.kuHeading2LineHeight,
      marginTop: ThemeStore.theme.typography.typographyTitleMarginTop,
      marginBottom: ThemeStore.theme.typography.typographyTitleMarginBottom
    }),
    getH3: () => ({
      fontSize: ThemeStore.theme.root.heading3Size,
      color: ThemeStore.theme.root.headingColor,
      fontWeight: ThemeStore.theme.typography.typographyTitleFontWeight,
      lineHeight: ThemeStore.theme.typography.kuHeading3LineHeight,
      marginTop: ThemeStore.theme.typography.typographyTitleMarginTop,
      marginBottom: ThemeStore.theme.typography.typographyTitleMarginBottom
    }),
    getH4: () => ({
      fontSize: ThemeStore.theme.root.heading4Size,
      color: ThemeStore.theme.root.headingColor,
      fontWeight: ThemeStore.theme.typography.typographyTitleFontWeight,
      lineHeight: ThemeStore.theme.typography.kuHeading4LineHeight,
      marginTop: ThemeStore.theme.typography.typographyTitleMarginTop,
      marginBottom: ThemeStore.theme.typography.typographyTitleMarginBottom
    })
  }
};

ThemeService.eventBus.on(theme => {
  ThemeStore.update(theme);
});

function mergeThemeValues(themeObj: any, path: string, value: string) {
  const paths = path.split('.');

  let obj = themeObj as any;

  paths.slice(0, -1).forEach(p => {
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      obj = obj[p];
    }
  });

  obj[paths[paths.length - 1]] = value;
}
