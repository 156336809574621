import React from 'react';
import { List } from '@shuyun-ep-team/kylin-ui';
import clsx from 'classnames';
import InfiniteScroll from 'react-infinite-scroller';
import NoNoticeIcon from '@shuyun-ep-team/icons/react/NoNotice';
import { Styles, createStyles } from '@common/components/jss-hooks-in-classes';
import { GetMessageListRes } from '@common/components/notification-center/type';
import { NoticeItem } from './notice-item';
import * as styles from './index.jss';

type ClassName = keyof typeof styles;

const classes = createStyles<ClassName>(styles);

interface IProps {
  messages: GetMessageListRes['messages'];
  onInfiniteOnLoad: () => void;
  hasMore: boolean;
  loading: boolean;
  emptyText: React.ReactNode;
  onRead?: () => void;
}

interface IState {
  activeKey: string;
}

/**
 * @description 消息列表
 */
export class NoticeList extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      activeKey: '',
    };
  }

  handleInfiniteOnLoad = () => {
    this.props.onInfiniteOnLoad();
  };

  onCollapse = (key: string) => {
    const { activeKey } = this.state;
    this.setState({
      activeKey: activeKey === key ? '' : key,
    });
  };

  onRead = () => {
    this.props.onRead && this.props.onRead();
  };

  public render() {
    const { messages, hasMore, loading, emptyText } = this.props;

    const { activeKey } = this.state;

    return (
      <Styles styles={classes}>
        {classes => (
          <div className={clsx(classes.listWrap, { [classes.emptyWrap]: messages.length === 0 })}>
            <InfiniteScroll
              initialLoad={false}
              pageStart={0}
              loadMore={this.handleInfiniteOnLoad}
              hasMore={!loading && hasMore}
              useWindow={false}
              style={{ height: messages.length === 0 ? '100%' : 'auto' }}
            >
              <List
                locale={{
                  emptyText: (
                    <div>
                      <NoNoticeIcon style={{ width: 64, height: 64 }} />
                      <div className={classes.emptyText}>{emptyText}</div>
                    </div>
                  ),
                }}
                dataSource={messages}
                renderItem={item => (
                  <NoticeItem
                    key={item.id}
                    message={item}
                    activeKey={activeKey}
                    onCollapse={this.onCollapse}
                    onRead={this.onRead}
                  />
                )}
              ></List>
            </InfiniteScroll>
          </div>
        )}
      </Styles>
    );
  }
}
