import qs from "qs";
import * as React from "react";
import { observer } from "mobx-react";
import { observable, runInAction, action } from "mobx";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { set as setToken } from "@shuyun-ep-team/utils/es/token";
import { Button } from "@shuyun-ep-team/kylin-ui";
import languageStore from "@business/stores/language";
import Modal from "@common/components/modal";
import MaskLoading from "@common/components/loading";
import { check, create } from "./service";
import * as i18nMap from "./i18n.map";
import BindLocalUser from "./bind-local";
import SystemStore from "@common/stores/system";
const { translate } = languageStore;

@observer
class SsoBind extends React.Component<RouteComponentProps> {
  private session!: string;
  @observable private isFetching!: boolean;
  @observable private isCreateFetching!: boolean;
  @observable private checkVisible!: boolean;
  @observable private bindVisible!: boolean;
  // public static defaultProps = {};

  public getSnapshotBeforeUpdate(prevProps: RouteComponentProps) {
    const prevSession = this.getParams(prevProps.location.search).session;
    const currentSession = this.getParams(this.props.location.search).session;

    if (prevSession !== currentSession && currentSession) {
      this.checkUser(currentSession).then(() => {
        this.session = currentSession;
        runInAction(() => (this.checkVisible = true));
      });
    }

    return null;
  }

  public componentDidMount() {
    SystemStore.fetchSystemConfig().then(() => {
      languageStore.fetchLanguagePackage(SystemStore.config.language);
    });
    const {
      location: { search },
      history
    } = this.props;

    const { session } = this.getParams(search);

    if (session) {
      this.checkUser(session).then(() => {
        this.session = session;
        runInAction(() => {
          this.checkVisible = true;
        });
      });
    } else {
      history.replace("/login");
    }
  }

  /**
   * 检查用户session是否合法
   */
  @action
  private checkUser = (session: string) => {
    this.isFetching = true;

    return check(session)
      .then(res => {
        runInAction(() => {
          this.isFetching = false;
        });
        return res;
      })
      .catch(err => {
        runInAction(() => {
          this.isFetching = false;
        });
        throw err;
      });
  };

  /**
   * 创建本地用户
   */
  @action
  private onCreateUser = () => {
    this.isCreateFetching = true;
    return create(this.session)
      .then(res => {
        runInAction(() => (this.isCreateFetching = false));

        setToken(res.token);

        window.location.replace(res.callback);
      })
      .catch(err => {
        runInAction(() => {
          this.isCreateFetching = false;
        });
        throw err;
      });
  };

  @action
  private onBindUser = () => {
    this.bindVisible = true;
    this.checkVisible = false;
  };

  /**
   * 获取地址参数
   */
  private getParams = (search: string = "") => {
    return qs.parse(search.replace(/^\?/, "")) as { session: string };
  };

  private onCancel = () => {
    const { history } = this.props;

    history.replace("/login");
  };

  public render() {
    const { isFetching, i18n } = languageStore;

    return (
      <>
        {this.isFetching || isFetching ? <MaskLoading visible={true} /> : null}

        {i18n ? (
          <Modal
            width={560}
            visible={this.checkVisible}
            title={translate(i18nMap.checkTitle)}
            footer={false}
            onCancel={this.onCancel}
            maskClosable={false}
            destroyOnClose={true}
          >
            {this.isCreateFetching || isFetching ? (
              <MaskLoading visible={true} />
            ) : null}

            <div style={{ textAlign: "center", margin: 20 }}>
              <Button style={{ width: 320 }} onClick={this.onCreateUser}>
                {translate(i18nMap.createBtn)}
              </Button>
              <Button
                style={{ width: 320, marginTop: 20 }}
                type="primary"
                onClick={this.onBindUser}
              >
                {translate(i18nMap.bindBtn)}
              </Button>
            </div>
          </Modal>
        ) : null}
        {this.bindVisible ? <BindLocalUser token={this.session} /> : null}
      </>
    );
  }
}

export default withRouter(SsoBind);
