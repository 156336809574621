import UserMenuStore from '@business/layouts/main/menu/store';
/**
 * 隐藏主菜单
 * 应用端隐藏主菜单
 */

function hideAll() {
  UserMenuStore.updateFoldedStatus(true);
}

/**
 * 显示主菜单
 * 应用端显示主菜单
 */

function showAll() {
  UserMenuStore.updateFoldedStatus(false);
}

export const BusinessMenuService = {
  hideAll,
  showAll
};
