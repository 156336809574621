import * as classes from './index.scss';
import * as React from 'react';
import { observer } from 'mobx-react';
import TimeoutIcon from 'shuyun-ep-icons/react/Timeout';
import * as i18nMap from './i18n.map';
import { LanguageStore } from '@common/stores/language';



@observer
export default class Timeout extends React.Component {
  public render() {
    const { translate } = LanguageStore.instance;

    const mainDescription = translate(i18nMap.mainDescription);
    const subDescription = translate(i18nMap.subDescription);

    return (
      <div className={classes.container}>
        <TimeoutIcon className={classes.icon} />
        <h3 className={classes.h}>{mainDescription}</h3>
        <p className={classes.desc}>{subDescription}</p>
      </div>
    );
  }
}
