import * as React from 'react';
import * as classes from '../401/index.scss';
import { LanguageStore } from '@common/stores/language';

// tslint:disable-next-line
const pic404 = require('@common/assets/images/404.png');


export const Page404 = () => {
  const { translate } = LanguageStore.instance.stores.commonPackageStore;
  return (
    <div className={classes.container}>
      <div className={classes.body}>
        <img src={pic404} className={classes.bgImage} />
        <p className={classes.title}>{translate('ErrorPage.NoExist')}</p>
      </div>
    </div>
  );
};


