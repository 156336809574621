import { JssStyle } from 'jss';

export const wrap: JssStyle = {
  display: 'flex',
  fontSize: '12px',
  lineHeight: '17px',
  height: '32px',
  borderRadius: '2px',
  border: '1px solid #FFF1B8',
  padding: '0 12px',
  alignItems: 'center',
  marginBottom: 12,
};

export const left: JssStyle = {
  flex: 1,
};
