import * as SystemJS from 'systemjs';
import * as singleSPA from 'single-spa';
import { IApp } from '@business/typings/global';
import SpaStore from './store';
import { BreadcrumbService } from '@common/services/breadcrumb';
import { PortalService } from '@common/services/portal';


export function initSpaService(apps: IApp[]) {

  apps.forEach(app => {
    // 入口文件路径，需要保证不缓存
    const entrance = `${app.entrance}?ts=${Date.now()}`;

    const loadingFnc = () => {
      SpaStore.update({ isLoading: true });
      return SystemJS.import(entrance).then(entranceModule => {
        return {
          ...entranceModule,
          bootstrap: (data: any) => {
            try {
              return PortalService.SpaService.getRoot().then(() => {
                return entranceModule.bootstrap(data);
              });
            } catch (err) {
              return Promise.reject(err);
            }
          },
          mount: (data: any) => {
            try {
              return PortalService.SpaService.getRoot().then(() => {
                return entranceModule.mount(data).then(() => {
                  SpaStore.update({ isLoading: false });
                });
              });
            } catch (err) {
              return Promise.reject(err);
            }
          },
          unmount: (data: any) => {
            try {
              return entranceModule.unmount(data).then(() => {
                BreadcrumbService.render('');
              });
            } catch (err) {
              return Promise.reject(err);
            }
          }
        };
      });
    };

    singleSPA.registerApplication(
      app.name,
      loadingFnc,
      hashPrefix(app.rootHash),
      PortalService
    );
  });

  singleSPA.start();
}


export function hashPrefix(prefix: string) {
  return (location: Location) => {
    return location.hash.startsWith(`#${prefix}`);
  };
}


// export const getAppNames = singleSPA.getAppNames;


// export const destroyAllApps = () => {
//   const apps = getAppNames();
//   return Promise.all(apps.map((app: string) => {
//     return singleSPA.unloadApplication(app, { waitForUnmount: true });
//   }));
// };
