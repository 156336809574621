import * as React from "react";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import { observer } from "mobx-react";
import MainLayout from "@business/layouts/main/index";
import LoginLayout from "@business/layouts/login/index";
import SsoBind from "@business/layouts/bind";
import RedirectPage from "@business/layouts/redirect";

import SystemStore from "@common/stores/system";
import LanguageStore from "@business/stores/language";
import { ThemeServiceModal } from '@common/components/theme-service-modal';
import { VerificationModal } from '@common/components/micro-service/factor-verification';

// const config = (window as any).CCMS_INFOS_CONFIG;
@observer
export default class RootRouter extends React.Component {
  public componentDidMount() {
    SystemStore.fetchSystemConfig().then(() => {
      // if (config.SAAS === "false") {
        const language = SystemStore.config.language;
        LanguageStore.fetchLanguagePackage(language);
      // }
    });
  }

  public render() {
    // Note:
    // 以下 Route 使用 render 替代 component 的写法是 react-router 自身的问题
    // 会报类型检查错误（但不影响正常渲染）
    return (
      <>
        <VerificationModal />
        <ThemeServiceModal />
        <HashRouter>
          <Switch>
            <Route path="/login" component={LoginLayout} exact={true} />
            <Route path="/sso/bind" component={SsoBind} exact={true} />
            <Route path="/redirect" component={RedirectPage} exact={true} />
            <Route path="/" component={MainLayout} />
            <Redirect from="**" to="/" />
          </Switch>
        </HashRouter>
      </>
    );
  }
}
