export const Timeout = {
  title: 'LoginModal.Timeout.title',
  okText: 'LoginModal.Timeout.okText',
  cancelText: 'LoginModal.Timeout.cancelText'
};

export const Kickout = {
  title: 'LoginModal.Kickout.title',
  okText: 'LoginModal.Kickout.okText',
  cancelText: 'LoginModal.Kickout.cancelText'
};

export const Login = {
  title: 'LoginModal.LoginForm.title',
  okText: 'LoginModal.LoginForm.okText',
  cancelText: 'LoginModal.LoginForm.cancelText'
};
