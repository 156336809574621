import { LanguageStore } from '@common/stores/language';
import systemStore from '@common/stores/system';

/**
 * 获取当前用户的语言
 */
function getCurrentLanguage() {
  return LanguageStore.instance.language;
}

function getAllLanguages() {
  return systemStore.getSystemAllLanguages();
}

/**
 * 获取当前语言中的通用语言包内容
 */
function getCurrentLanguageCommonPackage() {
  return LanguageStore.instance.stores.commonPackageStore.getPackage();
}

/**
 * 获取当前语言中的菜单语言包内容
 */
function getCurrentLanguageMenuPackage() {
  return LanguageStore.instance.stores.menuPackageStore.getPackage();
}

/**
 * 获取当前语言中的通用的 ant-design 组件的本地化语言配置
 */
function getCurrentLanguageCommonLocaleForAntd() {
  return LanguageStore.instance.stores.commonPackageStore.getLocaleForAntd();
}

export const LanguageService: KyPortalService.ILanguageService = {
  getLanguage: getCurrentLanguage,
  getSystemAllLanguages: getAllLanguages,
  getCommonPackage: getCurrentLanguageCommonPackage,
  getCommonLocaleForAntd: getCurrentLanguageCommonLocaleForAntd,
  translate(key, params) {
    return LanguageStore.instance.stores.commonPackageStore.translate(key, params);
  },
  Menu: {
    getPackage: getCurrentLanguageMenuPackage,
    translate(key, params) {
      return LanguageStore.instance.stores.menuPackageStore.translate(key, params);
    }
  },
  Common: {
    getPackage: getCurrentLanguageCommonPackage,
    translate(key, params) {
      return LanguageStore.instance.stores.commonPackageStore.translate(key, params);
    }
  },

  Widget: {
    getPackage() {
      return LanguageStore.instance.stores.widgetPackageStore.getPackage();
    },
    translate(key, params) {
      return LanguageStore.instance.stores.widgetPackageStore.translate(key, params);
    }
  }
};
