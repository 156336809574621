import * as React from 'react';
import { Modal } from '@shuyun-ep-team/kylin-ui';
import { IModalFuncProps, IModalProps } from '@shuyun-ep-team/kylin-ui/lib/modal';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { LanguageStore } from '@common/stores/language';


export function confirm(opts: IModalFuncProps) {
  const { translate } = LanguageStore.instance.stores.commonPackageStore;

  const { className, ...rest } = opts;

  return Modal.confirm({
    okText: translate('Components.ModalConfirm.okText'),
    cancelText: translate('Components.ModalConfirm.cancelText'),
    className: classnames(className),
    ...rest
  });
}

export function info(opts: IModalFuncProps) {
  const { translate } = LanguageStore.instance.stores.commonPackageStore;

  const { className, ...rest } = opts;

  return Modal.info({
    okText: translate('Components.ModalConfirm.okText'),
    okCancel: false,
    className: classnames(className),
    ...rest
  });
}


export function error(opts: IModalFuncProps) {
  const { translate } = LanguageStore.instance.stores.commonPackageStore;

  const { className, ...rest } = opts;

  return Modal.error({
    okText: translate('Components.ModalConfirm.okText'),
    okCancel: false,
    className: classnames(className),
    ...rest
  });
}



export function warning(opts: IModalFuncProps) {
  const { translate } = LanguageStore.instance.stores.commonPackageStore;

  const { className, ...rest } = opts;

  return Modal.warning({
    okText: translate('Components.ModalConfirm.okText'),
    okCancel: false,
    className: classnames(className),
    ...rest
  });
}

@observer
export default class CustomModal extends React.Component<IModalProps, any> {
  public static confirm = confirm;
  public static info = info;
  public static error = error;
  public static warning = warning;
  public render() {
    const { wrapClassName, ...rest } = this.props;
    // const { translate } = LanguageStore.commonPackageStore;
    return (
      <Modal
        // okText={translate('Components.Modal.okText')}
        // cancelText={translate('Components.Modal.cancelText')}
        wrapClassName={classnames(wrapClassName)}
        {...rest}
      />
    );
  }
}
