import AccountStore from '@common/stores/user';


export const ssoLocalStorageName = "ccmsSSOSessionId";


/**
 * 菜单是否展开的配置以当前登录用户隔离
 */
export function getMenuSessionStorageName() {
  return `CCMS_MEMU_COLLAPSED-${AccountStore.get().userId}`;
}
