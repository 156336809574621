import SPAStore from '@common/services/spa/store';
import { initSpaService } from '@common/services/spa/helper';


export function bootstrapSpaService(source: 'Business' | 'Implement') {
  SPAStore.fetch(source).then((apps: any) => {
    initSpaService(apps);
  });
}


export function getSpaRoot(): Promise<HTMLDivElement> {
  return new Promise((resolve, reject) => {
    runTask(1000);

    function runTask(times: number) {
      if (SPAStore.SpaRoot) {
        return resolve(SPAStore.SpaRoot);
      }
      if (times < 0) {
        return reject();
      }

      setTimeout(() => {
        runTask(times - 1);
      }, 60);
    }
  });
}


export const SpaService: KyPortalService.ISpaService = {
  getRoot: getSpaRoot
};
