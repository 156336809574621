import React from 'react';
import { CommonTranslate } from '@common/components/translate';
import { Switch, Tabs, Button, MaskLoading, Modal, Divider, Popover } from '@shuyun-ep-team/kylin-ui';
import SettingIcon from '@shuyun-ep-team/icons/react/Setting';
import { NoticeContext } from '@common/components/notification-center/context';
import { Styles, createStyles } from '@common/components/jss-hooks-in-classes';
import { observer } from 'mobx-react';
import { NoticeList } from '../notice-list';
import { WechatTips } from '../wechat-tips';
import { ReadStatus, GetMessageListRes, TReadStatus } from '../../type';
import { postAllRead, getMessageList, putUserConfig } from '../../service';
import * as styles from './index.jss';

type ClassName = keyof typeof styles;

const classes = createStyles<ClassName>(styles);

const { TabPane } = Tabs;

interface IState {
  /** 已读状态 */
  readStatus: TReadStatus;
  /** 消息数量 */
  total: GetMessageListRes['total'];
  /** 列表请求偏移量 */
  offset: GetMessageListRes['offset'];
  modalVisible: boolean;
  modalLoading: boolean;
  loading: boolean;
  messages: GetMessageListRes['messages'];
  hasMore: boolean;
  systemNotify: boolean;
  wechatNotify: boolean;
}

/**
 * @description 消息中心抽屉body部分
 */
@observer
export class DrawerBody extends React.Component<{}, IState> {
  static contextType = NoticeContext;

  context!: React.ContextType<typeof NoticeContext>;

  constructor(props: any) {
    super(props);

    this.state = {
      readStatus: ReadStatus.NOT_READ,
      total: 0,
      offset: 0,
      modalVisible: false,
      modalLoading: false,
      loading: false,
      messages: [],
      hasMore: true,
      systemNotify: false,
      wechatNotify: false,
    };
  }

  /* -------------------------------------------------------------------------- */
  /*                                     方法                                     */
  /* -------------------------------------------------------------------------- */

  /* ---------------------------------- modal 设置相关 ---------------------------------- */

  /** 打开设置 */
  handleSetting = () => {
    this.setState({
      modalVisible: true,
    });
  };

  /** 设置-modal ok */
  handleModalOk = () => {
    const { systemNotify, wechatNotify } = this.state;
    const { config, setConfig } = this.context;
    this.setState({
      modalLoading: true,
    });
    const params = { ...config, systemNotify, wechatNotify };
    putUserConfig(params)
      .then(() => {
        setConfig(params);
        this.setState({
          modalVisible: false,
          modalLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          modalLoading: false,
        });
      });
  };

  /** 设置-modal Cancel */
  handleModalCancel = () => {
    this.setState({
      modalVisible: false,
    });
  };

  /** 设置系统消息 */
  onSwitchSystemNotify = (checked: boolean) => {
    this.setState({
      systemNotify: checked,
    });
  };

  /** 设置微信消息 */
  onSwitchWechatNotify = (checked: boolean) => {
    this.setState({
      wechatNotify: checked,
    });
  };

  /* --------------------------------- tab 相关 --------------------------------- */
  /** tab 点击 */
  onTabClick = (key: ReadStatus) => {
    this.setState(
      {
        readStatus: key,
        offset: 0,
        total: 0,
        messages: [],
      },
      () => {
        this.fetchList();
      },
    );
  };

  /** 全部设为已读 */
  handleAllRead = () => {
    if (this.state.messages.length === 0) {
      return;
    }
    postAllRead().then(() => {
      this.setState(
        {
          offset: 0,
          total: 0,
          messages: [],
        },
        () => {
          this.fetchList();
        },
      );
    });
  };

  /* --------------------------------- 数据获取及变更 -------------------------------- */

  /** 请求消息列表 */
  fetchList = () => {
    const { readStatus, offset, messages, total } = this.state;
    this.setState({
      loading: true,
    });
    getMessageList({
      offset,
      size: 40,
      searchCount: readStatus === ReadStatus.NOT_READ,
      readStatus: readStatus === 'ALL' ? undefined : readStatus,
    })
      .then(res => {
        const hasMore = offset && offset === res.offset ? false : true;

        const countTotal = total + (res.total || 0);

        const list = messages.concat(res.messages || []);
        this.setState({
          offset: res.offset,
          loading: false,
          hasMore,
          total: countTotal,
          messages: [...list],
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  };

  /** 触发滚动加载 */
  onInfiniteOnLoad = () => {
    this.fetchList();
  };

  /** 触发已读事件 */
  onRead = () => {
    const { total } = this.state;

    if (total > 0) {
      this.setState({
        total: total - 1,
      });
    }
  };

  componentDidMount() {
    const { config } = this.context;

    this.setState({
      systemNotify: config.systemNotify,
      wechatNotify: config.wechatNotify,
    });
    this.fetchList();
  }

  public render() {
    const { readStatus, total, loading, modalVisible, modalLoading, messages, hasMore } = this.state;

    const { config } = this.context;

    const NotReadTitle = (
      <div>
        <CommonTranslate i18n='Kylin.Common.Portal.NotRead' />
        {readStatus === ReadStatus.NOT_READ && !!total && `（${total}）`}
      </div>
    );

    return (
      <Styles styles={classes}>
        {classes => (
          <>
            {loading && hasMore && <MaskLoading visible={loading} />}

            {config.wechatFocusTip && (
              <WechatTips close={true} text={<CommonTranslate i18n='Kylin.Common.Portal.WechatTips1' />} />
            )}

            <Tabs
              activeKey={readStatus}
              tabBarExtraContent={
                <div>
                  <Button style={{ marginRight: 20 }} type='link' onClick={this.handleAllRead}>
                    <CommonTranslate i18n='Kylin.Common.Portal.AllRead' />
                  </Button>
                  <SettingIcon style={{ cursor: 'pointer', width: 16, height: 16 }} onClick={this.handleSetting} />
                </div>
              }
              onTabClick={this.onTabClick}
            >
              <TabPane tab={NotReadTitle} key={ReadStatus.NOT_READ}>
                {ReadStatus.NOT_READ === readStatus && (
                  <NoticeList
                    emptyText={<CommonTranslate i18n='Kylin.Common.Portal.NotReadNoData' />}
                    messages={messages}
                    hasMore={hasMore}
                    loading={loading}
                    onRead={this.onRead}
                    onInfiniteOnLoad={this.onInfiniteOnLoad}
                  />
                )}
              </TabPane>
              <TabPane tab={<CommonTranslate i18n='Kylin.Common.Portal.AllNotice' />} key='ALL'>
                {readStatus === 'ALL' && (
                  <NoticeList
                    emptyText={<CommonTranslate i18n='Kylin.Common.Portal.AllNoticeNoData' />}
                    messages={messages}
                    hasMore={hasMore}
                    loading={loading}
                    onInfiniteOnLoad={this.onInfiniteOnLoad}
                  />
                )}
              </TabPane>
            </Tabs>
            <Modal
              title={<CommonTranslate i18n='Kylin.Common.Portal.NoticeSetting' />}
              visible={modalVisible}
              width={480}
              className={classes.wrap}
              destroyOnClose={true}
              onOk={this.handleModalOk}
              bodyStyle={{ height: 260 }}
              onCancel={this.handleModalCancel}
            >
              <MaskLoading visible={modalLoading} />
              <div className={classes.settingItem}>
                <div className={classes.itemLeft}>
                  <CommonTranslate i18n='Kylin.Common.Portal.NoticeCard' />
                </div>
                <Switch defaultChecked={config.systemNotify} onChange={this.onSwitchSystemNotify} />
              </div>
              <Divider />
              <Popover
                placement='bottomLeft'
                overlayClassName='notice-center-popover-wechat-tips'
                className={classes.popoverWechatTips}
                visible={true}
                // @ts-ignore
                getPopupContainer={trigger => trigger.parentNode}
                content={
                  <div style={{ width: 440 }}>
                    <WechatTips
                      text={<CommonTranslate i18n='Kylin.Common.Portal.WechatTips2' />}
                      background='#F2F3F6'
                    />
                  </div>
                }
              >
                <div className={classes.settingItem}>
                  <div className={classes.itemLeft}>
                    <CommonTranslate i18n='Kylin.Common.Portal.WechatNotice' />
                  </div>
                  <Switch defaultChecked={config.wechatNotify} onChange={this.onSwitchWechatNotify} />
                </div>
              </Popover>
            </Modal>
          </>
        )}
      </Styles>
    );
  }
}
