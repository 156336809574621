import * as classes from './index.scss';
import * as React from 'react';
import * as i18nMap from './i18n.map';
import Store from './store';
import { observer } from 'mobx-react';
import UserStore from '@common/stores/user';
import SystemStore from '@common/stores/system';
import LanguageStore from '@business/stores/language';
import { Select, Divider } from '@shuyun-ep-team/kylin-ui';
import If from '@common/components/if';
import TextButton from '@common/components/text-button';
import { LanguageEventBus } from '@common/events/language';
import OperationPermissionTree from './operate-permisstion-tree';
import menuStore from '@business/layouts/main/menu/store';


@observer
export default class UserProfile extends React.Component {

  public componentDidMount() {
    // 获取用户敏感信息
    Store.fetchUserSensitivityData();

    // 获取通道信息
    Store.fetchUserChannelPermission(UserStore.profile.userId);

    // 获取用户当前语言
    Store.setCurrentLanguage(SystemStore.config.language);

    // 获取系统配置信息（语言包）
    SystemStore.fetchSystemConfig();


    // BreadcrumbService.reactRender((
    //   <Breadcrumb>
    //     <Breadcrumb.Item>
    //       <a href='#/'>Portal</a>
    //     </Breadcrumb.Item>
    //     <Breadcrumb.Item>
    //       <Translate i18n="Header.UserMenu.account" />
    //     </Breadcrumb.Item>
    //   </Breadcrumb>
    // ));

  }


  private updateUserLanguage = () => {
    const language = Store.currentLanguage;

    if (SystemStore.config.language === language) {
      Store.toggleLanguageMode(false);
      return;
    }

    // 语言的维度为系统，去除之前设计的用户维度。
    Store.toggleLanguageMode(false);
    UserStore.updateUserLanguage(language);
    SystemStore.changeLanguage(language);
    UserStore.fetchUserProfile();
    menuStore.fetchUserAvailableMenus(true);
    // 当前语言被修改
    LanguageEventBus.emit(language);
  }


  private calculateLanguageText(lang: string) {
    if (!SystemStore.config.languages) {
      return '';
    }

    return (SystemStore.config.languages.find(item => item.language === lang) || { describer: '' }).describer;
  }

  /*  private calculateMaskText() {
     const { translate } = LanguageStore;
     const { sensitivityData } = Store;
     // 掩码的方式 0为全掩码，1为掩码，-1为不掩码
     const maskTextMap = {
       '-1': i18nMap.unMaskText,
       '1': i18nMap.partialMaskText,
       '0': i18nMap.fullMaskText
     };
     return sensitivityData.map(item => {
       return `${item.sensitivityLevelName}（${translate(maskTextMap[item.maskWay])}）`;
     }).join('、');
   } */


  public render() {
    const { translate } = LanguageStore;
    const { isSwitchLanguageMode, currentLanguage, isSubmiting } = Store;
    const { profile } = UserStore;
    const { config } = SystemStore;


    return (
      <div className={classes.container}>
        <div>
          <div className={classes.fieldset}>
            <div
              className={classes.label}
              style={{ lineHeight: '32px' }}
            >{translate(i18nMap.languageLabel)}
            </div>

            <div className={classes.content}>
              {isSwitchLanguageMode ?
                <div style={{ lineHeight: '32px' }}>
                  <Select
                    style={{ minWidth: '100px' }}
                    value={currentLanguage}
                    onChange={value => Store.setCurrentLanguage(value as string)}
                  >
                    {config.languages.map(item => {
                      return (
                        <Select.Option
                          key={item.language}
                          value={item.language}
                        >{item.describer}</Select.Option>
                      );
                    })}
                  </Select>
                  <TextButton
                    className={classes.btnLink}
                    disabled={isSubmiting}
                    onClick={this.updateUserLanguage}
                  >{translate(i18nMap.saveButtonText)}</TextButton>
                  <TextButton
                    className={classes.btnCancel}
                    onClick={() => Store.toggleLanguageMode(false)}
                  >{translate(i18nMap.cancelButtonText)}</TextButton>
                </div> :
                <div style={{ lineHeight: '32px' }}>
                  <span>{this.calculateLanguageText(config.language)}</span>
                  <TextButton
                    className={classes.btnLink}
                    onClick={() => Store.toggleLanguageMode(true)}
                  >{translate(i18nMap.updateButtonText)}</TextButton>
                </div>
              }
            </div>

          </div>

          <div className={classes.fieldset}>
            <div className={classes.label} >
              {translate(i18nMap.operatePermissionLabel)}
            </div>
            <div className={classes.content}>
              <OperationPermissionTree />
            </div>
          </div>

          <div className={classes.fieldset}>
            <div className={classes.label}>
              {translate(i18nMap.channelPermissionLabel)}
            </div>
            <div className={classes.content}>
              <If condition={Store.channelPermissions.length === 0}>
                {translate(i18nMap.channelNoPermissionMessage)}
              </If>

              {Store.channelPermissions.map(item => {
                return <div key={item.gatewayId}>{item.gatewayName}</div>;
              })}
            </div>
          </div>

          {/* <div className={classes.fieldset}>
            <div className={classes.label}>
              {translate(i18nMap.sensitivePermissionLabel)}
            </div>
            <div className={classes.content}>
              <If condition={Store.sensitivityData.length === 0}>
                {translate(i18nMap.sensitiveNoPermissionMessage)}
              </If>
              {this.calculateMaskText()}
            </div>
          </div> */}

          <div className={classes.fieldset}>
            <div className={classes.label}>
              {translate(i18nMap.organizationLabel)}
            </div>
            <div className={classes.content}>
              <If condition={!profile.directOrg}>
                {translate(i18nMap.noOrganizationMessage)}
              </If>
              {profile.directOrg}
            </div>
          </div>
        </div>

        <div>
          <div className={classes.dividerBox}>
            <div style={{ minWidth: '80px', fontSize: '12px' }}>
              {translate(i18nMap.lastLoginLabel)}
            </div>
            <Divider />
          </div>

          <div className={classes.fieldset}>
            <div className={classes.label}>
              {translate(i18nMap.lastLoginDateLabel)}
            </div>
            <div className={classes.content}>
              {profile.lastLoginTime}
            </div>
          </div>


          <div className={classes.fieldset}>
            <div className={classes.label}>
              {translate(i18nMap.lastLoginDeviceLabel)}
            </div>
            <div className={classes.content}>
              {profile.lastLoginEquipment}
            </div>
          </div>


          <div className={classes.fieldset}>
            <div className={classes.label}>
              {translate(i18nMap.lastLoginLocationLabel)}
            </div>
            <div className={classes.content} >
              {profile.lastLoginLocation}
            </div>
          </div>

          <div className={classes.fieldset}>
            <div className={classes.label} >
              {translate(i18nMap.lastLoginIPLabel)}
            </div>
            <div className={classes.content} >
              {profile.lastLoginIp}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
