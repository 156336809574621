import * as classes from './index.scss';
import * as React from 'react';
import { LanguageStore } from '@common/stores/language';
import SystemSettingEmptyIcon from 'shuyun-ep-icons/react/SystemSettingEmpty';
// import { Breadcrumb } from '@shuyun-ep-team/kylin-ui';
// import Translate from '@business/components/translate';
// import { BreadcrumbService } from '@business/services/breadcrumb';


export class NonPage extends React.Component {
  public componentDidMount() {
    // BreadcrumbService.reactRender((
    //   <Breadcrumb>
    //     <Breadcrumb.Item>
    //       <a href='#/'>Portal</a>
    //     </Breadcrumb.Item>
    //     <Breadcrumb.Item>
    //       <Translate i18n="ErrorPage.NoPage" />
    //     </Breadcrumb.Item>
    //   </Breadcrumb>
    // ));
  }

  public render() {
    return (
      <div className={classes.container}>
        <div style={{ textAlign: 'center' }}>
          <SystemSettingEmptyIcon style={{ fontSize: '100px', color: '#afafaf' }} />
          <p style={{ fontSize: '20px', marginTop: '16px' }}>
            {LanguageStore.instance.stores.commonPackageStore.translate('ErrorPage.NoPage')}
          </p>
        </div>
      </div>
    );
  }
}
