import * as classes from './index.scss';
import * as React from 'react';
import { observer } from 'mobx-react';
import Store from './store';
import { Form, Button, Input, Row, Col, message as Toast } from '@shuyun-ep-team/kylin-ui';
import { FormComponentProps } from '@shuyun-ep-team/kylin-ui/lib/form';
import * as i18nMap from './i18n.map';
import LanguageStore from '@business/stores/language';
import PasswordRuleStore from '@business/stores/password';
import MaskLoading from '@common/components/loading';
// import { Breadcrumb } from '@shuyun-ep-team/kylin-ui';
// import Translate from '@common/components/translate';
// import { BreadcrumbService } from '@common/services/breadcrumb';



@observer
class UserPassword extends React.Component<FormComponentProps> {

  public componentDidMount() {
    PasswordRuleStore.fetchPasswordRules();
    // BreadcrumbService.reactRender((
    //   <Breadcrumb>
    //     <Breadcrumb.Item>
    //       <a href='#/'>Portal</a>
    //     </Breadcrumb.Item>
    //     <Breadcrumb.Item>
    //       <Translate i18n="Header.UserMenu.password" />
    //     </Breadcrumb.Item>
    //   </Breadcrumb>
    // ));
  }

  private reset = () => {
    const { form } = this.props;
    form.resetFields();
  }


  private resetConfirmPassword = () => {
    const { form } = this.props;
    form.setFieldsValue({ confirmPassword: '' });
  }


  private submit = () => {
    const { form } = this.props;

    form.validateFields(async (errors) => {
      if (errors) {
        return;
      }


      const formData: any = form.getFieldsValue();
      await Store.updateUserPassword({
        oldPassword: formData.currentPassword,
        newPassword: formData.newPassword
      }).then(() => {
        Toast.success(LanguageStore.i18n[i18nMap.updateSuccess]);
      });

      // TokenUtils.remove();
      // BrowserUtils.goToLogin();
    });
  }


  public render() {
    const { form } = this.props;
    const { translate } = LanguageStore;
    const { getFieldDecorator } = form;
    const { isSubmiting } = Store;
    const { isFetching, passwordRegExp } = PasswordRuleStore;


    return (
      <div className={classes.container}>
        <Form className={classes.form} layout="inline">
          <MaskLoading visible={isFetching} />
          <Form.Item
            className={classes.formItem}
            labelCol={{ span: 9 }}
            wrapperCol={{ span: 15 }}
            label={translate(i18nMap.currentLabel)}
          >
            {getFieldDecorator('currentPassword', {
              rules: [{
                required: true,
                message: translate(i18nMap.currentEmptyTips)
              }]
            })(
              <Input
                type="password"
                autoComplete="new-password"
              />
            )}
          </Form.Item>
          <Form.Item
            className={classes.formItem}
            labelCol={{ span: 9 }}
            wrapperCol={{ span: 15 }}
            label={translate(i18nMap.newLabel)}
          >
            {getFieldDecorator('newPassword', {
              rules: [{
                required: true,
                message: translate(i18nMap.newEmptyTips)
              }, {
                pattern: passwordRegExp,
                message: PasswordRuleStore.calculatePasswordRuleMessage
              }]
            })(
              <Input
                type="password"
                autoComplete="new-password"
                onChange={this.resetConfirmPassword}
              />
            )}
          </Form.Item>
          <Form.Item
            className={classes.formItem}
            labelCol={{ span: 9 }}
            wrapperCol={{ span: 15 }}
            label={translate(i18nMap.confirmLabel)}
          >
            {getFieldDecorator('confirmPassword', {
              rules: [
                {
                  required: true,
                  message: translate(i18nMap.confirmEmptyTips)
                },
                {
                  pattern: new RegExp(`^${form.getFieldValue('newPassword')}$`),
                  message: translate(i18nMap.confirmNotEqualTips)
                }
              ]
            })(
              <Input
                type="password"
                autoComplete="new-password"
              />
            )}
          </Form.Item>
          <Row style={{ marginTop: '20px' }}>
            <Col span={9}></Col>
            <Col span={15}>
              <Button
                type="primary"
                htmlType="submit"
                className={classes.btn}
                disabled={isSubmiting}
                loading={isSubmiting}
                onClick={this.submit}
              >{translate(i18nMap.saveButtonText)}</Button>

              <Button
                htmlType="reset"
                onClick={this.reset}
              >{translate(i18nMap.resetButtonText)}</Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}


export default Form.create()(UserPassword as any);
