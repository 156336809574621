import { BaseLanguagePackageStore } from './base';
import { JsonPackageService } from '@common/services/json-package';


/**
 * 获取 widget 的语言包
 */
function fetchWdigetLanguagePackage(language: string) {
  const url = `/web-common-resource/configs/widget/i18n/${language}.json`;

  return JsonPackageService.fetch(url, {
    // 指定语言包加载失败会默认使用中文语言包
    fallbackURL: `/web-common-resource/configs/widget/i18n/zh-CN.json`
  });
}


export class WidgetLanguagePackageStore extends BaseLanguagePackageStore {

  constructor() {
    super(fetchWdigetLanguagePackage, 'enterprise-widget-运用端');
  }
}
