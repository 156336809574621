import { JssStyle } from 'jss';
import { antPrefix } from '@shuyun-ep-team/kylin-ui/es/styles/vars';

export const wrap: JssStyle = {
  [`& .${antPrefix}-popover-inner-content`]: {
    padding: 0,
  },

  [`& .${antPrefix}-popover-arrow`]: {
    borderTopColor: '#F2F3F6 !important',
    borderLeftColor: '#F2F3F6 !important',
  },
};

export const settingItem: JssStyle = {
  display: 'flex',
  height: '22px',
  fontSize: '14px',
  lineHeight: '22px',
};

export const itemLeft: JssStyle = {
  flex: 1,
};
