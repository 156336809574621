import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { NonPage } from '@common/views/errors/non';


export default function generateHTML() {
  const div = document.createElement('div');
  ReactDOM.render(<NonPage />, div);
  return div.innerHTML;
}
