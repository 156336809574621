import React from 'react';
import clsx from 'classnames';
import { Transition } from 'react-transition-group';
import { TransitionProps, TransitionStatus } from 'react-transition-group/Transition';

export interface IFadeProps
  extends Pick<
    TransitionProps,
    'in' | 'onEnter' | 'onEntering' | 'onEntered' | 'onExit' | 'onExiting' | 'onExited'
  > {
  duration?: number;
  className?: string;
  style?: React.CSSProperties;
  children: (state: TransitionStatus) => React.ReactNode;
}

export const Fade: React.FC<IFadeProps> = props => {
  const { duration = 300, className, children, style, ...otherProps } = props;

  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0
  };

  const transitionStyles: any = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 }
  };

  return (
    <Transition timeout={duration} unmountOnExit={true} {...otherProps}>
      {state => (
        <div
          className={clsx(className)}
          style={{
            ...style,
            ...defaultStyle,
            ...transitionStyles[state]
          }}
        >
          {children(state)}
        </div>
      )}
    </Transition>
  );
};
