import React from 'react';
import qs from 'qs';
import classnames from 'classnames';
import SearchIcon from '@shuyun-ep-team/icons/react/Search';
import LanguageStore from '@business/stores/language';
import { RouteComponentProps } from 'react-router-dom';
import withStyles, { WithStylesProps } from 'react-jss';
import { Input } from '@shuyun-ep-team/kylin-ui';
import Highlighter from 'react-highlight-words';
import { observer } from 'mobx-react';
import MenuStore from '../menu/store';
import * as styles from './index.jss';
import { Fade } from '../menu/components/fade';
import { INestMenu } from '@business/typings/global';
import { historyRecordStore } from './search-history-store';
import * as i18nMap from './i18n.map';
import { calculateMenuName } from '@business/utils/menu';

type ClassName = keyof typeof styles;

interface IMatchedMenu {
  name: string;
  url: string;
  id: number;
  code: string;
}

export interface ISearchPageBaseProps
  extends RouteComponentProps<any>,
  WithStylesProps<typeof styles> { }

export interface ISearchPageBaseState {
  inputValue: string;
  matchedMenus: IMatchedMenu[];
}

@observer
class SearchPageBase extends React.Component<ISearchPageBaseProps, ISearchPageBaseState> {
  public state: ISearchPageBaseState = {
    inputValue: '',
    matchedMenus: []
  };

  public componentDidMount() {
    this.setState({ inputValue: getKeyword(this.props.location.search) });

    MenuStore.fetchUserAvailableMenus().then(() => {
      this.handleSearch();
    });
  }

  public componentDidUpdate(prevProps: ISearchPageBaseProps) {
    // TODO: trim
    const prevKeyword = getKeyword(prevProps.location.search);

    if (this.keyword !== prevKeyword) {
      this.setState({ inputValue: this.keyword });

      this.handleSearch();
    }
  }

  private handleSearch = () => {
    const { menus } = MenuStore;
    const { keyword } = this;

    // 存储到localStorage
    historyRecordStore.push(keyword);

    const matchedMenus = matchKeyword(menus, keyword);

    this.setState({ matchedMenus });
  };

  private get keyword() {
    return getKeyword(this.props.location.search);
  }

  private get resultsNumber() {
    return this.state.matchedMenus.length;
  }

  public render() {
    const { clsx } = this;
    const { inputValue, matchedMenus } = this.state;
    const { replace } = this.props.history;
    const { translate } = LanguageStore;

    return (
      <div className={clsx('root')}>
        <header className={clsx('title')}>{translate(i18nMap.Search.title)}</header>

        <main>
          <Input
            value={inputValue}
            className={clsx('searchInput')}
            prefix={<SearchIcon />}
            onChange={e => {
              this.setState({ inputValue: e.target.value });
            }}
            onPressEnter={() => {
              replace(`/search?keyword=${this.state.inputValue}`);
            }}
          />
          <div className={clsx('helper')}>
            {translate(i18nMap.Search.prefix)}
            {this.resultsNumber}
            {translate(i18nMap.Search.suffix)}
          </div>

          <Fade in={matchedMenus.length > 0}>
            {state => {
              if (state !== 'entered') {
                return null;
              }
              return (
                <section className={clsx('resultSection')}>
                  <div className={clsx('label')}>{translate(i18nMap.Search.functionalEntry)}</div>

                  <ul className={clsx('itemList')}>
                    {matchedMenus.length > 0
                      ? matchedMenus.map(item => {
                        const name = calculateMenuName(item);

                        return (
                          <li key={item.id} className={clsx('item')}>
                            <a className={clsx('itemTitle')} href={item.url}>
                              <Highlighter
                                highlightClassName={clsx('hightLight')}
                                searchWords={[this.keyword]}
                                autoEscape={true}
                                textToHighlight={name}
                              />
                            </a>
                          </li>
                        );
                      })
                      : null}
                  </ul>
                </section>
              );
            }}
          </Fade>
        </main>
      </div>
    );
  }

  private clsx = (...args: Array<ClassName | ''>) => {
    const mergedClasses = args.map(name => {
      if (name === '') {
        return '';
      }
      return this.props.classes[name];
    });

    return classnames(...mergedClasses);
  };
}

function getKeyword(search: string) {
  return (qs.parse(search.slice(1)).keyword as string || '').trim();
}

function matchKeyword(menus: INestMenu[], keyword: string) {
  const regExp = new RegExp(keyword, 'gi');

  return menus.reduce<IMatchedMenu[]>((p, c) => {
    const name = calculateMenuName(c);
    const matched = keyword === '' ? false : name.match(regExp) !== null;
    const hasValidUrl = typeof c.url === 'string' && c.url.length > 0;

    let currentMatched: IMatchedMenu[] = [];
    let childrenMatched: IMatchedMenu[] = [];

    if (matched && hasValidUrl) {
      currentMatched = [
        {
          name: c.name,
          url: c.url,
          id: c.id,
          code: c.code
        }
      ];
    } else {
      currentMatched = [];
    }

    if (c.children && c.children.length > 0) {
      childrenMatched = matchKeyword(c.children, keyword);
    }

    return [...p, ...currentMatched, ...childrenMatched];
  }, []);
}

export const SearchPage = withStyles(styles)(SearchPageBase);
