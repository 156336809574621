import * as React from 'react';
import * as classes from './index.scss';
import * as privacyImage from '../../assets/images/privacy.png';

const PolicyPrivacyArticle = {
  title: '隐私政策 1.0',
  desc:
    '杭州数云信息技术有限公司（以下简称“数云”）尊重并保护所有使用数云CRM系统（以下简称“数云CRM”）的企业（以下简称“您”）的客户个人信息隐私权。为了给您提供消费者运营管理服务，数云会按照本隐私权政策的规定使用您提供的属于您的客户个人信息。但数云将以高度的勤勉、审慎义务对待这些信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，数云不会将这些信息对外披露或向第三方提供。数云会不时更新本隐私权政策。您在同意数云CRM服务使用协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于数云CRM服务使用协议不可分割的一部分。',
  update: '2020年10月28日',
  rules: [
    {
      title: '适用范围',
      sections: [
        'a) 在您开通数云CRM帐号时，您根据数云要求提供的个人信息（个人信息包含您的姓名，职务，联系电话，企业邮箱）；',
        'b) 在完成合同或授权协议的情况下，数云CRM账户进行开通，合同或授权文件中需要包含您提供公司名称等必备工商信息、并加盖有效公章以供我们核对并留存；如果您无法提供，我们可能无法为您开通账户或向您提供部分、全部服务；',
        'c) 在您使用数云CRM服务，或访问数云系统时，数云出于安全和审计考虑，会自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您业务相关的网页记录等数据，用于支撑您的业务和安全；',
        'd) 数云通过合法途径并经过您的授权后，从您或者您的商业合作伙伴处（包括但不仅限于淘宝、京东等平台）同步您的客户个人数据。'
      ],
      rules: [
        {
          title: '您了解并同意，以下信息不适用本隐私权政策： ',
          sections: ['a) 违反法律规定或违反数云规则行为及数云已对您采取的措施。']
        }
      ]
    },
    {
      title: '信息使用',
      sections: [
        'a) 数云不会向任何无关第三方提供、出售、出租、分享或交易您的客户个人信息，除非事先得到您的许可，或该第三方和数云单独或共同为您提供服务，且在该服务结束后，其将被禁止访问所有这些个人信息。',
        'b) 数云亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的客户个人信息。任何数云CRM平台用户如从事上述活动，一经发现，数云有权立即终止与该用户的服务协议。',
        'c) 为了更好为您服务，在您授权的情况下，数云会通过使用您的客户个人信息，向您提供业务需要的信息。或者在您授权的情况下，与数云合作伙伴共享信息以便他们更好为您提供服务。'
      ]
    },
    {
      title: '信息共享',
      sections: [
        '在如下情况下，数云依据您的意愿或法律的规定全部或部分的共享您客户的个人信息：',
        'a) 经您事先同意，与第三方共享；',
        'b) 为提供您所要求的产品和服务，而必须和第三方分享您客户的个人信息；',
        'c) 根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；',
        'd) 如您出现违反中国有关法律、法规或者数云服务协议或相关规则的情况，需要向第三方披露；'
      ]
    },
    {
      title: '信息存储和交换',
      sections: [
        '数云同步的有关您客户信息和资料将保存在数云的服务器上，这些信息和资料将存储在中华人民共和国境内，不涉及在国外存储、转移和交换。'
      ]
    },
    {
      title: 'Cookie的使用',
      sections: [
        'a) 在您未拒绝接受cookies的情况下，数云会在您的计算机上设定或取用cookies，以便您能登录或使用依赖于cookies的数云CRM平台服务或功能。数云使用cookies可为您提供更加便捷的登录服务。',
        'b) 您有权选择接受或拒绝接受cookies。您可以通过修改浏览器设置的方式拒绝接受cookies。但如果您选择拒绝接受cookies，则您可能无法登录或使用依赖于cookies的数云CRM服务或功能。',
        'c) 通过数云所设cookies所取得的有关信息，将适用本政策。'
      ]
    },
    {
      title: '信息安全',
      sections: [
        'a) 数云已使用符合业界标准的安全防护措施保护您提供的客户个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您客户的个人信息。例如，在您的浏览器与“服务”之间交换数据时受SSL加密保护；我们同时对CRM系统相关网站提供https安全浏览方式；我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识；',
        {
          text: 'b) 数云已经取得了以下认证：',
          rules: ['国家等级保护三级安全标准。', 'ISO/IEC 27001信息安全管理体系认证。'],
          type: 'li'
        },
        'c) 数云帐号均有安全保护功能，请妥善保管您的用户名及密码信息。数云将通过对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和伪造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在“完善的安全措施”。',
        'd) 数云会依照个人信息的不同等级存储不同期限，存储期限严格按照法律及相关法规规定，最低期限不少于3个月，超出必要期限后，我们将对您客户的个人信息进行删除或匿名化处理，但法律另有规定的除外。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。'
      ]
    },
    {
      title: '响应您的服务请求',
      sections: [
        'a) 为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会要求您验证自己的身份，然后再处理您的请求。我们将在3天内做出答复。如您不满意，还可以通过以下途径投诉:【投诉途径：客服电话400-629-8558】',
        'b) 对于您在服务合同范围内的请求，我们原则上不收取费用，但对超出服务合同范围内的请求，我们将视情收取一定费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。'
      ],
      rules: [
        {
          title: '在以下情形中，我们将无法响应您的请求：',
          sections: [
            '逃避您自身应当履行的法律法规规定的职责和义务时；',
            '危害国家安全、国防安全直接相关的；',
            '危害公共安全、公共卫生、重大公共利益直接相关的；',
            '影响或阻挠犯罪侦查、起诉、审判和执行判决等直接相关的；',
            '危害个人信息主体或其他个人的生命、财产等重大合法权益；',
            '涉及商业秘密犯罪的。'
          ],
          type: 'li'
        }
      ]
    },
    {
      title: '如何联系我们',
      sections: [
        '如果您对本隐私政策有任何疑问、意见或建议，通过以下方式与我们联系：请拨打客服电话400-629-8558。',
        '我们设立了个人信息保护专职部门（或个人信息保护专员），您可以通过以下方式与其联系：请拨打客服电话400-629-8558。',
        '一般情况下，我们将在15天内回复。',
        '如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您或您的客户合法权益，您还可以通过以下外部途径寻求解决方案：向消费者权益保护部门、监管机关举报或者向有管辖权的法院提起诉讼。'
      ]
    }
  ]
};

interface ISection {
  text: string;
  rules: string[];
  type?: 'li';
}

interface IRule {
  title: string;
  sections: Array<string | ISection>;
  type?: 'li';
}

export const PolicyPrivacy: React.FC = () => {
  return (
    <div className={classes.policyPrivacy}>
      <div className={classes.title}>
        <h1>{PolicyPrivacyArticle.title}</h1>
        <div className={classes.update}>{PolicyPrivacyArticle.update}</div>
      </div>
      <div className={classes.wrap}>
        <div className={classes.content}>
          <div style={{ textAlign: 'center' }}>
            <img
              style={{ width: 60, height: 72 }}
              src={privacyImage}
              alt={PolicyPrivacyArticle.desc}
            />
          </div>
          <p className={classes.desc}>{PolicyPrivacyArticle.desc}</p>
          {PolicyPrivacyArticle.rules.map((rule, index) => {
            return (
              <section className={classes.section} key={index}>
                <h2 className={classes.ruleTitle}>{rule.title}</h2>
                <div className={classes.ruleSectionBox}>
                  {(rule.sections as Array<string | ISection>).map((section, index2) => {
                    if (typeof section === 'string') {
                      return <p key={`${section}-${index2}`}>{section}</p>;
                    } else {
                      return (
                        <div className={classes.subRuleBox} key={`${section}-${index2}`}>
                          <p>{section.text}</p>
                          <ul>
                            {section.rules.map((rule2, index3) => {
                              return (
                                <li
                                  className={section.type === 'li' ? classes.listItem : undefined}
                                  key={`${section}-${index3}`}>
                                  {rule2}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      );
                    }
                  })}
                </div>
                {rule.rules ? (
                  <div className={classes.ruleBox}>
                    {(rule.rules as IRule[]).map((rule2, index3) => {
                      return (
                        <div key={`${rule2.title}-${index3}`}>
                          <p>{rule2.title}</p>
                          {rule2.sections.map(section => {
                            if (typeof section === 'string') {
                              return (
                                <p
                                  className={rule2.type === 'li' ? classes.listItem : undefined}
                                  key={`${section}-${index3}`}>
                                  {section}
                                </p>
                              );
                            } else {
                              return (
                                <div className={classes.subRuleBox} key={`${section}-${index3}`}>
                                  <p>{section.text}</p>
                                  <ul>
                                    {section.rules.map((rule3, index2) => {
                                      return (
                                        <li
                                          className={
                                            section.type === 'li' ? classes.listItem : undefined
                                          }
                                          key={`${section}-${index2}`}>
                                          {rule3}
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              );
                            }
                          })}
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </section>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PolicyPrivacy;
