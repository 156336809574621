import { computed } from 'mobx';
import { BaseLanguagePackageStore } from './base';
import { JsonPackageService } from '@common/services/json-package';



/**
 * 获取通用的语言包，主要包含以下配置
 * 1. Modal、Confirm、Table、Pagination、DatePicker 等组件的通用配置
 */
function fetchLanguagePackage(language: string) {
  const url = `/web-common-resource/configs/common/i18n/${language}.json`;
  return JsonPackageService.fetch(url, {
    // 指定语言包加载失败会默认使用中文语言包
    fallbackURL: `/web-common-resource/configs/common/i18n/zh-CN.json`
  });
}


export class CommonLanguagePackageStore extends BaseLanguagePackageStore {
  constructor() {
    super(fetchLanguagePackage, 'enterprise-common-resource-通用');
  }



  @computed
  public get localeForAntd() {
    return this.getLocaleForAntd();
  }

  public getLocaleForAntd() {
    if (!this.i18n) {
      return { locale: this.language };
    }

    return {
      locale: this.language,
      Pagination: {
        items_per_page: this.translate('Components.Pagination.pageSizeText'),
        jump_to: this.translate('Components.Pagination.jumpTo'),
        jump_to_confirm: this.translate('Components.Pagination.jumpToConfirm'),
        page: this.translate('Components.Pagination.page'),

        // Pagination.jsx
        prev_page: this.translate('Components.Pagination.prevPageText'),
        next_page: this.translate('Components.Pagination.nextPageText')
      },
      TimePicker: {
        placeholder: this.translate('Components.TimePicker.placeholder')
      },
      DatePicker: {
        lang: {
          today: this.translate('Components.DatePicker.today'),
          now: this.translate('Components.DatePicker.now'),
          backToToday: this.translate('Components.DatePicker.backToToday'),
          ok: this.translate('Components.DatePicker.ok'),
          timeSelect: this.translate('Components.DatePicker.timeSelect'),
          dateSelect: this.translate('Components.DatePicker.dateSelect'),
          weekSelect: this.translate('Components.DatePicker.weekSelect'),
          clear: this.translate('Components.DatePicker.clear'),
          month: this.translate('Components.DatePicker.month'),
          year: this.translate('Components.DatePicker.year'),
          previousMonth: this.translate('Components.DatePicker.previousMonth'),
          nextMonth: this.translate('Components.DatePicker.nextMonth'),
          monthSelect: this.translate('Components.DatePicker.monthSelect'),
          yearSelect: this.translate('Components.DatePicker.yearSelect'),
          decadeSelect: this.translate('Components.DatePicker.decadeSelect'),
          yearFormat: this.translate('Components.DatePicker.yearFormat'),
          dayFormat: this.translate('Components.DatePicker.dayFormat'),
          dateFormat: this.translate('Components.DatePicker.dateFormat'),
          dateTimeFormat: this.translate('Components.DatePicker.dateTimeFormat'),
          previousYear: this.translate('Components.DatePicker.previousYear'),
          nextYear: this.translate('Components.DatePicker.nextYear'),
          previousDecade: this.translate('Components.DatePicker.previousDecade'),
          nextDecade: this.translate('Components.DatePicker.nextDecade'),
          previousCentury: this.translate('Components.DatePicker.previousCentury'),
          nextCentury: this.translate('Components.DatePicker.nextCentury'),
          placeholder: this.translate('Components.DatePicker.placeholder'),
          rangePlaceholder: [
            this.translate('Components.DatePicker.RangePlaceholder.start'),
            this.translate('Components.DatePicker.RangePlaceholder.end')
          ]
        },
        timePickerLocale: {
          placeholder: this.translate('Components.TimePicker.placeholder')
        }
      },
      Calendar: {
        placeholder: this.translate('Components.DatePicker.placeholder'),
        rangePlaceholder: [
          this.translate('Components.DatePicker.RangePlaceholder.start'),
          this.translate('Components.DatePicker.RangePlaceholder.end')
        ],
        timePickerLocale: {
          placeholder: this.translate('Components.TimePicker.placeholder')
        }
      },
      Table: {
        filterTitle: this.translate('Components.Table.filterTitle'),
        filterConfirm: this.translate('Components.Table.filterConfirm'),
        filterReset: this.translate('Components.Table.filterReset'),
        emptyText: this.translate('Components.Table.emptyText'),
        selectAll: this.translate('Components.Table.selectAll'),
        selectInvert: this.translate('Components.Table.selectInvert'),
        sortTitle: this.translate('Components.Table.sortTitle')
      },
      Modal: {
        okText: this.translate('Components.Modal.okText'),
        cancelText: this.translate('Components.Modal.cancelText'),
        justOkText: this.translate('Components.Modal.justOkText')
      },
      Popconfirm: {
        cancelText: this.translate('Components.Popconfirm.cancelText'),
        okText: this.translate('Components.Popconfirm.okText')
      },
      Transfer: {
        notFoundContent: this.translate('Components.Transfer.notFoundContent'),
        searchPlaceholder: this.translate('Components.Transfer.searchPlaceholder'),
        itemUnit: this.translate('Components.Transfer.itemUnit'),
        itemsUnit: this.translate('Components.Transfer.itemsUnit')
      },
      Select: {
        notFoundContent: this.translate('Components.Select.notFoundContent')
      },
      Upload: {
        uploading: this.translate('Components.Upload.uploading'),
        removeFile: this.translate('Components.Upload.removeFile'),
        uploadError: this.translate('Components.Upload.uploadError'),
        previewFile: this.translate('Components.Upload.previewFile')
      }
    };
  }
}
