import * as classes from './index.scss';
import * as React from 'react';
import { observer } from 'mobx-react';
import UserStore from '@common/stores/user';
import Router from './router';
import { Menu } from '@shuyun-ep-team/kylin-ui';
import { Link } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';
import * as i18nMap from './i18n.map';
import LanguageStore from '@business/stores/language';
import TokenUtils from '@shuyun-ep-team/utils/es/token';

@observer
export default class UserAccount extends React.Component<RouteComponentProps<any>> {
  public componentDidMount() {
    UserStore.fetchUserProfile();
  }

  private calcuteActiveMenu(): string {
    const { pathname } = this.props.location;
    return pathname.replace('/account/', '');
  }

  public render() {
    const { translate } = LanguageStore;
    const { profile } = UserStore;

    // 判断是否为账号密码登录，非账号密码登录没有修改密码页面
    const isLoginFromAccount = TokenUtils.getTokenObj().loginFrom === 'Account';

    return (
      <div className={classes.container}>
        <h2 className={classes.header}>
          {translate(i18nMap.welcome, {
            name: profile.realName || profile.userName
          })}
        </h2>
        <div className={classes.main}>
          <Menu className={classes.menu} mode="inline" selectedKeys={[this.calcuteActiveMenu()]}>
            <Menu.Item key="profile">
              <Link to="/account/profile">{translate(i18nMap.account)}</Link>
            </Menu.Item>

            {isLoginFromAccount ? (
              <Menu.Item key="password">
                <Link to="/account/password">{translate(i18nMap.password)}</Link>
              </Menu.Item>
            ) : null}
          </Menu>
          <div className={classes.body}>
            <Router />
          </div>
        </div>
      </div>
    );
  }
}
