import * as React from 'react';
import { Form, Button, message as Toast } from '@shuyun-ep-team/kylin-ui';
import CustomModal from '@common/components/modal';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { FormComponentProps } from '@shuyun-ep-team/kylin-ui/lib/form';
import { RouteComponentProps } from 'react-router-dom';
import TokenUtils from '@shuyun-ep-team/utils/es/token';
import Store from './store';
import UserStore from '@common/stores/user';
import SystemStore from '@common/stores/system';
import { LanguageStore } from '@common/stores/language';
import PermissionStore from '@common/stores/permission';
import LoginModalStore from '@common/components/login/store';
import Timeout from './timeout';
import Kickout from './kickout';
import LoginForm from './login-form';
import * as i18nMap from './i18n.map';
import { setParams } from '@common/utils/query-string';

interface IProps extends RouteComponentProps<any, any> {
  visible: boolean;
}

@observer
class LoginModal extends React.Component<IProps & FormComponentProps> {

  private jumpToLogin = () => {
    const { history } = this.props;
    localStorage.removeItem('isMessage');
    const params = {
      token_type: '',
      access_token: '',
      session_id: '',
      reset_pwd: false,
      point_pwd: false,
    };
    setParams(params);
    TokenUtils.remove();
    history.push(`/login`);
    window.location.reload();
  }

  private switchToLoginMode = () => {
    this.jumpToLogin();
  }

  private login = () => {
    const { form } = this.props;

    form.validateFields(async (errors: any) => {
      if (errors) {
        return;
      }

      const { username, password } = form.getFieldsValue() as any;

      Store.login({ username, password }).then(tokenObj => {
        tokenObj.loginFrom = 'Account';
        TokenUtils.set(tokenObj);

        const userId = (UserStore.profile || {}).userId;

        // 获取用户的个人信息
        UserStore.fetchUserProfile().then(() => {
          // 如果重新登录的用户与之前的用户不是同一个，则跳转至系统着陆页
          // 重新获取当前用户的默认语言
          if (userId !== UserStore.profile.userId) {
            this.jumpToLandingPage();
            LanguageStore.instance.fetchLanguagePackage(SystemStore.config.language);
          }

          LoginModalStore.close();
        });

        // 重新获取用户的权限
        PermissionStore.fetch(true);

      }).catch(err => {
        Toast.error(err.message);
      });

    });
  }


  public jumpToLandingPage = () => {
    document.cookie = "tenant_domain=;path=/;expires=" + new Date(0).toUTCString();
    localStorage.removeItem('isMessage');
    this.props.history.push('/');
    window.location.reload();
  }

  private calculateModalTexts() {
    const { mode } = Store;
    const { translate } = LanguageStore.instance;
    return {
      title: translate(i18nMap[mode].title),
      okText: translate(i18nMap[mode].okText),
      cancelText: translate(i18nMap[mode].cancelText)
    };
  }

  public render() {
    const { visible } = this.props;
    const { mode, isSubmiting } = Store;

    const { title, okText, cancelText } = this.calculateModalTexts();

    const buttons = (mode === 'Timeout' || mode === 'Kickout') ?
      [
        <React.Fragment key="buttons">
          <Button onClick={() => this.jumpToLogin()}>{cancelText}</Button>
          <Button onClick={() => this.switchToLoginMode()} type="primary">{okText}</Button>
        </React.Fragment>
      ] :
      [
        <Button key="ok" type="primary" onClick={() => this.login()} disabled={isSubmiting}>{okText}</Button>
      ];

    return (
      <CustomModal
        visible={visible}
        keyboard={false}
        closable={false}
        maskClosable={false}
        width={480}
        title={title}
        footer={buttons}
        wrapClassName="portal-modal"
      >
        {
          mode === 'Timeout' ?
            <Timeout /> : (
              mode === 'Kickout' ?
                <Kickout /> :
                <LoginForm form={this.props.form} />
            )
        }
      </CustomModal>
    );
  }
}

export default Form.create()(withRouter(LoginModal as any) as any) as any;
