/**
 * 操作类型
 */
export enum OperationType {
  /** 下载(暂不支持) */
  DOWNLOAD = 'DOWNLOAD',
  /** 跳转(默认值) */
  REDIRECT = 'REDIRECT',
}

/**
 * 读取状态：接口
 */
export enum ReadStatus {
  /** 未读 */
  NOT_READ = 'NOT_READ',
  /** 已读 */
  READ_ALREADY = 'READ_ALREADY',
}

/** 读取状态：视图 */
export type TReadStatus = ReadStatus | 'ALL';

/**
 * 操作选项
 */
export interface Operation {
  /** 消息标题 */
  name: string;
  /** 跳转地址 */
  url: string;
  operationType: OperationType;
}

/** 消息 */
export interface Message {
  id: string;
  /** 消息标题 */
  title: string;
  /** 消息详情 */
  detail: string;
  /** 创建时间 */
  createAt: string;
  readStatus: ReadStatus;
  operate: Operation[];
}

/**
 * 用户配置信息
 */
export interface NoticeConfig {
  /** 是否开启系统卡片提醒 */
  systemNotify: boolean;
  /** 是否开启微信消息推送 */
  wechatNotify: boolean;
  /** 微信二维码关注提示显示 */
  wechatFocusTip: boolean;
}

/**
 * 二维码信息
 */
export interface QRCode {
  /** 二维码url地址 */
  qrcodeUrl: string;
  /** 二维码过期时间 */
  expireSeconds: number;
}

/**
 * 卡片消息获取接口的响应
 */
export interface GetCardMessageRes {
  /** 是否有未读消息 */
  hasNotRead: boolean;
  message: Message[];
}

/**
 * 获取消息列表接口的参数
 */
export interface GetMessageList {
  /** 请求偏移量，就是响应的offset */
  offset: number;
  size: number;
  /** 控制是否响应total */
  searchCount: boolean;
  readStatus?: ReadStatus;
}

/**
 * 获取消息列表的参数
 */
export interface GetMessageListRes {
  /** 消息总数量 */
  total: number;
  /** 下次请求偏移量 */
  offset: number;
  messages: Message[];
}
