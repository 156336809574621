import { LanguageStore } from '@common/stores/language';
import { BaseLanguagePackageStore } from '@common/stores/language/base';
import { JsonPackageService } from '@common/services/json-package';


function fetchLanguagePackage(language: string) {
  const url = `/portal/i18n/${language}.json`;
  return JsonPackageService.fetch(url, {
    // 指定语言包加载失败会默认使用中文语言包
    fallbackURL: `/portal/i18n/zh-CN.json`
  });
}


export class BusinessPortalLanguageStore extends BaseLanguagePackageStore {

  constructor() {
    super(fetchLanguagePackage, 'enterprise-portal-fe-运用端');
  }
}


export default LanguageStore.create({
  portalPackageStore: new BusinessPortalLanguageStore()
});
