
// tslint:disable-next-line
const EventEmitter = require('wolfy87-eventemitter');

const ee = new EventEmitter();

type TListener = (isFold?: boolean) => void;


// 添加菜单 展开/收起 事件的监听
function on(fnc: TListener) {
  ee.addListener('toggle', fnc);
}

// 移除菜单 展开/收起 事件的监听
function off(fnc: TListener) {
  ee.removeListener('toggle', fnc);
}


// 触发菜单 展开/收起 事件
function emit(isFold: boolean) {
  ee.emit('toggle', isFold);
}

// 添加菜单 展开/收起 事件的监听（动画完成）
function onComplete(fnc: TListener) {
  ee.addListener('ToggleComplete', fnc);
}

// 移除菜单 展开/收起 事件的监听（动画完成）
function offComplete(fnc: TListener) {
  ee.removeListener('ToggleComplete', fnc);
}


// 触发菜单 展开/收起 事件（动画完成）
function emitComplete(isFold: boolean) {
  ee.emit('ToggleComplete', isFold);
}

export const MenuEventBus: KyPortalService.IMenuEvent = {
  on, off, emit, onComplete, offComplete, emitComplete
};
