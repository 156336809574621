import axios from 'axios';
import { setRequestInterceptor } from '@common/services/fetch/interceptors/request';
import { setResponseInterceptor } from '@common/services/fetch/interceptors/response';

const config = (window as any).CCMS_INFOS_CONFIG;
// 纯粹的微服务
export const pureFetch = axios.create();
setResponseInterceptor(pureFetch);

// epassportLogin微服务
export const epassprotLoginFetch = axios.create({
  baseURL: config.SAAS === 'true' ? '/epassport/v1' : config.UAL + '/epassport/v1',
});
setRequestInterceptor(epassprotLoginFetch);
setResponseInterceptor(epassprotLoginFetch);

// epassport微服务
export const epassprotFetch = axios.create({
  baseURL: config.UAL + '/epassport/v1',
});
setRequestInterceptor(epassprotFetch);
setResponseInterceptor(epassprotFetch);

/** epassport sso用户绑定微服务 */
export const ssoUserFetch = axios.create({
  // baseURL: "http://172.18.57.252:8080/epassport/v1/sso/config/user"
  baseURL: config.UAL + '/epassport/v1/sso/user',
});
setRequestInterceptor(ssoUserFetch);
setResponseInterceptor(ssoUserFetch);

// 纯粹的微服务
export const ssoFetch = axios.create({
  // baseURL: "http://172.18.57.251:8080/epassport/v1/sso"
  baseURL: config.UAL + '/epassport/v1/sso',
});
setRequestInterceptor(ssoFetch);
setResponseInterceptor(ssoFetch);

// portal微服务
export const portalFetch = axios.create({
  baseURL: config.UAL + '/system-configuration/v1',
  headers: {
    'Content-Type': 'application/json',
  },
});

setRequestInterceptor(portalFetch);
setResponseInterceptor(portalFetch);

// 通知中心微服务
export const notifyFetch = axios.create({
  baseURL: config.UAL + '/notify/v1',
  headers: {
    'Content-Type': 'application/json',
  },
});

setRequestInterceptor(notifyFetch);
setResponseInterceptor(notifyFetch);
