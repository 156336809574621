export const title = 'LoginPage.ResetForm.title';


export const Password = {
  label: 'LoginPage.ResetForm.Password.label',
  placeholder: 'LoginPage.ResetForm.Password.placeholder',
  requiredTips: 'LoginPage.ResetForm.Password.requiredTips'
};


export const ConfirmPassword = {
  label: 'LoginPage.ResetForm.ConfirmPassword.label',
  placeholder: 'LoginPage.ResetForm.ConfirmPassword.placeholder',
  requiredTips: 'LoginPage.ResetForm.ConfirmPassword.requiredTips',
  confirmNotEqualTips: 'LoginPage.ResetForm.ConfirmPassword.confirmNotEqualTips'
};


export const LoginButtonText = 'LoginPage.ResetForm.LoginButton.text';

