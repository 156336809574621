export const UserMenu = {
  account: 'Header.UserMenu.account',
  dashboard: 'Header.UserMenu.dashboard',
  password: 'Header.UserMenu.password',
  logout: 'Header.UserMenu.logout',
  policyPrivacy: 'Header.UserMenu.policyPrivacy'
};

export const IconTooltip = {
  search: 'Header.Icon.Tooltip.search',
  system: 'Header.Icon.Tooltip.system',
  user: 'Header.Icon.Tooltip.user'
};

export const LogoutConfirmModal = {
  title: 'LogoutConfirmModal.title',
  content: 'LogoutConfirmModal.content'
};

export const Search = {
  placeholder: 'Search.Input.placeholder',
  label: 'Search.label'
};
