import { observable, action, runInAction } from 'mobx';
import {
  updateUserLanguage,
  fetchUserSensitivityData,
  fetchUserChannelPermission
} from './service';


interface ISensitivityData {
  id: string;
  maskWay: '-1' | '0' | '1';
  roleId: string;
  sensitivityLevelId: string;
  sensitivityLevelName: string;
}

interface IChannelPermission {
  gatewayId: string | number;
  gatewayName: string;
  status: string;
}


class UserProfileStore {
  // 是否为切换语言模式
  @observable public isSwitchLanguageMode = false;

  @action public toggleLanguageMode(isSwitchMode: boolean) {
    this.isSwitchLanguageMode = isSwitchMode;
  }

  @observable public currentLanguage: string = '';

  @action public setCurrentLanguage(language: string) {
    this.currentLanguage = language;
  }

  @observable public isSubmiting = false;
  @action public updateUserLanguage(language: string) {
    this.isSubmiting = true;
    return updateUserLanguage(language).then(() => {
      runInAction(() => {
        this.isSubmiting = false;
      });
    }).catch(err => {
      runInAction(() => {
        this.isSubmiting = false;
      });
      throw err;
    });
  }


  // 用户敏感信息
  // @observable public isFetching = false;
  // @observable public sensitivityData: ISensitivityData[] = [];
  // @action public fetchUserSensitivityData() {
  //   this.isFetching = true;
  //   return fetchSensitivityDataObjectTypes().then((res: any) => {
  //     const types = res.map((item: any) => item.code);
  //     return fetchUserSensitivityData(types).then((res: any) => {
  //       this.isFetching = false;
  //       this.sensitivityData = res;
  //     });
  //   }).catch(err => {
  //     this.isFetching = false;
  //     throw err;
  //   });
  // }


  @observable public isFetching = false;
  @observable public sensitivityData: ISensitivityData[] = [];
  @action public fetchUserSensitivityData() {
    this.isFetching = true;
    return fetchUserSensitivityData().then((res: any) => {
      runInAction(() => {
        this.isFetching = false;
        this.sensitivityData = res;
      });

    }).catch(err => {
      runInAction(() => {
        this.isFetching = false;
      });
      throw err;
    });
  }


  @observable public isFetchingChannel = false;
  @observable public channelPermissions: IChannelPermission[] = [];
  @action public fetchUserChannelPermission(userId: string) {
    this.isFetchingChannel = true;
    return fetchUserChannelPermission(userId).then((res: any) => {
      runInAction(() => {
        this.isFetchingChannel = false;
        this.channelPermissions = res;
      });
    }).catch(err => {
      runInAction(() => {
        this.isFetchingChannel = false;
      });
      throw err;
    });
  }

}


export default new UserProfileStore();
