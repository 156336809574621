import * as React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { Spin, Popover, Select, Divider } from '@shuyun-ep-team/kylin-ui';
import Cookies from 'js-cookie';
import SystemStore from '@common/stores/system';
import LanguageStore from '@business/stores/language';
import DingtalkWithColorIcon from '@shuyun-ep-team/icons/react/DingtalkWithColor';
import WorkWechatWithColorIcon from '@shuyun-ep-team/icons/react/WorkWechatWithColor';
import CircleArrowDownIcon from 'shuyun-ep-icons/react/CircleArrowDown';
import MaskLoading from '@common/components/loading';
import { LanguageEventBus } from '@common/events/language';
import { RouteComponentProps } from 'react-router-dom';
import { getAllParam, setParams } from '@common/utils/query-string';
import { verificationStore } from '@common/components/micro-service/factor-verification/store';
import LoginForm from './login-form';
import ResetForm from './reset-form';
import WorkWechatForm from './wechat-form';
import DingtalkForm from './dingtalk-form';
import Store, { ISSO } from './store';

import * as classes from './index.scss';

@observer
export default class Login extends React.Component<RouteComponentProps<any>> {
  // eslint-disable-next-line react/no-deprecated
  public componentWillMount() {
    verificationStore.reset();
    // tslint:disable-next-line: no-console
    console.warn(`[Config Error]: CCMS_INFOS_CONFIG.SAAS config is required.`);

    localStorage.setItem('saas', 'false');
    SystemStore.fetchSystemConfig().then(() => {
      Store.fetchSsoConfig();
    });
    // 登录页清楚cookies
    this.clearCookies();
  }

  public componentWillUnmount() {
    Store.destroy();
  }

  private jumpToSAML(item: ISSO) {
    const CCMS_INFOS_CONFIG = (window as any).CCMS_INFOS_CONFIG;
    const { TENANT } = CCMS_INFOS_CONFIG;
    const { origin, pathname } = window.location;
    const callbackURL = encodeURIComponent(`${origin}${pathname}`);
    const redirectURL = encodeURIComponent(`${origin}${pathname}#/redirect`);
    const bindUrl = encodeURIComponent(`${origin}${pathname}#/sso/bind`);
    const loginUrl = encodeURIComponent(`${origin}${pathname}#/login`);
    const params = `tenantId=${TENANT}&configId=${item.id}&callback=${callbackURL}&login=${loginUrl}&bind=${bindUrl}&redirect=${redirectURL}`;

    // tslint:disable-next-line
    const callbackUrl = `${origin}/epassport/v1/sso/saml/login?${params}`;

    window.location.href = callbackUrl;
  }

  private setCurrentLanguage = (currentLanguage: string) => {
    SystemStore.changeLanguage(currentLanguage);
    LanguageEventBus.emit(currentLanguage);
    Store.fetchSsoConfig();
  };

  /** 开启企业微信登陆 */
  private onOpenWorkWechat = () => {
    const { openWorkWechat, getWorkWechatConfig, workWechat } = Store;

    getWorkWechatConfig(workWechat.id).then(() => {
      openWorkWechat(true);
    });
  };

  /** 开启钉钉登陆 */
  private onOpenDingtalk = () => {
    const { openDingtalk, getDingtalkConfig, dingtalk } = Store;

    getDingtalkConfig(dingtalk.id).then(() => {
      openDingtalk(true);
    });
  };

  public jumpToLandingPage = () => {
    const { origin, pathname } = window.location;
    const newParam = getAllParam();

    // 如果自行登录,去掉对应参数
    delete newParam.access_token;
    delete newParam.token_type;
    delete newParam.session_id;

    // 跳转到首页
    setParams(newParam, `${origin}${pathname}`, '#/');
  };

  public clearCookies = () => {
    if (Cookies.get('tenant_domain')) {
      Cookies.remove('tenant_domain');
      window.location.reload();
    }
  };

  public render() {
    const { SAAS, BACKGROUNDPATH } = (window as any).CCMS_INFOS_CONFIG;
    const { i18n, translate } = LanguageStore;
    const {
      isResetMode,
      activeSSO,
      ssos,
      isFetching,
      enableSSO,
      enablePwd,
      enabledWorkWechat,
      visibleWorkWechat,
      wechatConf,
      enabledDingtalk,
      visibleDingtalk,
      dingtalkConf,
    } = Store;
    const { languages, language } = SystemStore.config;

    if (!i18n) {
      return (
        <div className={classes.container}>
          <Spin className={classes.loading} />
        </div>
      );
    }

    const languagesRender = () => {
      return (
        <Select
          size='small'
          className={classes.languageSelect}
          dropdownMatchSelectWidth={false}
          value={language}
          onChange={(value: any) => this.setCurrentLanguage(value)}
        >
          {languages?.map((item: { language: string | number | undefined; describer: React.ReactNode }) => {
            return (
              <Select.Option key={item.language} value={item.language}>
                {item.describer}
              </Select.Option>
            );
          })}
        </Select>
      );
    };

    return (
      <div
        className={classes.container}
        style={BACKGROUNDPATH ? { backgroundImage: 'url(' + BACKGROUNDPATH + ')' } : undefined}
      >
        <MaskLoading visible={isFetching} />
        <div
          className={classNames(classes.formBox, {
            [classes.workWechatMode]: visibleWorkWechat || visibleDingtalk,
          })}
        >
          <div className={classes.loginForm}>
            {/* <div className={classes.logoBox}>
            <Logo src={logo} maxWidth={330} maxHeight={48} />
          </div> */}

            {enablePwd || SAAS === 'true' ? (
              !isResetMode ? (
                <LoginForm jumpToLandingPage={this.jumpToLandingPage} />
              ) : (
                <ResetForm jumpToLandingPage={this.jumpToLandingPage} />
              )
            ) : null}

            {enableSSO && ssos && (ssos.length || enabledWorkWechat || enabledDingtalk) ? (
              <div className={classes.ssoBox}>
                {/* 仅有一个 sso 登陆时 */}

                {ssos.length === 1 ? (
                  <div title={ssos[0].name} className={classes.currentSso} onClick={() => this.jumpToSAML(ssos[0])}>
                    {ssos[0].name}
                  </div>
                ) : null}

                {/* 多个 sso 登陆 */}
                {ssos.length > 1 ? (
                  <Popover
                    trigger='click'
                    placement='bottom'
                    content={
                      <div className={classes.ssoPopover}>
                        {ssos.map((item, index) => {
                          return (
                            <div
                              key={index}
                              onClick={() => this.jumpToSAML(item)}
                              className={classNames(classes.sso, {
                                [classes.active]: activeSSO && activeSSO.id === item.id,
                              })}
                            >
                              {item.name}
                            </div>
                          );
                        })}
                      </div>
                    }
                  >
                    <div className={classes.currentSso}>
                      <span style={{ marginRight: '5px', verticalAlign: 'middle' }}>
                        {activeSSO ? activeSSO.name : translate('LoginPage.SSO.unselectText')}
                      </span>
                      <CircleArrowDownIcon style={{ fontSize: '16px' }} />
                    </div>
                  </Popover>
                ) : null}

                <div className={classes.qrWrap}>
                  {/* 企业微信 */}
                  {enabledWorkWechat === true ? (
                    <div className={classes.workWechatWrap} onClick={this.onOpenWorkWechat}>
                      <WorkWechatWithColorIcon className={classes.workWechatIcon} />
                    </div>
                  ) : null}

                  {/* 钉钉 */}
                  {enabledDingtalk === true ? (
                    <div className={classes.dingtalkWrap} onClick={this.onOpenDingtalk}>
                      <DingtalkWithColorIcon className={classes.dingtalkIcon} />
                    </div>
                  ) : null}
                </div>

                {languages && languages.length > 0 ? (
                  <div>
                    <Divider type='vertical' style={{ height: 20 }} />
                    {languagesRender()}
                  </div>
                ) : null}
              </div>
            ) : languages && languages.length > 0 ? (
              <div className={classes.languageBox}>{languagesRender()}</div>
            ) : null}
          </div>

          {/* 微信登陆表单 */}
          {visibleWorkWechat ? (
            <WorkWechatForm onClose={() => Store.openWorkWechat(false)} config={wechatConf} />
          ) : null}

          {/* 钉钉登陆表单 */}
          {visibleDingtalk ? <DingtalkForm onClose={() => Store.openDingtalk(false)} config={dingtalkConf} /> : null}
        </div>

        <footer className={classes.footer}>Copyright Ⓒ 2020 shuyun Co.,Ltd All rights reserved.</footer>
      </div>
    );
  }
}
