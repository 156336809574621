import qs from 'qs';

// 跳转至登录页
export function goToLogin() {

  window.location.hash = '/login';

}

export function getTransitionEndEvent() {
  const div = document.createElement('div');
  const transEndEventNames: {
    [key: string]: string
  } = {
    WebkitTransition: 'webkitTransitionEnd',
    MozTransition: 'transitionend',
    OTransition: 'oTransitionEnd otransitionend',
    transition: 'transitionend'
  };

  for (const name in transEndEventNames) {
    if (div.style[name as any] !== undefined) {
      return transEndEventNames[name];
    }
  }

  return false;
}

/**
 * 判断页面是否为 Headless 模式
 */
export function checkHeadless() {
  const search = window.location.search;

  if (!search) {
    return false;
  }

  const { headless } = qs.parse(search, {
    ignoreQueryPrefix: true,
    strictNullHandling: true
  });

  return !!headless;
}


/**
 * 判断页面是否为 inspect 模式
 */
export function checkInspect() {
  if (sessionStorage.getItem("enableShuYunTranslateExtend")) {
    return true;
  } else {
    return false;
  }
}



export const BrowserUtils = {
  goToLogin,
  checkHeadless,
  isInspect: checkInspect(),
  getTransitionEndEvent
};

export default BrowserUtils;
