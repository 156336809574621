
const request = window && window.requestAnimationFrame ? (fnc, args) => {
  const timer = window.requestAnimationFrame(() => fnc(...args));
  return () => window.cancelAnimationFrame(timer);
} : (fnc, args) => {
  const timer = setTimeout(() => fnc(...args), 1000 / 60);
  return () => clearTimeout(timer);
};


export function throttleRequestAnimationFrame(fnc) {
  let cancel;
  return (...args) => {
    if (cancel) {
      cancel();
    }
    cancel = request(fnc, args);
  };
}


export function throttle(fnc: (...args: any) => any, timeout: number = 1000 / 60) {
  let timer: NodeJS.Timeout | undefined;
  return (...args: any) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      fnc(...args);
    }, timeout);
  };
}


