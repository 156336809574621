import * as classes from './index.scss';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Tree } from '@shuyun-ep-team/kylin-ui';
import Store, { IPermission } from './store';
import If from '@common/components/if';
import MaskLoading from '@common/components/loading';
import TextButton from '@common/components/text-button';
import ArrowUpIcon from 'shuyun-ep-icons/react/ArrowUp';
import ArrowDownIcon from 'shuyun-ep-icons/react/ArrowDown';
import * as i18nMap from './i18n.map';
import LanguageStore from '@business/stores/language';
import { LanguageEventBus } from '@common/events/language';


@observer
export default class OperationPermissionTree extends React.Component {

  public componentDidMount() {
    LanguageEventBus.on(() => {
      Store.fetchPermission();
    });
    Store.fetchPermission();
  }

  private calculateTreeNodes(nodes: IPermission[]) {
    return nodes.map(node => {
      return (
        <Tree.TreeNode key={node.id + ''} title={node.name}>
          {node.children ? this.calculateTreeNodes(node.children) : null}
        </Tree.TreeNode>
      );
    });
  }

  public render() {
    const { translate } = LanguageStore;
    const { isFetching, permissions, nestPermissions, isExpand } = Store;

    return (
      <div className={classes.container}>

        <MaskLoading visible={isFetching} />

        <If condition={!isFetching && permissions.length === 0}>
          <div>{translate(i18nMap.noPermissionMessage)}</div>
        </If>

        <If condition={permissions.length > 0}>
          <div className={classes.treeBoxContainer}>
            <div className={classes.treeBox}
              style={{ height: isExpand ? '360px' : '102px' }}
            >
              <Tree selectable={false}>
                {this.calculateTreeNodes(nestPermissions)}
              </Tree>
            </div>

            <If condition={permissions.length > 3}>
              <footer className={classes.expand}>
                {!isExpand ?
                  <TextButton
                    className={classes.btnText}
                    onClick={() => Store.setExpand(true)}>
                    <span>{translate(i18nMap.spreadOut)}</span>
                    <ArrowDownIcon />
                  </TextButton> :
                  <TextButton
                    className={classes.btnText}
                    onClick={() => Store.setExpand(false)}>
                    <span>{translate(i18nMap.collapse)}</span>
                    <ArrowUpIcon />
                  </TextButton>
                }
              </footer>
            </If>
          </div>
        </If>
      </div>
    );
  }
}
