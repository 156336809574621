
// tslint:disable-next-line
const EventEmitter = require('wolfy87-eventemitter');

const ee = new EventEmitter();

type TListener = () => void;


// 添加权限变更事件
export function on(fnc: TListener) {
  ee.addListener('permissionChange', fnc);
}

// 移除权限变更事件
export function off(fnc: TListener) {
  ee.removeListener('permissionChange', fnc);
}


// 触发权限变更事件
export function emit() {
  ee.emit('permissionChange');
}

export const PermissionEventBus: KyPortalService.IPermissionEventBus = {
  on, off, emit
};
