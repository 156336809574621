import * as React from 'react';
import { observer } from 'mobx-react';
import { Row, Col, Input, Popover, Drawer, Tabs } from '@shuyun-ep-team/kylin-ui';
import { SketchPicker } from 'react-color';
import { throttle } from '@common/utils/throttle';
import { themeServiceStore } from './store';



@observer
export class ThemeServiceModal extends React.Component {

  private throttleMerge = throttle(() => {
    themeServiceStore.mergeToTheme();
  }, 50);

  private onCancel() {
    themeServiceStore.reset();
    themeServiceStore.updateVisible(false);
  }

  private updateThemeVariable(group: any, path: string, value: string) {
    themeServiceStore.setThemeGroupValue(group, path, value);
    this.throttleMerge();
  }


  public render() {
    return (
      <Drawer
        width={500}
        title='主题配置修改（仅对测试和开发开放）'
        visible={themeServiceStore.visible}
        onClose={this.onCancel}
        mask={false}
      >
        <Tabs
          activeKey={themeServiceStore.currentTab}
          onChange={tab => themeServiceStore.setCurrentTab(tab)}
        >
          {themeServiceStore.groups.map(group => {
            return <Tabs.TabPane key={group} tab={group} />;
          })}
        </Tabs>
        <div style={{ height: 'calc(100vh - 200px)', overflow: 'auto' }}>
          {Object.keys(themeServiceStore.currentTabVariables).map((attribute) => {
            const group = themeServiceStore.currentTabVariables;
            const value = themeServiceStore.currentTabVariables[attribute] as string;

            const isColor = (attribute.endsWith('Color') || attribute.endsWith('Bg'))
              // && !value.includes('rgba')
              && !value.includes('linear-gradient');

            return (
              <Row key={attribute} style={{ marginBottom: 10 }}>
                <Col span={12}>{attribute}</Col>
                <Col span={12}>
                  {isColor ?
                    <Popover trigger='click' content={<SketchPicker
                      color={value}
                      onChange={color => {
                        this.updateThemeVariable(group, attribute, color.hex);
                      }}
                    />}>
                      <Input value={value} readOnly={true}
                        style={{
                          backgroundColor: value
                        }}
                      />
                    </Popover>
                    :
                    <Input
                      value={value}
                      onChange={evt => {
                        this.updateThemeVariable(group, attribute, evt.target.value);
                      }}
                    />
                  }
                </Col>
              </Row>
            );
          })}
        </div>
      </Drawer>
    );
  }
}
