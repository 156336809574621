import React, { FC, useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useLocation } from 'react-use';
import { notification, Drawer } from '@shuyun-ep-team/kylin-ui';
import clsx from 'classnames';
import NoticeIcon from '@shuyun-ep-team/icons/react/Notice';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { observer } from 'mobx-react';
import { createStyles, Styles } from '@common/components/jss-hooks-in-classes';
import { Dot } from './components/dot';
import { MoreNotice } from './components/more-notice';
import { DrawerBody } from './components/drawer-body';
import { OperationBtn } from './components/operation-btn';
import { NoticeStore } from './store';
import { postRecord, getUserConfig, getCardMessage, postRead } from './service';
import { NoticeContext } from './context';
import * as styles from './index.jss';

type ClassName = keyof typeof styles;

const classes = createStyles<ClassName>(styles);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      retry: false,
    },
  },
});

interface IProps {
  children: (props: ChildrenProps) => React.ReactElement;
}

interface ChildrenProps {
  hasNotRead: boolean;
  store: NoticeStore;
  setDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>;
  drawerVisible: boolean;
}

/**
 * @description 消息
 */
const Notification: FC<IProps> = props => {
  const location = useLocation();

  const [store] = useState(() => new NoticeStore());

  const [drawerVisible, setDrawerVisible] = useState(false);

  /** 记录消息卡片的ID,关闭卡片时用到 */
  const noticeIdsRef = useRef<string[]>([]);
  /* -------------------------------------------------------------------------- */
  /*                                     接口                                     */
  /* -------------------------------------------------------------------------- */
  const { data: userConfig } = useQuery(['getUserConfig'], () => getUserConfig());

  const { data: cardMessage } = useQuery(['getCardMessage'], () => getCardMessage(), {
    refetchInterval: 5000,
  });

  /* -------------------------------------------------------------------------- */
  /*                                     方法                                     */
  /* -------------------------------------------------------------------------- */
  /**
   * 消息卡片消失
   * 为什么不用notification.destroy();，因为此方法没有关闭时的动画效果
   */
  const handleClose = () => {
    noticeIdsRef?.current?.forEach(id => {
      notification.close(id);
    });
    noticeIdsRef.current = [];
  };
  /**
   * 卡片消息操作
   */
  const handleRecord = useCallback(() => {
    postRecord();
    handleClose();
  }, []);

  /**
   * 卡片消息消息关闭
   */
  const onClose = useCallback((id: string) => {
    postRecord();
    postRead([id]);
    handleClose();
  }, []);

  /**
   * 点击更多
   */
  const onMoreNoticeClick = useCallback(() => {
    setDrawerVisible(true);
  }, []);

  const cardList = useMemo(() => {
    return cardMessage?.message?.filter(item => item.readStatus === 'NOT_READ') || [];
  }, [cardMessage]);

  /* -------------------------------------------------------------------------- */
  /*                                     副作用                                    */
  /* -------------------------------------------------------------------------- */
  /** 路由发生改变的副作用 */
  useEffect(() => {
    setDrawerVisible(false);
  }, [location.href]);

  /**
   * 消息卡券提醒
   */
  useEffect(() => {
    if (drawerVisible) {
      return;
    }
    handleClose();

    cardList.slice(0, 1).forEach(item => {
      noticeIdsRef.current.push(item.id);
      setTimeout(() => {
        const btn = (
          <div>
            <OperationBtn messageId={item.id} notification={true} operate={item.operate} onClick={handleRecord} />
          </div>
        );
        notification.open({
          key: item.id,
          message: item.title,
          duration: null,
          description: item.detail,
          btn,
          onClose: () => onClose(item.id),
        });
      });
    });
    if (cardList.length >= 2) {
      noticeIdsRef.current.push('notification-more');
      setTimeout(() => {
        notification.open({
          key: 'notification-more',
          message: '',
          description: <MoreNotice onClick={onMoreNoticeClick} />,
          duration: null,
          closeIcon: ' ',
          className: 'notice-center-notification-more',
        });
      });
    }
  }, [cardList, drawerVisible, handleRecord, onClose, onMoreNoticeClick]);

  useEffect(() => {
    if (userConfig) {
      store.setConfig(userConfig);
    }
  }, [store, userConfig]);

  /** 消息中心展开的副作用 */
  useEffect(() => {
    if (drawerVisible) {
      handleClose();
      postRecord();
    }
  }, [drawerVisible]);

  return props.children({ hasNotRead: !!cardMessage?.hasNotRead, store, setDrawerVisible, drawerVisible });
};

/**
 * @description 消息中心
 */
@observer
export class NotificationCenter extends React.Component {
  public render() {
    return (
      <QueryClientProvider client={queryClient}>
        <Notification>
          {({ hasNotRead, store, drawerVisible, setDrawerVisible }) => (
            <Styles styles={classes}>
              {classes => (
                <NoticeContext.Provider value={store}>
                  <div
                    className={clsx(classes.icon, classes.iconWithHoverFrame)}
                    onClick={() => setDrawerVisible(!drawerVisible)}
                  >
                    {hasNotRead && <Dot style={{ position: 'absolute', top: 2, right: 2 }} />}
                    <NoticeIcon />
                  </div>
                  <Drawer
                    title={null}
                    placement='left'
                    width={480}
                    closable={false}
                    destroyOnClose={true}
                    className={classes.drawerWrap}
                    getContainer='.menuNavRight'
                    maskStyle={{ backgroundColor: 'transparent' }}
                    maskClosable={true}
                    onClose={() => setDrawerVisible(false)}
                    visible={drawerVisible}
                    style={{ position: 'absolute', left: drawerVisible ? 64 : 0 }}
                  >
                    <DrawerBody />
                  </Drawer>
                </NoticeContext.Provider>
              )}
            </Styles>
          )}
        </Notification>
      </QueryClientProvider>
    );
  }
}
