import { ICallback } from '../../components/micro-service/factor-verification/store';
import CurrentUserStore from '@common/stores/user';
import {
  verificationStore,
  IVerificationConfig
} from '../../components/micro-service/factor-verification/store';

export const VerificationService: KyPortalService.IVerificationService = {
  validateMobileBinding: (config?: IVerificationConfig) =>
    verificationStore.verificationMobileBinding(config),
  validateCaptchaTransmission: (callback: ICallback, config?: IVerificationConfig) =>
    verificationStore.verificationCaptchaTransmission(callback, config),
  validate: (callback: ICallback, config?: IVerificationConfig) => {
    if (
      window.CCMS_INFOS_CONFIG.ISVERIFYMOBILE === 'true' ||
      !window.CCMS_INFOS_CONFIG.ISVERIFYMOBILE
    ) {
      CurrentUserStore.fetchUserProfile().then(() => {
        const hasMobile = CurrentUserStore.get().hasMobile;
        if (hasMobile) {
          verificationStore.verificationCaptchaTransmission(callback, config);
        } else {
          verificationStore.verificationMobileBinding(config);
        }
      });
    } else {
      callback('', () => Promise.resolve(''));
    }
  }
};
