import { IToken } from "@shuyun-ep-team/utils/es/token";
import { ssoFetch } from "@common/services/fetch";

/**
 * 获取 sso 定向信息
 * @param session session_id
 */
export function getSsoRedirectInfo(session: string): Promise<IRedirectInfo> {
  return ssoFetch.get(`/saml/login/redirect?session=${session}`, {
    isNotNeedAuth: true,
    AutoError: false
  } as any) as any;
}

export interface IRedirectInfo {
  callback: string;
  token: IToken;
}
