import React from 'react';
import NoticeIcon from '@shuyun-ep-team/icons/react/Notice';
import { CommonTranslate } from '@common/components/translate';
import { primaryColor } from '@shuyun-ep-team/kylin-ui/es/styles/vars';
import ArrowRightIcon from '@shuyun-ep-team/icons/react/ArrowRight';

/**
 * @description 更多提示
 */
export class MoreNotice extends React.PureComponent<{ onClick: () => void }> {
  public render() {
    const { onClick } = this.props;

    return (
      <div style={{ cursor: 'pointer' }} onClick={onClick}>
        <NoticeIcon style={{ width: 16, height: 16, marginRight: 8, color: primaryColor }} />
        <CommonTranslate i18n='Kylin.Common.Portal.MoreNotice' />
        <ArrowRightIcon style={{ width: 16, height: 16, marginLeft: 8 }} />
      </div>
    );
  }
}
