import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Page401 } from '@common/views/errors/401';


export default function generateHTML() {
  const div = document.createElement('div');
  ReactDOM.render(<Page401 />, div);
  return div.innerHTML;
}
