import { action, observable, computed, runInAction } from 'mobx';
import { getPasswordRules } from './service';
import LanguageStore from '@business/stores/language';
import * as i18nMap from './i18n.map';


type TPasswordType = 'NUMBER' | 'CHARACTER' | 'SYMBOL' | 'MAJUSCULE';

interface IRule {
  minPwdSize: number;
  maxPwdSize: number;
  pwdComplexity: TPasswordType[];
}


const RuleRegExpMap = {
  NUMBER: '(?=.*[0-9])',
  CHARACTER: '(?=.*[a-z])',
  SYMBOL: '(?=.*(\\W|_)+)',
  MAJUSCULE: '(?=.*[A-Z])'
};


class PasswordRuleStore {

  @observable public isFetching = false;
  @observable public rule: IRule = {
    pwdComplexity: []
  } as any;
  @action public fetchPasswordRules() {
    this.isFetching = true;
    return getPasswordRules().then((res: any) => {
      runInAction(() => {
        this.isFetching = false;
        this.rule = res;
      });
    }).catch(err => {
      runInAction(() => {
        this.isFetching = false;
      });
      throw err;
    });
  }


  @computed public get passwordRegExp() {
    const rule = this.rule.pwdComplexity.reduce((p, n) => {
      return p += RuleRegExpMap[n];
    }, '') + `^.{${this.rule.minPwdSize || 0},${this.rule.maxPwdSize || ''}}$`;

    return new RegExp(rule);
  }


  @computed public get calculatePasswordRuleMessage() {
    const combo = this.rule.pwdComplexity.map(key => {
      return LanguageStore.translate(i18nMap.PasswordRule[key]);
    }).join(', ');

    return LanguageStore.translate(i18nMap.PasswordRule.message, {
      minLength: this.rule.minPwdSize + '',
      maxLength: this.rule.maxPwdSize + '',
      combo
    });
  }

}

export default new PasswordRuleStore();
