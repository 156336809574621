/*
 * @Author: Mark.Zhang
 * @Date: 2020-12-15 14:41:09
 * 前端日志收集sdk 初始化配置
 */

const ignoreUrls = ['http://localhost:8080/'];

const ignoreErrors = [
  '<img src="" width="32px" height="32px">',
  'ResizeObserver loop limit exceeded',
  'ResizeObserver loop completed with undelivered notifications.'
];

export const TRACK_CONFIG = {
  // 由监控平台后台管理系统创建项目时生成
  projectID: 'BSxeKxEoGc9VUHTn7xNn4g==',
  // 同projectID
  token: 'BSxeKxEoGc9VUHTn7xNn4g==',
  reportUrl: 'https://api-track.kylin.shuyun.com/monitor-service/s/r',
  spa: true,
  // 不是hash模式的项目一定要改成false，否则不能统计到pv的类型
  hash: true,
  ignore: {
    urls: ignoreUrls,
    errors: ignoreErrors
  }
};
