import { action, observable, runInAction } from 'mobx';
import { resetPassword } from '../service';


class ResetFormStore {

  @observable public isSubmiting = false;
  @action public reset(formData: { newPassword: string }) {
    this.isSubmiting = true;

    return resetPassword(formData).then(res => {
      runInAction(() => {
        this.isSubmiting = false;
      });
      return res;
    }).catch(err => {
      runInAction(() => {
        this.isSubmiting = false;
      });
      throw err;
    });
  }

}

export default new ResetFormStore();
