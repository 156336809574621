/**
 * 设置storage
 * @param name storage名
 * @param value storage值
 */

export function setCookie(name: string, value: string) {
  localStorage.setItem(name, value);
}

/**
 * 读取storage
 * @param name storage名
 */
export function getCookie(name: string) {
  return localStorage.getItem(name);
}

/**
 * 删除storage
 * @param name storage名
 */
export function delCookie(name: string) {
  localStorage.removeItem(name);
}
