import * as React from 'react';
import SystemJS from 'systemjs';
import CloseIcon from '@shuyun-ep-team/icons/react/Close';
import * as classes from './index.scss';
import { IWechatQrcodeConfig } from './types';
import MaskLoading from '@common/components/loading';

const { useEffect, useCallback } = React;

interface IProps {
  config: IWechatQrcodeConfig;
  onClose: () => void;
}

const WechatForm = (props: IProps) => {
  const { onClose, config } = props;
  const onClosedQrcode = useCallback(() => onClose && onClose(), []);

  useEffect(() => {
    // const  { UAL } = window.CCMS_INFOS_CONFIG;
    const wechatConfig = {
      id: 'wechat-qrcode',
      appid: config.appid,
      agentid: config.agentid,
      redirect_uri: config.redirectUri,
      state: config.state
      // href: `http://localhost:8080/custom.wechat.css`
    };

    if (!(window as any).WwLogin) {
      const { protocol } = window.location;
      SystemJS.import(
        `${protocol}//rescdn.qqmail.com/node/ww/wwopenmng/js/sso/wwLogin-1.0.0.js`
      ).then(() => (window as any).WwLogin(wechatConfig));
    } else {
      (window as any).WwLogin(wechatConfig);
    }
  }, []);

  return (
    <div className={classes.wechatWrap}>
      <div id="wechat-qrcode">
        <MaskLoading visible={true} />
      </div>
      <CloseIcon className={classes.closedIcon} onClick={onClosedQrcode} />
    </div>
  );
};

export default WechatForm;
