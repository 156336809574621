import * as classes from '../index.scss';
import * as React from 'react';
import Store from './store';
import LoginStore from '../store';
import LanguageStore from '@business/stores/language';
import { observer } from 'mobx-react';
import { FormComponentProps } from '@shuyun-ep-team/kylin-ui/lib/form';
import { Button, Input, Form, message as Toast } from '@shuyun-ep-team/kylin-ui';
import TokenUtils from '@shuyun-ep-team/utils/es/token';
import { withRouter } from 'react-router-dom';
import * as i18nMap from './i18n.map';
import { LoginService } from '@common/services/login';
import { observable, runInAction } from 'mobx';
import SystemStore from '@common/stores/system';
import Translate from '@common/components/translate';
import { setCookie } from "@common/utils/cookie";
import * as logo from '../images/logo.png';
const { translate } = LanguageStore;


interface IProps extends FormComponentProps {
  jumpToLandingPage: () => void;
}

const config = (window as any).CCMS_INFOS_CONFIG;
@observer
class LoginForm extends React.Component<IProps> {
  private readonly isSass = config.SAAS === 'true';
  // 'false': 不需要短信验证码， 'true': 需要短信验证码
  private readonly isMessage = config.ISMESSAGE === 'true';
  @observable private sendButtonDisabled = false;
  private sendButtonText = <Translate i18n={i18nMap.Code.buttonText} />;


  private login = () => {
    const { form } = this.props;
    const { usernamedomain = '', password, verification_code } = form.getFieldsValue() as any;
    form.validateFields(['usernamedomain'], (nameErr) => {
      if (nameErr) {
        return;
      }
      const tenantDomain = this.getdomain(usernamedomain);
      if (this.isSass && !(usernamedomain.split('@') && tenantDomain)) {
        Toast.error(translate(i18nMap.UserName.domainRequiredTips));
        return;
      }

      form.validateFields(['password', 'verification_code'], async (errors) => {
        if (errors) {
          return;
        }

        const username = this.isSass ? usernamedomain.split('@')[0] : usernamedomain;

        document.cookie = "tenant_domain=" + tenantDomain + ";path=/;";
        Store.login({ username, password, verification_code, tenant_domain: tenantDomain }).then(tokenObj => {
          localStorage.setItem('isMessage', config.ISMESSAGE);
          tokenObj.loginFrom = 'Account';
          TokenUtils.set(tokenObj);
          LoginService.close();
          setCookie("language", SystemStore.config.language);

          LoginStore.tenantId = (tokenObj as any).tenantId;
          // 要求用户重置密码
          if (tokenObj.reset_pwd) {
            LoginStore.setIsResetMode(true);
          } else {
            this.props.jumpToLandingPage();
          }
        }).catch(err => {
          if (err.status === 502) {
            Toast.error(translate(i18nMap.UserName.mismatch));
          } else {
            Toast.error(err.message);
          }
        });
      });
    });
  }

  // 发送验证码
  private sendCode = () => {
    const { form } = this.props;
    const { usernamedomain = '', password } = form.getFieldsValue() as any;

    form.validateFields(['usernamedomain', 'password'], (errors) => {
      if (errors) {
        return;
      }
      const tenantDomain = this.getdomain(usernamedomain);
      if (this.isSass && !(usernamedomain.split('@') && tenantDomain)) {
        Toast.error(translate(i18nMap.UserName.domainRequiredTips));
        return;
      }
      const username = this.isSass ? usernamedomain.split('@')[0] : usernamedomain;

      document.cookie = "tenant_domain=" + tenantDomain + ";path=/;";
      runInAction(() => {
        this.sendButtonDisabled = true;
      });

      Store.getCode({ username, password, tenant_domain: tenantDomain }).then(() => {
        let second = 60;
        runInAction(() => {
          this.sendButtonText = <Translate i18n={i18nMap.Code.buttonCountdown} data={{ second: String(second) }} />;
          this.forceUpdate();
        });
        const timer = setInterval(() => {
          second--;
          runInAction(() => {
            this.sendButtonText = <Translate i18n={i18nMap.Code.buttonCountdown} data={{ second: String(second) }} />;
            this.forceUpdate();
          });
          if (second === 0) {
            clearInterval(timer);
            runInAction(() => {
              this.sendButtonDisabled = false;
              this.sendButtonText = <Translate i18n={i18nMap.Code.resendButtonText} />;
              this.forceUpdate();
            });
          }
        }, 1000);
      }).catch(err => {
        runInAction(() => {
          this.sendButtonDisabled = false;
          this.sendButtonText = <Translate i18n={i18nMap.Code.resendButtonText} />;
          this.forceUpdate();
        });
        if (err.status === 502) {
          Toast.error(translate(i18nMap.UserName.mismatch));
        } else {
          Toast.error(err.message);
        }
      });
    });
  }

  private getdomain = (usernamedomain: string) => {
    const domainArr = usernamedomain.split('@');
    if (domainArr.length > 1) {
      return domainArr[domainArr.length - 1];
    } else {
      return '';
    }
  }

  public render() {
    const { isSubmiting } = Store;
    const { getFieldDecorator } = this.props.form;

    return (
      <Form>
        <div className={classes.formLogo}>
          <img src={config.LOGOPATH || logo} width="222px" height="64px" />
        </div>
        <Form.Item
          label={translate(i18nMap.UserName.label)}
          className={classes.formItem}
        >
          {getFieldDecorator('usernamedomain', {
            rules: [{
              required: true,
              message: <Translate i18n={i18nMap.UserName.requiredTips} data={{
                domain: this.isSass ? translate(i18nMap.UserName.domain) : ''
              }} />
            }]
          })(
            <Input
              size="large"
              placeholder={translate(i18nMap.UserName.placeholder, {
                domain: this.isSass ? translate(i18nMap.UserName.domain) : ''
              })} />
          )}
        </Form.Item>

        <Form.Item
          label={translate(i18nMap.Password.label)}
          className={classes.formItem}
        >
          {getFieldDecorator('password', {
            rules: [{
              required: true,
              message: <Translate i18n={i18nMap.Password.requiredTips} />
            }]
          })(
            <Input
              size="large"
              type='password'
              width={204}
              placeholder={translate(i18nMap.Password.placeholder)}
            />
          )}
        </Form.Item>
        {this.isMessage && <Form.Item
          label={translate(i18nMap.Password.label)}
          className={classes.formItem}
        >
          {getFieldDecorator('verification_code', {
            rules: [{
              required: true,
              message: <Translate i18n={i18nMap.Code.requiredTips} />
            }]
          })(
            <Input
              size="large"
              className={classes.codeInput}
              placeholder={translate(i18nMap.Code.placeholder)}
            />
          )}
          <Button
            disabled={this.sendButtonDisabled}
            className={classes.codeBtm}
            onClick={this.sendCode}
            size="large">
            {this.sendButtonText}
          </Button>
        </Form.Item>}
        <Button
          size="large"
          type='primary'
          htmlType="submit"
          className={classes.btnLogin}
          onClick={this.login}
          disabled={isSubmiting}
        >
          {translate(i18nMap.LoginButtonText)}
        </Button>
      </Form>
    );
  }
}


export default Form.create()(withRouter(LoginForm as any) as any) as any;
