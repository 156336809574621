import * as React from 'react';
import WelcomeWithColorIcon from '@shuyun-ep-team/icons/react/WelcomeWithColor';
import * as classes from './index.scss';
import LanguageStore from '@business/stores/language';

export const WelcomePage = () => {
  const { translate } = LanguageStore.stores.commonPackageStore;
  return (
    <div className={classes.container}>
      <div className={classes.body}>
        <WelcomeWithColorIcon className={classes.icon} />
        <p className={classes.title}>{translate('Kylin.Common.Welcome')}</p>
        <p>{translate('Kylin.Common.Welcome.tips')}</p>
      </div>
    </div>
  );
};
