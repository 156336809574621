import * as React from 'react';
// import {withRouter} from 'react-router';
import { observer } from 'mobx-react';
import MaskLoading from '@common/components/loading';
import { RouteComponentProps } from 'react-router-dom';
import { INestMenu } from '@business/typings/global';
import Store from '@business/layouts/main/menu/store';
import { getTokenObj } from '@shuyun-ep-team/utils/es/token';
import { LoginService } from '@common/services/login';

type IProps = RouteComponentProps<{ src: string }>;

@observer
export default class IframePage extends React.Component<IProps> {
  private currenturl: string = '';

  public messageEventCallback = (event: MessageEvent) => {
    if (event.data) {
      // token失效，调整登录页
      if (event.data === 'authReject') {
        LoginService.jumpToLogin();
      }
    }
  };

  // @observable
  public state = { isLoading: false, timestamp: Date.now() };

  public getUrl = (menus: INestMenu[]) => {
    const { src } = this.props.match!.params;
    const menu = menus.filter(item => item.code === src)[0];
    if (menu) {
      let connectVar = '?';
      // 已有参数处理
      if (menu.url.indexOf(connectVar) !== -1) {
        connectVar = '&';
      }
      this.currenturl = menu.url + `?token=${getTokenObj().access_token}&__syp__=${this.state.timestamp}`;
    } else {
      menus.forEach(item => {
        if (item.children && item.children.length > 0) {
          this.getUrl(item.children);
        }
      });
    }
  };

  public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
    const { search: currentSearch } = this.props.location;
    if (currentSearch !== nextProps.location.search) {
      this.setState({
        isLoading: false,
        timestamp: Date.now(),
      });
    }
  }

  public componentDidMount() {
    // iframe通讯服务
    window.addEventListener('message', this.messageEventCallback, false);
  }

  public componentWillUnmount() {
    window.removeEventListener('message', this.messageEventCallback, false);
  }

  public render() {
    const { menus } = Store;
    this.getUrl(menus);
    return (
      <div style={{ width: '100%', height: '100%', position: 'relative' }}>
        <MaskLoading visible={this.state.isLoading} />
        <iframe
          src={decodeURIComponent(this.currenturl)}
          width='100%'
          height='100%'
          title='kylinIframe'
          style={{ border: 'none' }}
          onLoad={() => {
            this.setState({ isLoading: false });
          }}
          onError={() => {
            this.setState({ isLoading: false });
          }}
        ></iframe>
      </div>
    );
  }
}
