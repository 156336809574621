import * as classes from './index.scss';

import * as React from 'react';
import classNames from 'classnames';


// interface IProps extends React.Props<any> {
//   className?: string;
// }

export default class TextButton extends React.PureComponent<any> {


  public render() {

    const { className, children, ...rest } = this.props;

    return (
      <button className={classNames(className, classes.textBtn)} {...rest}>{children}</button>
    );
  }

}
