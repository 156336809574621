import queryString from "query-string";

export function setParams(query: any, url = window.location.href, hash = '') {
  const urlData = queryString.parseUrl(url);
  const searchObj = { ...urlData.query, ...query };
  const newQuery = Object.keys(searchObj).length > 0 ?
    '?' + queryString.stringify(searchObj) : '';

  location.href = `${urlData.url}${newQuery}${hash}`;
  // 重新请求资源，涉及SAAS模式
  window.location.reload();
}

export function getParam(name: string) {
  const urlData = queryString.parseUrl(window.location.href);
  return urlData.query[name] || '';
}

export function getAllParam() {
  const { query = {} } = queryString.parseUrl(window.location.href);
  return query ?? {};
}
