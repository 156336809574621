import { observable, action, toJS } from 'mobx';
import StyleAttached from '@common/services/style-attached';
import { JsonPackageService } from '@common/services/json-package';
import { isObject } from '@shuyun-ep-team/utils/es/var-type-detector';

class SystemThemeStore {
  @observable
  public theme = {} as KyPortalService.ITheme;

  public fetch() {
    return JsonPackageService.fetch('/web-common-resource/theme/theme.json').then((theme: any) => {
      this.update({
        ...theme,
        menu: {
          navLeftBg: 'linear-gradient(180deg,rgba(0,65,134,1) 0%,rgba(0,99,172,1) 100%)',
          navRightBg: 'rgba(0, 65, 134, 1)',
          navMenuItemHoverBg: 'rgba(30, 132, 198, 0.3)',
          navMenuItemCheckedBg: 'rgba(0, 49, 101, 1)'
        }
      });
    });
  }

  @action
  public update(theme: any) {
    this.theme = theme;
    StyleAttached.changeThemeOnly(theme);
    // this.createGlobalStyleVariables();
  }

  public get() {
    return toJS(this.theme);
  }

  public getPathValue(path: string, withImportant = false) {
    const paths = path.split('.');

    const value = paths.reduce((obj, p) => {
      if (Object.prototype.hasOwnProperty.call(obj, p)) {
        return (obj as any)[p];
      }
      return '';
    }, this.theme);

    return withImportant ? `${value} !important` : value;
  }

  // /**
  //  * 根据主题配置设置全局的样式变量
  //  * 部分子模块采用 sass 或者 css 编写样式可以采用这种方式
  //  */
  // private createGlobalStyleVariables() {
  //   const variables: { [key: string]: any } = {
  //     '--primary-color': this.getPathValue('root.primaryColor'),
  //     '--link-hover-color': this.getPathValue('root.linkHoverColor'),
  //     '--success-color': this.getPathValue('root.successColor'),
  //     '--error-color': this.getPathValue('root.errorColor'),
  //     '--warning-color': this.getPathValue('root.warningColor'),
  //     '--normal-color': this.getPathValue('root.normalColor'),
  //     '--processing-color': this.getPathValue('root.processingColor'),
  //     '--ku-body-padding-lg': this.getPathValue('root.kuBodyPaddingLg'),
  //     '--ku-body-padding-md': this.getPathValue('root.kuBodyPaddingMd'),
  //     '--ku-body-padding-sm': this.getPathValue('root.kuBodyPaddingSm'),
  //     '--ku-body-padding-xs': this.getPathValue('root.kuBodyPaddingXs'),
  //     '--body-background': this.getPathValue('root.bodyBackground'),
  //     '--heading-1-size': this.getPathValue('root.heading1Size'),
  //     '--heading-2-size': this.getPathValue('root.heading2Size'),
  //     '--heading-3-size': this.getPathValue('root.heading3Size'),
  //     '--heading-4-size': this.getPathValue('root.heading4Size'),
  //     '--heading-color': this.getPathValue('root.headingColor'),
  //     '--typography-title-fontWeight': this.getPathValue('typography.typographyTitleFontWeight'),
  //     '--ku-heading-1-line-height': this.getPathValue('typography.kuHeading1LineHeight'),
  //     '--ku-heading-2-line-height': this.getPathValue('typography.kuHeading2LineHeight'),
  //     '--ku-heading-3-line-height': this.getPathValue('typography.kuHeading3LineHeight'),
  //     '--ku-heading-4-line-height': this.getPathValue('typography.kuHeading4LineHeight'),
  //     '--typography-title-margin-top': this.getPathValue('typography.typographyTitleMarginTop'),
  //     '--typography-title-margin-Bottom': this.getPathValue(
  //       'typography.typographyTitleMarginBottom'
  //     )
  //   };

  //   Object.keys(variables).forEach(key => {
  //     document.documentElement.style.setProperty(key, variables[key]);
  //   });
  // }
  public flat() {
    const theme = toJS(this.theme || {});

    return flatObj(theme, {});
  }
}

function flatObj(obj: Record<string, any>, data: Record<string, any>) {
  Object.keys(obj).reduce((preData, current: string) => {
    if (isObject(obj[current])) {
      flatObj(obj[current], data);
    } else {
      preData[current] = obj[current];
    }

    return preData;
  }, data);

  return data;
}

export const ThemeStore = new SystemThemeStore();
