import { JssStyle } from 'jss';
import { antPrefix } from '@shuyun-ep-team/kylin-ui/es/styles/vars';

export const icon: JssStyle = {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'rgba(255, 255, 255, 0.8)',
  cursor: 'pointer',
  width: 32,
  height: 32,
  fontSize: 24,
  marginBottom: 15,
  '&:hover': {
    color: 'rgba(255, 255, 255, 1)',
  },
};

export const iconWithHoverFrame: JssStyle = {
  '&:hover': {
    borderRadius: '50%',
    background: 'rgba(30, 132, 198, 0.8)',
  },
};

export const drawerWrap: JssStyle = {
  [`&.${antPrefix}-drawer-open`]: {
    width: 'calc(100vw - 64px) !important',
  },
};
