import { epassprotFetch, epassprotLoginFetch, ssoFetch } from '@common/services/fetch';
import { IToken } from '@shuyun-ep-team/utils/es/token';
import SystemStore from '@common/stores/system';
import { getCookie } from '@common/utils/cookie';
import { IWorkWechatProtocol, IDingtalkProtocol } from './types';

export function login(formData: { [key: string]: string }): Promise<IToken> {
  return epassprotLoginFetch.post('/oauth/token', formData, {
    AutoError: false,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept-Language': SystemStore.config.language
    }
  } as any) as any;
}

export function getCode(formData: { [key: string]: string }): Promise<IToken> {
  return epassprotLoginFetch.post('/oauth/verificationCode', formData, {
    AutoError: false,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept-Language': SystemStore.config.language
    }
  } as any) as any;
}

export function resetPassword(formData: { [key: string]: string }): Promise<IToken> {
  return epassprotFetch.put('/account/resetInitialPassword', formData, {
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': SystemStore.config.language
    }
  }) as any;
}

export function fetchSsoConfig(tenantId: string) {
  return epassprotFetch.get(`/admin/tenants/${tenantId}/loginRule`, {
    isNotNeedAuth: true,
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': SystemStore.config.language || getCookie('language')
    }
  } as any);
}

export function fetchSpConfig<T = IDingtalkProtocol | IWorkWechatProtocol>(
  procotol: 'wechat' | 'dingtalk',
  id: number
): Promise<T> {
  return ssoFetch.get(`/${procotol}/${id}`, {
    isNotNeedAuth: true
  } as any) as any;
}
