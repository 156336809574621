import { set as setToken } from '@shuyun-ep-team/utils/es/token';
import { RequestService } from '@common/services/request';
import * as qs from 'qs';
import { getAllParam } from '@common/utils/query-string';

export function setRequestInterceptor(fetch: any) {
  fetch.interceptors.request.use((config: any) => {
    // 默认由拦截器自动处理错误
    // eslint-disable-next-line no-prototype-builtins
    if (!config.hasOwnProperty('AutoError')) {
      config.AutoError = true;
    }

    const isNotNeedAuth = config.isNotNeedAuth;

    delete config.isNotNeedAuth;

    const DefaultHeaders = RequestService.generateRequestHeaders();

    if (!config.headers.Authorization && !isNotNeedAuth && DefaultHeaders.Authorization) {
      const { access_token = '', token_type = 'Bearer', session_id = '' }: any = getAllParam();

      if (token_type && access_token) {
        setToken({
          token_type,
          access_token,
          session_id,
          reset_pwd: false,
          point_pwd: false,
        });
        config.headers.Authorization = `${token_type} ${access_token}`;
        location.search = '';
      } else {
        config.headers.Authorization = DefaultHeaders.Authorization;
      }
    }

    if (!config.headers['Accept-Language'] && DefaultHeaders['Accept-Language']) {
      config.headers['Accept-Language'] = DefaultHeaders['Accept-Language'];
    }

    const isFormData = config.data instanceof FormData;

    if (!config.headers['Content-Type']) {
      config.headers['Content-Type'] = 'application/json';
    }

    // 格式化参数
    if (config.method !== 'get' && !isFormData) {
      if (config.headers['Content-Type'] !== 'application/json') {
        config.data = qs.stringify(config.data);
      }
    }

    return config;
  });
}
