import * as React from "react";
import classnames from "classnames";
import MenuViewportIcon from "@shuyun-ep-team/icons/react/MenuViewport";
import MenuMarketingIcon from "@shuyun-ep-team/icons/react/MenuMarketing";
import MenuCustomerDataIcon from "@shuyun-ep-team/icons/react/MenuCustomerData";
import MenuMarkIcon from "@shuyun-ep-team/icons/react/MenuMark";
import MenuLoyaltyIcon from "@shuyun-ep-team/icons/react/MenuLoyalty";
import MenuInterestIcon from "@shuyun-ep-team/icons/react/MenuInterest";
import MenuGraphIcon from "@shuyun-ep-team/icons/react/MenuGraph";
import MenuFixedIcon from "@shuyun-ep-team/icons/react/MenuFixed";
import MenuFavoriteIcon from "@shuyun-ep-team/icons/react/MenuFavorite";
import MenuDashboardIcon from "@shuyun-ep-team/icons/react/MenuDashboard";
import MenuContentIcon from "@shuyun-ep-team/icons/react/MenuContent";
import MenuAppPlusIcon from "@shuyun-ep-team/icons/react/MenuAppPlus";
import QilinshujuzhunbeiIcon from '@shuyun-ep-team/icons/react/Qilinshujuzhunbei';
import * as classes from "./index.scss";

const config: { [icon: string]: React.ReactNode } = {
  /** 客户数据管理 */
  customerData: <MenuCustomerDataIcon />,
  /** 忠诚度 */
  loyalty: <MenuLoyaltyIcon />,
  /** 卡券管理 */
  interest: <MenuInterestIcon />,
  /** 内容管理 */
  content: <MenuContentIcon />,
  /** 主动营销管理 */
  marketing: <MenuMarketingIcon />,
  /** 应用 +  */
  appPlus: <MenuAppPlusIcon />,

  fixed: <MenuFixedIcon />,
  graph: <MenuGraphIcon />,
  favorite: <MenuFavoriteIcon />,
  /** 数据准备 */
  dataPrepare: <QilinshujuzhunbeiIcon />,
  /** dashboard 类型 */
  dashboard: <MenuDashboardIcon />,
  /** 视图类 */
  viewport: <MenuViewportIcon />,
  mark: <MenuMarkIcon />
};

interface IProps {
  icon: string;
  className?: string;
}

export default ({ icon, className }: IProps) => {
  return (
    <i className={classnames("menu-icon", classes.icon, className)}>
      {config[icon] || config.favorite}
    </i>
  );
};
