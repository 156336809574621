import { epassprotFetch, portalFetch } from '@common/services/fetch';

export function updateUserLanguage(language: string) {
  return portalFetch.put('/account/resetLanguage', { language }, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

// export function fetchSensitivityDataObjectTypes() {
//   return epassprotFetch.get('/admin/objectTypes');
// }

// // 获取用户敏感数据权限
// export function fetchUserSensitivityData(objectTypes: string[]) {
//   const types = objectTypes.map(item => `objectType=${item}`).join('&');
//   return epassprotFetch.get(`/account/dataPermissions/multiple?${types}`);
// }

// 获取用户敏感数据权限
export function fetchUserSensitivityData() {
  return portalFetch.get('/account/sensitivitydata');
}


// 获取用户的通道权限
export function fetchUserChannelPermission(userId: string) {
  return epassprotFetch.get(`/gateway/user/permissions?userId=${userId}`);
}
