import { ICSSProperties } from '@shuyun-ep-team/utils/es/jss/typings/style';

/******************** search bar ********************/

export const searchBar: ICSSProperties = {
  width: '100%',
  height: '100%',
  fontSize: 25,
  color: 'rgba(255,255,255, 0.8)'
};

export const searchBarHeader: ICSSProperties = {
  display: 'flex',
  alignItems: 'center',
  marginTop: 26,
  marginBottom: 24
};

export const searchBarCancelIcon: ICSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'rgba(256, 256, 256, 0.2)',
  width: 16,
  height: 16,
  fontSize: 16,
  marginLeft: 24,
  marginRight: 16,
  cursor: 'pointer'
};

export const searchBarInput: ICSSProperties = {
  width: 180,
  height: 28,
  color: 'rgba(256, 256, 256, 0.6)',
  background: 'transparent',
  border: '1px solid rgba(256, 256, 256, 0.4)',
  caretColor: '#ccd9e7',

  '&:focus': {
    border: '1px solid #658fb7',
    boxShadow: 'none'
  },

  '&::placeholder': {
    color: 'rgba(256, 256, 256, 0.2)'
  }
};

export const searchBarHistoryPanel: ICSSProperties = {
  marginLeft: 56,
  width: 180
};

export const historyPanelLabel: ICSSProperties = {
  marginBottom: 12,
  fontSize: 12,
  lineHeight: '17px',
  color: 'rgba(153, 153, 153, 1)'
};

export const historyList: ICSSProperties = {
  listStyle: 'none',
  padding: 0
};

export const historyItem: ICSSProperties = {
  color: 'rgba(255, 255, 255, 100)',
  fontSize: 14,
  lineHeight: '20px',
  marginBottom: 8,
  cursor: 'pointer'
};
