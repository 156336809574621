import * as React from 'react';
import { observer } from 'mobx-react';
import MaskLoading from '@common/components/loading';
import SpaStore from '@common/services/spa/store';
import PermissionStore from '@common/stores/permission';


@observer
export default class SPA extends React.Component {

  private rootRef!: React.RefObject<HTMLDivElement>;

  public componentWillMount() {
    this.rootRef = React.createRef();
  }

  public componentDidMount() {
    PermissionStore.fetch().then(() => {
      SpaStore.updateSpaRoot(this.rootRef.current!);
    });
  }

  public componentWillUnmount() {
    SpaStore.updateSpaRoot();
  }

  public render() {
    return (
      <div
        ref={this.rootRef}
        style={{ width: '100%', height: '100%', position: 'relative' }}
      >
        <MaskLoading visible={SpaStore.isLoading} />
      </div>
    );
  }
}
