import { observable, action } from "mobx";
import { MenuEventBus } from "@common/events/menu";
import { getTransitionEndEvent } from "@common/utils/browser";
import { setMenuCollapsed } from "@business/utils/menu";

class MainLayoutStore {
  // 菜单是否收起
  @observable public isFold = false;

  @action public setFold(isFold: boolean) {
    if (isFold !== this.isFold) {
      this.isFold = isFold;

      // 设置当前菜单是否展开，存在 sessionStorage
      setMenuCollapsed(isFold);

      MenuEventBus.emit(isFold);
    }

    // 如果浏览器不支持TransitionEnd事件类型
    // 则通过定时器触发动画完成事件（定时器向后延长50ms）
    if (getTransitionEndEvent() === false) {
      setTimeout(() => {
        this.emitMenuTransitionEnd();
      }, 350);
    }
  }

  // 菜单动画完成
  @action public emitMenuTransitionEnd() {
    MenuEventBus.emitComplete(this.isFold);
  }
}

export default new MainLayoutStore();
