import * as qs from 'qs';
// import { IMenu } from '@business/typings/global';
import { portalFetch } from '@common/services/fetch';

export function fetchUserShortcutMenus() {
  return portalFetch.get('/menu/shortcut/list');
}

export function addUserShortcutMenus(menu: any) {
  return portalFetch.put('/menu/shortcut/batch', menu);
}

export function delUserShortcutMenus(id: number) {
  return portalFetch.delete(`/menu/shortcut/${id}`);
}

export function fetchUserAvailableMenus(params: { isReturnChild: boolean; isPageMenu?: boolean }) {
  return portalFetch.get(`/menu/user/list?${qs.stringify(params)}`);
}

export function getLogoPath() {
  return portalFetch.get('/corporation');
}

// 调用接口，验证 token 是否失效
export function ensureTokenOk() {
  return portalFetch.get('/account/info');
}
