import * as classes from './index.scss';
import * as React from 'react';
import qs from 'qs';
import { observer } from 'mobx-react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
// import LayoutHeader from "./header/index";
// import LayoutNavigation from "./navigation/index";
import classNames from 'classnames';
import MainStore from './store';
import UserStore from '@common/stores/user';
import SystemStore from '@common/stores/system';
import LanguageStore from '@business/stores/language';
import PermissionStore from '@common/stores/permission';
import If from '@common/components/if';
import SPA from './spa';
import LoginModalStore from '@common/components/login/store';
import Router from './router';
import { Spin, message as Toast } from '@shuyun-ep-team/kylin-ui';
import LoginModal from '@common/components/login';
import TokenUtils from '@shuyun-ep-team/utils/es/token';
import { TRACK_CONFIG } from '@common/configs/track-config';
import Track from '@shuyun-ep-team/monitor-track';
import { getTransitionEndEvent, checkHeadless } from '@common/utils/browser';
import { ssoLocalStorageName } from '../constant';
import { Menu } from './menu';
// import Menu from './navigation/index';

const isHeadless = checkHeadless();

const track = new Track();
@observer
class MainLayout extends React.Component<RouteComponentProps> {
  public async componentDidMount() {
    // 获取用户操作权限
    PermissionStore.fetch(true);
    // 获取系统配置信息
    await SystemStore.fetchSystemConfig();

    // 获取当前用户信息
    await UserStore.fetchUserProfile();
    const lang = SystemStore.config.language;
    const CCMS_INFOS_CONFIG = window.CCMS_INFOS_CONFIG || {};
    const enableTrack = CCMS_INFOS_CONFIG.TRACK === 'true' || false;
    const customPayload = { ...UserStore.profile, ...CCMS_INFOS_CONFIG };

    track.init({
      ...TRACK_CONFIG,
      enable: enableTrack,
      customPayload: JSON.stringify(customPayload)
    });

    // 获取语言包
    await LanguageStore.fetchLanguagePackage(lang);
    // 密码即将到期提示
    const tokenObj = TokenUtils.getTokenObj();

    // sso 登陆登陆用户记录session
    if (tokenObj.loginFrom === 'SSO') {
      const params = this.getRouteSearch(window.location.search);
      // tslint:disable-next-line: no-console
      console.log('sso 重定向携带参数：', params);
      // 记录 session_id 至localStorage
      if (params.session) {
        localStorage.setItem(ssoLocalStorageName, params.session);
      } else {
        // tslint:disable-next-line: no-console
        console.log('缺失 sso session 参数！！！');
        this.props.history.replace('/login');
      }
    }

    if (tokenObj.point_pwd && !tokenObj.hasPointed) {
      const { translate } = LanguageStore;
      Toast.error(translate('PasswordExpiringTips'), 5);
      TokenUtils.setKey('hasPointed', true);
    }
  }

  private getRouteSearch = (str = '') => {
    return qs.parse(str.replace(/^\?/, '')) as { session: string };
  };

  public componentWillUnmount() {
    PermissionStore.destroy();
  }

  private addTransitionEndListener(menuDOM: HTMLDivElement) {
    // 判断浏览器是否支持TransitionEnd事件
    // 如果不支持则使用定时器处理
    // @see `@business/layouts/main/store.ts`
    const transitionend = getTransitionEndEvent();
    if (transitionend && menuDOM) {
      menuDOM.addEventListener(
        transitionend,
        (evt: any) => {
          if (evt.propertyName === 'margin-left') {
            MainStore.emitMenuTransitionEnd();
          }
        },
        false
      );
    }
  }

  public render() {
    // const { isFold } = MainStore;
    const {
      i18n,
      isFetching,
      translate,
      stores: { commonPackageStore }
    } = LanguageStore;
    const { profile } = UserStore;

    // 语言包在获取到之前只能显示 loading
    if (!i18n || !commonPackageStore.i18n) {
      return (
        <div className={classes.loading}>
          <Spin />
        </div>
      );
    }

    const isReady = profile && profile.userId;

    return (
      <div className={classes.layout}>
        <If condition={isFetching}>
          <div className={classes.langLoading}>
            <Spin tip={translate('MainLayout.languageLoadingTips')} />
          </div>
        </If>

        <LoginModal visible={LoginModalStore.visible} />

        {isReady ? (
          <>
            {!isHeadless ? (
              <div
                ref={this.addTransitionEndListener}
                className={classNames(classes.menuZone, {
                  // [classes.fold]: isFold
                })}>
                <Menu />
              </div>
            ) : null}

            <main className={classes.mainZone}>
              {/* {!isHeadless ? (
                <header className={classes.header}>
                  <LayoutHeader />
                </header>
              ) : null} */}
              {/* <section className={classes.body}> */}
              <Router />
              <SPA />
              {/* </section> */}
            </main>
          </>
        ) : (
          <div className={classes.loading}>
            <Spin />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(MainLayout);
