export const currentLabel = 'AccountPage.PasswordPage.currentLabel';
export const currentEmptyTips = 'AccountPage.PasswordPage.currentEmptyTips';


export const newLabel = 'AccountPage.PasswordPage.newLabel';
export const newEmptyTips = 'AccountPage.PasswordPage.newEmptyTips';


export const confirmLabel = 'AccountPage.PasswordPage.confirmLabel';
export const confirmEmptyTips = 'AccountPage.PasswordPage.confirmEmptyTips';
export const confirmNotEqualTips = 'AccountPage.PasswordPage.confirmNotEqualTips';


export const saveButtonText = 'AccountPage.PasswordPage.saveButtonText';
export const resetButtonText = 'AccountPage.PasswordPage.resetButtonText';


export const updateSuccess = 'AccountPage.PasswordPage.updateSuccessMessage';
