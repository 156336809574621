import { ICSSProperties } from '@shuyun-ep-team/utils/es/jss/typings/style';
import { ThemeStore } from '@common/stores/theme';
import { antPrefix } from '@shuyun-ep-team/kylin-ui/es/styles/vars';

// menu item的间距
const spacing = {
  'level1&level1': 12,
  'level1&level2': 6,
  'level2&level2': 4,
  'level2&level3': 6,
  'level3&level3': 4,
};

export const root: ICSSProperties = {};

export const nav: ICSSProperties = {
  display: 'flex',
  overflow: 'hidden',
  height: '100%',
};

export const flex: ICSSProperties = {
  flex: 1,
};

/******************** nav left ********************/

export const navLeft: ICSSProperties = () => ({
  // transition: 'all 1s',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  zIndex: 9999,
  // width: menuTheme.kylinNavLeftWidth,
  height: '100%',
  background: ThemeStore.getPathValue('menu.navLeftBg'),
  // background: 'linear-gradient(180deg,rgba(0,65,134,1) 0%,rgba(0,99,172,1) 100%)'
});

export const icon: ICSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'rgba(255, 255, 255, 0.8)',
  cursor: 'pointer',

  '&:hover': {
    color: 'rgba(255, 255, 255, 1)',
  },
};

export const logo: ICSSProperties = {
  width: 32,
  height: 32,
  fontSize: 32,
  marginTop: 20,
  marginBottom: 35,
  cursor: 'default',
};

export const quickIcon: ICSSProperties = {
  width: 32,
  height: 32,
  fontSize: 20,
  marginBottom: 12,
};

export const systemIconPlaceholder: ICSSProperties = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
};

export const systemIcon: ICSSProperties = {
  width: 32,
  height: 32,
  fontSize: 24,
  marginBottom: 15,
};

export const iconWithHoverFrame: ICSSProperties = {
  '&:hover': {
    borderRadius: '50%',
    background: 'rgba(30, 132, 198, 0.8)',
  },
};

export const splitLine: ICSSProperties = {
  width: 42,
  height: 1,
  backgroundColor: 'rgba(204, 223, 238, 0.2)',
};

export const toggleIcon: ICSSProperties = {
  // transition: 'all 0.6s',
  width: '100%',
  fontSize: 16,
  paddingTop: 12,
  paddingBottom: 12,
};

export const toggleIconLeft: ICSSProperties = {};

export const toggleIconRight: ICSSProperties = {
  transform: 'rotate3d(0, 1, 0, 180deg)',
};

export const ctxMenuFrame: ICSSProperties = () => ({
  position: 'fixed',
  zIndex: 1000,
  left: 64,
  background: 'transparent',
  paddingLeft: 1,
});

export const ctxMenu: ICSSProperties = {
  boxShadow: '0px 4px 12px 0px rgba(0,0,0,0.2)',
  background: 'rgba(0,99,171,1)',
  listStyle: 'none',
  borderRadius: '4px',
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 12,
  paddingBottom: 12,
  margin: 0,
};

export const userMenu: ICSSProperties = {
  bottom: 8,
};

export const ctxMenuItem: ICSSProperties = {
  // boxSizing: 'content-box',
  display: 'flex',
  alignItems: 'center',
  minWidth: 156,
  paddingLeft: 24,
  cursor: 'pointer',
  height: 32,
  color: 'rgba(255, 255, 255, 0.8)',
  fontSize: 14,
};

export const ctxMenuItemWithoutCursor: ICSSProperties = {
  ...ctxMenuItem,
  cursor: 'default',
};

export const ctxMenuItemWithHover: ICSSProperties = {
  // boxSizing: 'content-box',
  display: 'flex',
  alignItems: 'center',
  minWidth: 156,
  paddingLeft: 24,
  cursor: 'pointer',
  height: 32,
  color: 'rgba(255, 255, 255, 0.8)',
  fontSize: 14,

  transition: `color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1)`,
  transitionProperty: `color, border-color, background, padding`,
  transitionDuration: `0.3s, 0.3s, 0.3s, 0.15s`,
  transitionTimingFunction: `cubic-bezier(0.645, 0.045, 0.355, 1), cubic-bezier(0.645, 0.045, 0.355, 1), cubic-bezier(0.645, 0.045, 0.355, 1), cubic-bezier(0.645, 0.045, 0.355, 1)`,
  transitionDelay: `0s, 0s, 0s, 0s`,

  '&:hover': {
    background: 'rgba(0, 49, 101, 1)',
    color: 'rgba(255, 255, 255, 1)',
  },
};

export const ctxMenuDivider: ICSSProperties = {
  width: 'auto',
  marginLeft: 20,
  marginRight: 20,
  marginTop: 6,
  marginBottom: 6,
  height: 1,
  background: 'rgba(204, 223, 238, 0.2)',
};
const menuPrefix = `${antPrefix}-menu`;
/******************** nav right ********************/
export const navRight: ICSSProperties = () => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100%',
  background: ThemeStore.getPathValue('menu.navRightBg'),

  // 箭头位置
  [`& .${menuPrefix}-submenu-inline > .${menuPrefix}-submenu-title .${menuPrefix}-submenu-arrow`]: {
    transform: 'rotate(-90deg)',
    right: 12,
    display: 'none',
  },

  [`& .${menuPrefix}-submenu-open > .${menuPrefix}-submenu-title .${menuPrefix}-submenu-arrow`]: {
    transform: 'rotate(180deg)',
  },

  // open时的箭头样式
  // [`& .${menuPrefix}-submenu-open`]: {
  //   '& $arrow': {
  //     transform: 'rotate(90deg)'
  //   }
  // },

  // 菜单背景 & 字体颜色
  [`& .${menuPrefix}-dark`]: {
    background: ThemeStore.getPathValue('menu.navRightBg'),
    color: 'rgba(246, 246, 246, 0.7)',
  },

  // 子菜单背景
  [`& .${menuPrefix}-inline.${menuPrefix}-sub`]: {
    background: ThemeStore.getPathValue('menu.navRightBg'),
    boxShadow: 'none',
  },

  // 菜单item公共样式
  [`& .${menuPrefix}-item, .${menuPrefix}-submenu-title`]: {
    display: 'flex',
    alignItems: 'center',
    width: '100% !important',
    height: 'auto !important',
    paddingLeft: '12px !important',
    paddingTop: '6px !important',
    paddingBottom: '6px !important',
  },

  // 重置[含子菜单的item项]的间距和hover样式
  [`& .${menuPrefix}-submenu-title`]: {
    margin: '0 !important',

    '&:hover': {
      color: 'rgba(255, 255, 255, 1)',
    },
  },

  [`& .${menuPrefix}-submenu .${menuPrefix}-item`]: {
    marginBottom: '0px !important',
  },

  // [`& .${menuPrefix}-sub .${menuPrefix}-item:first-child`]: {
  //   // marginTop: '2px !important'
  // },

  [`& .${menuPrefix}-item:hover`]: {
    color: 'rgba(255, 255, 255, 1)',
    background: ThemeStore.getPathValue('menu.navMenuItemHoverBg'),
  },

  [`& .${menuPrefix}.${menuPrefix}-dark .${menuPrefix}-item-selected`]: {
    color: 'rgba(255, 255, 255, 1)',
    background: ThemeStore.getPathValue('menu.navMenuItemCheckedBg'),
  },
});

// 箭头
export const arrow: ICSSProperties = {
  transition: 'all 0.3s',
};

// open时的箭头样式
export const arrowOpen: ICSSProperties = {
  transition: 'all 0.3s',
  transform: 'rotate(90deg)',
};

export const header: ICSSProperties = {
  display: 'flex',
  alignItems: 'center',
  width: 160,
  height: 34,
  marginTop: 19,
  marginBottom: 34,
};

export const rightLogo: ICSSProperties = {
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  objectPosition: '0 50%',
};

export const menuContainer: ICSSProperties = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  height: 'calc(100vh - 83px)',
  overflowY: 'scroll',
  paddingLeft: 8,
};

export const menu: ICSSProperties = () => ({
  width: 184,
  background: ThemeStore.getPathValue('menu.navRightBg'),
  color: 'rgba(246, 246, 246, 0.7)',
});

export const level3Item: ICSSProperties = {
  marginTop: `${spacing['level3&level3']}px !important`,
};
export const level2Item: ICSSProperties = {
  marginTop: `${spacing['level2&level2']}px !important`,

  '& $level3Item': {
    '&:first-child': {
      marginTop: `${spacing['level2&level3']}px !important`,
    },
  },
};
export const level1Item: ICSSProperties = {
  marginBottom: `${spacing['level1&level1']}px !important`,

  '& $level2Item': {
    '&:first-child': {
      marginTop: `${spacing['level1&level2']}px !important`,
    },
  },
};

export const menuIcon: ICSSProperties = {
  fontSize: 16,
  lineHeight: '16px',
  marginRight: 14,
};

export const itemLink: ICSSProperties = {
  display: 'flex !important',
  color: 'inherit',
};
export const level1ItemLink: ICSSProperties = {};
export const level2ItemLink: ICSSProperties = {};
export const level3ItemLink: ICSSProperties = {};

export const itemContent: ICSSProperties = {
  boxSizing: 'content-box',
  width: 112,
  lineHeight: '20px',
  whiteSpace: 'initial',

  overflow: 'hidden',
  display: '-webkit-box',
  '-webkit-box-orient': 'vertical',
  '-webkit-line-clamp': 3,
};
export const level1ItemContent: ICSSProperties = {};
export const level2ItemContent: ICSSProperties = {
  paddingLeft: '30px !important',
  fontSize: '12px !important',
};
export const level3ItemContent: ICSSProperties = {
  paddingLeft: '42px !important',
  fontSize: '12px !important',
};

/******************** search bar ********************/

export const searchBar: ICSSProperties = {
  width: '100%',
  fontSize: 25,
  color: 'rgba(255,255,255, 0.8)',
};
