import React from 'react';
import SystemJS from 'systemjs';
import CloseIcon from '@shuyun-ep-team/icons/react/Close';
import DingtalkWithColorIcon from '@shuyun-ep-team/icons/react/DingtalkWithColor';
import * as classes from './index.scss';
import { IDingtalkQrcodeConfig } from './types';
import MaskLoading from '@common/components/loading';

const { useEffect, useCallback } = React;

interface IProps {
  config: IDingtalkQrcodeConfig;
  onClose: () => void;
}

const DingtalkForm = (props: IProps) => {
  const { onClose, config } = props;
  const onClosedQrcode = useCallback(() => onClose && onClose(), []);

  useEffect(() => {
    const dingtalkConfig: IDingtalkQrcodeConfig = {
      id: 'dingtalk-qrcode',
      response_type: 'code',
      scope: 'snsapi_login',
      ...config
    };
    const { protocol } = window.location;
    const { id, appid, response_type, scope, state, redirect_uri } = dingtalkConfig;

    const url = `${protocol}//oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${appid}&response_type=${response_type}&scope=${scope}&state=${state}&redirect_uri=${redirect_uri}`;

    const goto = encodeURIComponent(url);
    const conf = { id, goto, style: 'border:none;background-color:#FFFFFF;padding-top: 30px;' };

    if (!(window as any).DDLogin) {
      const dingtalkSDK = `${protocol}//g.alicdn.com/dingding/dinglogin/0.0.5/ddLogin.js`;

      SystemJS.import(dingtalkSDK).then(() => (window as any).DDLogin(conf));
    } else {
      (window as any).DDLogin(conf);
    }

    function handleMessage(event: MessageEvent) {
      const { origin, data } = event;

      if (origin === 'https://login.dingtalk.com') {
        // 获取到loginTmpCode后就可以在这里构造跳转链接进行跳转了
        window.location.href = `${decodeURIComponent(goto)}&loginTmpCode=${data}`;
      }
    }

    if (typeof window.addEventListener !== undefined) {
      window.addEventListener('message', handleMessage, false);
    } else if (typeof (window as any).attachEvent !== undefined) {
      (window as any).attachEvent('onmessage', handleMessage);
    }

    return () => {
      if (typeof window.addEventListener !== undefined) {
        window.removeEventListener('message', handleMessage, false);
      } else if (typeof (window as any).detachEvent !== undefined) {
        (window as any).detachEvent('onmessage', handleMessage);
      }
    };
  }, []);

  return (
    <div className={classes.dingtalkWrap}>
      <div className={classes.header}>
        <DingtalkWithColorIcon className={classes.icon} />
      </div>
      <div id="dingtalk-qrcode">
        <MaskLoading visible={true} />
      </div>

      <CloseIcon className={classes.closedIcon} onClick={onClosedQrcode} />
    </div>
  );
};

export default DingtalkForm;
