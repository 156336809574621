import { observable, action } from 'mobx';
import { JsonPackageService } from '@common/services/json-package';

class SPAStore {

  @observable public isLoading = false;

  @action public update(data: {
    isLoading?: boolean
  }) {
    Object.assign(this, data);
  }

  public SpaRoot?: HTMLDivElement;

  public updateSpaRoot($div?: HTMLDivElement) {
    this.SpaRoot = $div;
  }

  public fetch(source: 'Business' | 'Implement') {
    return source === 'Business' ?
      this.fetchBusinessApps() :
      this.fetchImplementApps();
  }


  private fetchBusinessApps() {
    return JsonPackageService.fetch(`/web-common-resource/configs/portal/apps.json`).then((DefaultApps: any) => {
      if (process.env.isForAppDevCI) {
        const app = (window as any).APP as any;
        const apps = (window as any).APPS as any || (app ? [app] : DefaultApps);
        return apps;
      } else {
        return DefaultApps;
      }
    });
  }

  private fetchImplementApps() {
    return JsonPackageService.fetch(`/web-common-resource/configs/implement/apps.json`).then((DefaultApps: any) => {
      if (process.env.isForAppDevCI) {
        const app = (window as any).APP as any;
        const apps = (window as any).APPS as any || (app ? [app] : DefaultApps);
        return apps;
      } else {
        return DefaultApps;
      }
    });
  }
}


export default new SPAStore();
