import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import UserProfile from '@business/views/account/profile/index';
import UserPassword from '@business/views/account/password/index';
import TokenUtils from '@shuyun-ep-team/utils/es/token';
import { Page404 } from '@common/views/errors/404';


export default function Router() {
  const tokenObj = TokenUtils.getTokenObj();
  // 是否为账号登录，仅账号登录才有修改密码的功能
  const isLoginFromAccount = tokenObj.loginFrom === 'Account';

  return (
    <Switch>
      <Route path="/account/profile" component={UserProfile} />
      <Route
        path="/account/password"
        component={isLoginFromAccount ? UserPassword : Page404}
      />
    </Switch>
  );
}
