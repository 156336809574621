import UserPermissionStore from '@common/stores/permission';

/**
 * 获取当前用户的权限集合
 */
function getCurrentUserPermissions() {
  return UserPermissionStore.get();
}


/**
 * 刷新当前用户的权限数据
 */
function refreshCurrentUserPermission() {
  return UserPermissionStore.fetch(true);
}


export const PermissionService: KyPortalService.IPermissionService = {
  get: getCurrentUserPermissions,
  refresh: refreshCurrentUserPermission
};
