import React, { FC, Suspense, useEffect, useRef } from 'react';
import { verificationStore } from './store';
import { observer } from 'mobx-react';
import MaskLoading from '@common/components/loading';
import CurrentUserStore from '@common/stores/user';

interface IMobileCaptchaProps {
  zIndex?: number;
  renderType?: 'phone-binding' | 'captcha-transmission';
  closable?: boolean;
  onOk?: (data: any) => void;
  onClose?: () => void;
  callback?: (verifyCode: string, cancelAndVerification: () => Promise<any>) => void;
}

const WebVerificationBase = React.lazy<FC>(() =>
  (window as any).SystemJS.import('/web-business-components/validate.js')
    .then(({ VerificationRender }: any) => {
      const Container: FC<IMobileCaptchaProps> = (props) => {
        const ref = useRef<HTMLDivElement>(null);
        const destroyRef = useRef<any>(null);
        useEffect(() => {
          if (ref.current) {
            VerificationRender({
              props,
              container: ref.current
            }).then(({ destroy }: any) => destroyRef.current = destroy);
          }
          return () => {
            if (destroyRef.current) {
              destroyRef.current();
            }
          };
        }, []);
        return <div ref={ref}></div>;
      };

      return {
        default: Container
      };
    })
);

const WebVerification: FC<IMobileCaptchaProps> = props => {
  return (
    <Suspense fallback={<MaskLoading visible={true} />}>
      <WebVerificationBase {...props} />
    </Suspense>
  );
};


@observer
export class VerificationModal extends React.Component<IMobileCaptchaProps> {

  private close = () => {
    verificationStore.reset();
  }

  private phoneBindingOK = () => {
    CurrentUserStore.fetchUserProfile().then(() => {
      this.close();
    });
  }

  public render() {
    const { renderType, visible, hasMobile, zIndex, callback, closable } = verificationStore;
    return (
      <>
        {
          renderType === 'phone-binding'
          && !hasMobile
          && visible
          && <WebVerification key={renderType} zIndex={zIndex} closable={closable}
            renderType={renderType} onOk={this.phoneBindingOK} onClose={this.close} />
        }
        {
          renderType === 'captcha-transmission'
          && visible
          && <WebVerification key={renderType} zIndex={zIndex} onClose={this.close}
            renderType={renderType} onOk={this.close} callback={callback} closable={closable} />
        }
      </>
    );
  }
}
