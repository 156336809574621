import React, { FC, CSSProperties } from 'react';

interface IProps {
  color?: string;
  style?: CSSProperties;
}

/**
 * @description 小红点，默认红色
 */
export const Dot: FC<IProps> = props => {
  const { color, style = {} } = props;

  const resultBackground = color || '#ED5767';

  const mergeStyle: CSSProperties = {
    width: 12,
    height: 12,
    borderRadius: '50%',
    background: resultBackground,
    ...style,
  };

  return <div style={mergeStyle}></div>;
};
