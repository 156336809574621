import * as React from "react";
import { observer } from "mobx-react";
import { observable, runInAction, action } from "mobx";
import { Form, Input } from "@shuyun-ep-team/kylin-ui";
import { FormComponentProps } from "@shuyun-ep-team/kylin-ui/es/form";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { set as setToken } from "@shuyun-ep-team/utils/es/token";
import languageStore from "@business/stores/language";
import Modal from "@common/components/modal";
import MaskLoading from "@common/components/loading";
import { bind } from "../service";
import { IBindUser } from "../types";
import * as i18nMap from "./i18n.map";

const { translate } = languageStore;

interface IProps extends FormComponentProps {
  token: string;
}

@observer
class SsoBind extends React.Component<IProps & RouteComponentProps> {
  @observable private isFetching!: boolean;
  // @observable private visible!: boolean;
  @action
  public bindUser = (user: IBindUser) => {
    const { token } = this.props;
    this.isFetching = true;

    return bind(token, user)
      .then(res => {
        runInAction(() => {
          this.isFetching = false;
        });

        setToken(res.token);
        window.location.replace(res.callback);

        return res;
      })
      .catch(err => {
        runInAction(() => {
          this.isFetching = false;
        });
        throw err;
      });
  };

  private onOk = () => {
    const {
      form: { validateFields }
    } = this.props;

    validateFields((errors, user) => {
      if (errors) {
        return;
      }

      this.bindUser(user);
    });
  };

  private onCancel = () => {
    const { history } = this.props;

    history.replace("/login");
  };

  public render() {
    const {
      form: { getFieldDecorator }
    } = this.props;

    return (
      <Modal
        width={560}
        visible={true}
        title={translate(i18nMap.bindTitle)}
        okText={translate(i18nMap.ok)}
        cancelText={translate(i18nMap.cancel)}
        onOk={this.onOk}
        onCancel={this.onCancel}
        destroyOnClose={true}
        maskClosable={false}
      >
        {this.isFetching ? <MaskLoading visible={true} /> : null}
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
          <Form.Item label={translate(i18nMap.account)}>
            {getFieldDecorator("username", {
              rules: [
                {
                  required: true,
                  message: translate(i18nMap.accountRequired)
                }
              ]
            })(<Input placeholder={translate(i18nMap.accountRequired)} />)}
          </Form.Item>
          <Form.Item label={translate(i18nMap.password)}>
            {getFieldDecorator("password", {
              rules: [
                {
                  required: true,
                  message: translate(i18nMap.passwordRequired)
                }
              ]
            })(
              <Input.Password
                placeholder={translate(i18nMap.passwordRequired)}
              />
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default Form.create<IProps>()(withRouter(SsoBind));
