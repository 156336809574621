import * as React from 'react';


interface IProps {
  condition: boolean;
  children: any;
}

export default function If(props: IProps) {

  if (props.condition) {
    return (
      <React.Fragment>
        {props.children}
      </React.Fragment>
    );
  }

  return null;
}
