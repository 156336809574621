import { observable, action, runInAction } from 'mobx';
import { fetchUserAvailableMenus } from '@business/layouts/main/service';
import { INestMenu } from '@business/typings/global';
import { setMenuCollapsed } from '@business/utils/menu';

let fetchUserAvailableMenusPromise: Promise<void> | undefined;

class UserMenuStore {
  // 用户菜单
  @observable public isFetching = false;
  @observable public menus: INestMenu[] = [];
  @observable public isFolded = false;

  /**
   * 更新主菜单折叠
   * @param status
   */
  @action public updateFoldedStatus(status: boolean) {
    this.isFolded = status;
    // 将状态同步到localStorage中去
    setMenuCollapsed(status);
  }

  @action public fetchUserAvailableMenus(refresh = false) {
    if (!refresh && fetchUserAvailableMenusPromise) {
      return fetchUserAvailableMenusPromise;
    }

    this.isFetching = true;
    fetchUserAvailableMenusPromise = fetchUserAvailableMenus({
      isReturnChild: true,
      isPageMenu: false
    })
      .then((menus: any) => {
        runInAction(() => {
          this.isFetching = false;

          // 以下这一段if代码主要是方便子应用集成开发
          if (process.env.isForAppDevCI) {
            ((window as any).MENUS || []).forEach((item: any) => {
              menus.push(item);
            });
          }

          this.menus = processMenus(menus, []);

          function processMenus(list: any[], keys: any[]) {
            const openKeys = [...keys];

            return list.map(item => {
              const { children = [] } = item;
              // 父级以及父级以上的id
              item.parentKeys = openKeys;

              if (children.length) {
                const nextParentKeys = [...openKeys, item.id];
                item.children = processMenus(children, nextParentKeys);
              }
              return item;
            });
          }
        });
      })
      .catch(err => {
        runInAction(() => {
          this.isFetching = false;
          this.cleanCache();
        });
        throw err;
      });

    return fetchUserAvailableMenusPromise;
  }

  public cleanCache() {
    fetchUserAvailableMenusPromise = undefined;
  }
}

export default new UserMenuStore();
