import * as classes from './index.scss';
import * as React from 'react';
import { observer } from 'mobx-react';
import { Form, Input } from '@shuyun-ep-team/kylin-ui';
import { FormComponentProps } from '@shuyun-ep-team/kylin-ui/lib/form';
import * as i18nMap from './i18n.map';
import { LanguageStore } from '@common/stores/language';



@observer
export default class LoginForm extends React.Component<FormComponentProps, any> {
  public render() {
    const { translate } = LanguageStore.instance;
    const { getFieldDecorator } = this.props.form;


    return (
      <Form>
        <Form.Item
          label={translate(i18nMap.UserName.label)}
          className={classes.formItem}
        >
          {getFieldDecorator('username', {
            rules: [{
              required: true,
              message: translate(i18nMap.UserName.requiredTips)
            }]
          })(
            <Input placeholder={translate(i18nMap.UserName.placeholder)} />
          )}
        </Form.Item>

        <Form.Item
          label={translate(i18nMap.Password.label)}
          className={classes.formItem}
        >
          {getFieldDecorator('password', {
            rules: [{
              required: true,
              message: translate(i18nMap.Password.requiredTips)
            }]
          })(
            <Input
              type='password'
              placeholder={translate(i18nMap.Password.placeholder)}
            />
          )}
        </Form.Item>
      </Form>
    );
  }
}

