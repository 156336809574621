import * as React from 'react';
import { observer } from 'mobx-react';
import { LanguageStore } from '@common/stores/language';

interface IProps {
  i18n: string;
  data?: { [key: string]: any };
}

@observer
export class Translate extends React.Component<IProps> {
  public render() {
    const { translate } = LanguageStore.instance.stores.portalPackageStore;
    const { i18n, data } = this.props;
    return <React.Fragment>{translate(i18n, data)}</React.Fragment>;
  }
}

interface ICommonTranslateProps {
  i18n: string;
  data?: { [key: string]: any };
  component?: 'span' | 'div';
}

export const CommonTranslate: React.FC<ICommonTranslateProps> = props => {
  const { Common } = ((window as any).KyPortalService as any).LanguageService;
  const { i18n, data, component } = props;
  const text = Common.translate(i18n, data);

  if (component === 'span') {
    return <span data-i18n={i18n}>{text}</span>;
  }

  if (component === 'div') {
    return <div data-i18n={i18n}>{text}</div>;
  }

  return <React.Fragment>{text}</React.Fragment>;
};

export default Translate;
