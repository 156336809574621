import { JssStyle } from 'jss';
import { antPrefix } from '@shuyun-ep-team/kylin-ui/es/styles/vars';

export const listWrap: JssStyle = {
  position: 'relative',
  height: 'calc(100vh - 150px)',
  overflow: 'auto',
};

export const loadingWrap: JssStyle = {
  position: 'absolute',
  bottom: '40px',
  width: '100%',
  textAlign: 'center',
};

export const wrap: JssStyle = {
  position: 'relative',
  borderBottom: '1px solid rgba(217, 217, 217, 0.5)',
  // borderImage: 'linear-gradient(to bottom,transparent 50%, #D9D9D9 50%) 0 0 100%/1px 0',
};

export const subjectWrap: JssStyle = {
  display: 'flex',
  justifyContent: 'center',
  overflow: 'hidden',
  padding: '12px 16px',
};

export const title: JssStyle = {
  fontSize: '14px',
  color: '#333',
  lineHeight: '22px',
};

export const date: JssStyle = {
  fontSize: '12px',
  color: '#999',
  lineHeight: '17px',
};

export const subjectLeft: JssStyle = {
  flex: 1,
  overflow: 'hidden',
};

export const arrow: JssStyle = {
  display: 'flex',
  alignItems: 'center',
  transition: 'transform .2s ease',
};

export const unfoldWrap: JssStyle = {
  background: '#F8F8F8',
  overflow: 'hidden',
  transition: 'max-height .2s ease',
};

export const unfoldBox: JssStyle = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: 16,
};

export const unfoldDetail: JssStyle = {
  fontSize: '14px',
  lineHeight: '22px',
  color: '#666',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: '4',
  textOverflow: 'ellipsis',
  userSelect: 'text',
};

export const unfoldOperate: JssStyle = {
  marginTop: 12,
};

export const emptyWrap: JssStyle = {
  [`& .${antPrefix}-list`]: {
    height: '100%',
    [`& .${antPrefix}-spin-nested-loading`]: {
      height: '100%',
      [`& .${antPrefix}-spin-container`]: {
        height: '100%',
        [`& .${antPrefix}-list-empty-text`]: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        },
      },
    },
  },
};

export const emptyText: JssStyle = {
  marginTop: 12,
  height: '22px',
  fontSize: '14px',
  color: '#666666',
  lineHeight: '22px',
};
