import LoginModalStore from '@common/components/login/store';

type TMode = 'Timeout' | 'Login' | 'Kickout';


function open(mode: TMode = 'Timeout') {

  if (['Timeout', 'Login', 'Kickout'].indexOf(mode) === -1) {
    throw new TypeError('mode must be one of `Timeout` `Kickout` `Login`.');
  }

  // 如果当前登录框已经是展示，则不处理 mode 变化
  if (LoginModalStore.visible) {
    return;
  }

  LoginModalStore.open(mode);
}


function close() {
  LoginModalStore.close();
}

function jumpToLogin() {
  location.href = process.env.isImplementAppDevCI ? '/implement/#/login' : '/portal/#/login';
}


export const LoginService: KyPortalService.ILoginService = {
  open,
  close,
  jumpToLogin
};

