import { JssStyle } from 'jss';
import { backgroundColorLight, primaryColor } from '@shuyun-ep-team/kylin-ui/es/styles/vars';

export const operate: JssStyle = {
  '& button + button': {
    marginLeft: 8,
  },
};

export const customStyle: JssStyle = {
  '& button': {
    cursor: 'pointer',
    height: '24px',
    background: '#FFFFFF',
    borderRadius: '2px',
    border: `1px solid ${primaryColor}`,
    fontSize: '14px',
    color: `${primaryColor}`,
    lineHeight: '20px',
    '&:hover': {
      background: backgroundColorLight,
    },
  },
};
