import { action, observable, runInAction, toJS } from 'mobx';
import { ISystemConfig, ILanguage, IMenu } from '@common/interfaces/global';
import { portalFetch } from '@common/services/fetch';
import { LanguageStore } from '@common/stores/language';
import { getCookie, setCookie } from '@common/utils/cookie';

// 系统配置请求（单例）
let SystemConfigPromise: Promise<void> | undefined;

// 系统着陆页配置
let SystemLandingPagePromise: Promise<IMenu> | undefined;

const config = (window as any).CCMS_INFOS_CONFIG;

class SystemStore {
  // 获取系统配置
  @observable public isFetching = false;
  @observable public config: ISystemConfig = {} as ISystemConfig;

  @action public fetchSystemConfig(refresh = false) {
    if (SystemConfigPromise && !refresh) {
      return SystemConfigPromise;
    }

    let promiseArr: any[] = [this.fetchSystemLanguages()];
    this.isFetching = true;
    if (config.SAAS === 'false') {
      promiseArr = [this.fetchSystemLogo(), this.fetchSystemLanguages()];
    }
    return (SystemConfigPromise = Promise.all(promiseArr)
      .then(() => {
        runInAction(() => {
          this.isFetching = false;
        });
      })
      .catch(err => {
        runInAction(() => {
          this.isFetching = false;
        });
        SystemConfigPromise = undefined;
        throw err;
      }));
  }

  // 获取系统标识
  @action private fetchSystemLogo() {
    return portalFetch
      .get('/corporation', {
        isNotNeedAuth: true
      } as any)
      .then((res: any) => {
        runInAction(() => {
          this.config.logo = res.imgPath;
        });
      });
  }

  @action private fetchSystemLanguages() {
    if (config.SAAS === 'true') {
      const languagesSource = (window as any).CCMS_INFOS_CONFIG.LANGUAGES;
      if (languagesSource) {
        const languagesSourceObject = JSON.parse(languagesSource);
        runInAction(() => {
          this.config.languages = languagesSourceObject;
          this.config.language = languagesSourceObject[0].language;
        });
      } else {
        this.config.language = 'zh-CN';
        this.config.languages = [{ language: 'zh-CN', id: 0, describer: '中文', isDefault: true }];
        return LanguageStore.instance.fetchLanguagePackage('zh-CN');
      }
    } else {
      return portalFetch
        .get('/international/language/all', {
          isNotNeedAuth: true
        } as any)
        .then((res: any) => {
          const languages = res;
          const defaultLanguage =
            getCookie('language') ||
            navigator.language ||
            languages.find((item: ILanguage) => item.isDefault).language;

          runInAction(() => {
            this.config.languages = languages;
            this.config.language = defaultLanguage;
          });
        });
    }
  }

  @action public fetchSystemLandingPage(refresh = false): Promise<IMenu> {
    if (!refresh && SystemLandingPagePromise) {
      return SystemLandingPagePromise;
    }

    SystemLandingPagePromise = portalFetch
      .get('/menu/landingPage', {} as any)
      .then((res: any) => {
        return res;
      })
      .catch(err => {
        SystemLandingPagePromise = undefined;
        throw err;
      });

    return SystemLandingPagePromise;
  }

  @action public changeLanguage(language: string) {
    setCookie('language', language);
    runInAction(() => {
      this.config.language = language;
    });
    LanguageStore.instance.fetchLanguagePackage(language);
  }

  /**
   * 获取系统所有配置语言
   */
  public getSystemAllLanguages() {
    return toJS(this.config.languages) || [];
  }
}

export default new SystemStore();
