import React from 'react';
import { createUseStyles } from 'react-jss';
import { Classes, JssStyle } from 'jss';

export function createStyles<T extends string>(classes: { [key in T]: Partial<JssStyle> }) {
  return createUseStyles<T>(classes);
}

export function Styles<T extends string | number | symbol>(props: {
  styles: () => Classes<T>;
  children: (classes: Classes<T>) => React.ReactElement;
}) {
  const classes = props.styles();
  return props.children(classes);
}
