/**
 * @file apps应用列表模块相关api 服务
 */
import { notifyFetch } from '@common/services/fetch';
import { NoticeConfig, GetCardMessageRes, QRCode, GetMessageList, GetMessageListRes } from './type';

/**
 * 获取当前用户通知配置
 */
export const getUserConfig = (): Promise<NoticeConfig> => {
  return notifyFetch.get(`/web/user/config`, { AutoError: false } as any) as any;
};

/**
 * 修改当前用户通知配置
 */
export const putUserConfig = (params: NoticeConfig) => {
  return notifyFetch.put('/web/user/config', params);
};

/**
 * 设置所有消息已读
 */
export const postAllRead = () => {
  return notifyFetch.post('/web/user/message/already/read/all');
};

/**
 * 设置指定的消息已读
 */
export const postRead = (params: string[]) => {
  return notifyFetch.post('/web/user/message/already/read/specify', params);
};

/**
 * 卡片消息获取
 */
export const getCardMessage = (): Promise<GetCardMessageRes> => {
  return notifyFetch.get('/web/user/message/card', { AutoError: false } as any) as any;
};

/**
 * 获取微信公众号二维码
 */
export const getQRCode = (): Promise<QRCode> => {
  return notifyFetch.get('/web/user/wechat/qrcode') as any;
};

/**
 * 卡片操作时间更新
 */
export const postRecord = () => {
  return notifyFetch.post('/web/user/message/card/operate/record');
};

/**
 * 获取消息列表
 */
export const getMessageList = (params: GetMessageList): Promise<GetMessageListRes> => {
  return notifyFetch.get('/web/user/message/list', { params }) as any;
};
