import * as React from 'react';
import { configure } from 'mobx';
import { observer } from 'mobx-react';
import * as ReactDOM from 'react-dom';
import { ThemeProvider, JssProvider } from 'react-jss';
import RootRouter from './router';
import SystemStore from '@common/stores/system';
import { bootstrapSpaService } from '@common/services/spa';
import LanguageStore from '@business/stores/language';
import { ConfigProvider, message } from '@shuyun-ep-team/kylin-ui';
import { generateJssClassName } from '@shuyun-ep-team/utils/es/jss';
import { ThemeStore } from '@common/stores/theme';
import StyleAttached from '@common/services/style-attached';
import { antPrefix } from '@shuyun-ep-team/kylin-ui/es/styles/vars';
import { RuntimeThemeStyleSheet } from '@shuyun-ep-team/kylin-ui/es/utils/runtime-theme-style-sheet';
import '@shuyun-ep-team/kylin-ui/es/styles/index.css';
// import '@shuyun-ep-team/kylin-ui/lib/styles/components.less';

import '@business/services';
import '@business/assets/styles/index.scss';

message.config({ prefixCls: `${antPrefix}-message` });

const generateClassName = generateJssClassName('Portal');

// enable mobx strict-mode
configure({
  isolateGlobalState: true,
  enforceActions: 'observed'
});

@observer
class RootApp extends React.Component {
  private runtimeThemeStyleSheet!: RuntimeThemeStyleSheet;

  public componentDidMount() {
    // 获取系统配置信息
    SystemStore.fetchSystemConfig();
    ThemeStore.fetch().then(() => {
      this.runtimeThemeStyleSheet = new RuntimeThemeStyleSheet(ThemeStore.flat());
      this.runtimeThemeStyleSheet.attach();
    });

    bootstrapSpaService('Business');
  }

  public componentWillUnmount() {
    // 销毁附加样式
    StyleAttached.detach();

    if (this.runtimeThemeStyleSheet) {
      this.runtimeThemeStyleSheet.detach();
    }
  }

  public render() {
    window.document.title = LanguageStore.translate('Website.title') || '';

    return (
      <React.Fragment>
        <JssProvider generateId={generateClassName}>
          <ThemeProvider theme={ThemeStore.theme}>
            <ConfigProvider locale={LanguageStore.stores.commonPackageStore.localeForAntd as any}>
              <RootRouter />
            </ConfigProvider>
          </ThemeProvider>
        </JssProvider>
      </React.Fragment>
    );
  }
}

// 为自动化测试追加前缀代码
const rootDom = document.getElementById('root');
if (rootDom) {
  rootDom.setAttribute('data-portal-kylin-ui-prefix', antPrefix);
}

ReactDOM.render(<RootApp />, rootDom);
