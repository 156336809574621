import { action, observable, runInAction } from 'mobx';
import SystemStore from '@common/stores/system';
import { epassprotLoginFetch } from '@common/services/fetch';

function login(formData: { [key: string]: string }): Promise<any> {
  return epassprotLoginFetch.post('/oauth/token', formData, {
    AutoError: false,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept-Language': SystemStore.config.language
    }
  } as any) as any;
}

type TMode = 'Timeout' | 'Login' | 'Kickout';

class LoginModalStore {
  // 是否展示登录框
  @observable public visible = false;

  @observable public mode: TMode = 'Timeout';


  @action public open(mode: TMode) {
    this.visible = true;
    this.mode = mode;
  }

  @action public close() {
    this.visible = false;
  }


  @observable public isSubmiting = false;
  @action public login(formData: { username: string, password: string }) {
    this.isSubmiting = true;

    return login({
      ...formData,
      grant_type: 'password',
      tenant_id: (window as any).CCMS_INFOS_CONFIG.TENANT
    }).then(res => {
      runInAction(() => {
        this.isSubmiting = false;
      });

      return res;
    }).catch(err => {
      runInAction(() => {
        this.isSubmiting = false;
      });
      throw err;
    });
  }
}

export default new LoginModalStore();
