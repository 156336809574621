import { action, observable, runInAction, toJS } from 'mobx';
import { get as getToken } from '@shuyun-ep-team/utils/es/token';
import { IUser } from '@common/interfaces/global';
import { portalFetch, epassprotFetch, pureFetch } from '@common/services/fetch';
import { setCookie } from '@common/utils/cookie';
// import languageStore from './language';

class CurrentUserStore {
  // 获取用户信息
  @observable public isFetching = false;
  @observable public profile!: IUser;
  @action public fetchUserProfile() {
    this.isFetching = true;
    return fetchUserProfile()
      .then(res => {
        runInAction(() => {
          this.isFetching = false;
          this.profile = res;
        });
      })
      .catch(err => {
        runInAction(() => {
          this.isFetching = false;
        });
        throw err;
      });
  }

  // 修改用户默认语言
  @action public updateUserLanguage(language: string) {
    this.profile.language = language;
    setCookie('language', language);
    // languageStore.fetchLanguagePackage(language);
  }

  // 正常登录退出
  @action public logout() {
    return logout();
  }

  // 单点登录退出
  @action public ssoLogout(session: string, callback: string) {
    return ssoLogout(session, callback);
  }

  public get() {
    return toJS(this.profile);
  }
}

export default new CurrentUserStore();

function fetchUserProfile(): Promise<IUser> {
  return portalFetch.get('/account/info') as any;
}

function logout() {
  return epassprotFetch.post('/oauth/revoke');
}

function ssoLogout(session: string, callback: string) {
  const location = window.location;
  return pureFetch.post(
    `${location.origin}/epassport/v1/sso/saml/logout`,
    {
      token: getToken(),
      session,
      callback
    },
    {
      isNotNeedAuth: true,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    } as any
  );
}
